export const commonPermissionText = [
  { resourceKey: 'share', resourceName: '分享' },
  { resourceKey: 'download', resourceName: '下载' }
];

export const otherPermissionText = [
  { resourceKey: 'upload', resourceName: '上传' },
  { resourceKey: 'update_category', resourceName: '编辑分类' },
  { resourceKey: 'update_permission', resourceName: '编辑权限' },
  { resourceKey: 'update_tag', resourceName: '编辑标签' },
  { resourceKey: 'delete', resourceName: '删除' },
  { resourceKey: 'update_data_classification', resourceName: '编辑数据分级' }
];

export const filePermissionText = [
  { resourceKey: 'upload', resourceName: '上传' },
  { resourceKey: 'update_category', resourceName: '编辑分类' },
  { resourceKey: 'update_permission', resourceName: '编辑权限' },
  { resourceKey: 'update_tag', resourceName: '编辑标签' },
  { resourceKey: 'delete', resourceName: '删除' },
  { resourceKey: 'share', resourceName: '分享' },
  { resourceKey: 'download', resourceName: '下载' }
];

export const menuPermissionText = [
  { resourceKey: 'mapManagement', resourceName: '轮播图管理' },
  { resourceKey: 'authManagement', resourceName: '权限管理' },
  { resourceKey: 'accountConfig', resourceName: '账号配置' },
  { resourceKey: 'categoryManagement', resourceName: '分类管理' },
  { resourceKey: 'operateHistory', resourceName: '操作历史' }
];

export const contentColumnPermissionText = [
  { resourceKey: 'file_name', resourceName: '文件名' },
  { resourceKey: 'upload_time', resourceName: '上传时间' },
  { resourceKey: 'upload_user', resourceName: '上传者' },
  { resourceKey: 'type', resourceName: '类型' },
  { resourceKey: 'tag', resourceName: '标签' },
  { resourceKey: 'detail', resourceName: '详情' },
  { resourceKey: 'click_volume', resourceName: '点击量' },
  { resourceKey: 'data_classification', resourceName: '数据分级' }
];
