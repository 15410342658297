import ZeissButton from '@components/button';
import { FC, useEffect, useState } from 'react';
import './index.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { deleteFile, downloadRecord, getContentDetail } from '@api/home';
import { useImmer } from 'use-immer';
import { ContentDetailResult } from '@api/home/type';
import dayjs from 'dayjs';
import { Col, Empty, Image, Row, Tag } from 'antd';
import { tagsColors } from '../hotspot/list-table';
import { Player } from 'video-react';
import '../../../../node_modules/video-react/styles/scss/video-react.scss';
import { ReactComponent as PdfFileIcon } from '@/assets/image/file/pdf-file-icon.svg';
import { ReactComponent as AiFileIcon } from '@/assets/image/file/ai-file-icon.svg';
import { ReactComponent as InddFileIcon } from '@/assets/image/file/indd-file-icon.svg';
import { ReactComponent as PsFileIcon } from '@/assets/image/file/ps-file-icon.svg';
import { ReactComponent as WordFileIcon } from '@/assets/image/file/word-file-icon.svg';
import { ReactComponent as ZipFileIcon } from '@/assets/image/file/zip-file-icon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/image/delete-icon.svg';
import { ReactComponent as DefaultFileIcon } from '@/assets/image/default-file-icon.svg';

import Icon, { LeftOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { userInfo } from '@store';
import ZeissPopconfirm from '@components/popconfirm';
import download from '@utils/download';
import ClassTypeTag from '@components/classTypeTag';

export const imageSuffix = [
  'apng',
  'avif',
  'bmp',
  'gif',
  'ico',
  'cur',
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'png',
  'svg',
  'tif',
  'tiff',
  'webp'
];

export const videoSuffix = ['avi', 'mp4', 'webm'];

const CategoryAndFileDetail: FC = () => {
  const { fileId } = useParams();
  const [contentDetail, setContentDetail] = useImmer<
    ContentDetailResult | undefined
  >(undefined);
  const [userInfos] = useRecoilState<{
    authority: { resourceKey: string; children: { resourceKey: string }[] }[];
  }>(userInfo);
  const [noPermission, setNoPermission] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getContentDetailData = async () => {
    if (!fileId) return;
    try {
      const data = await getContentDetail({ id: fileId });
      setContentDetail(data);
    } catch (error) {
      setNoPermission(true);
      console.error(error);
    }
  };

  const handleShare = () => {
    navigate(`/contents/share`, {
      state: {
        fileList: [
          {
            fileId: contentDetail?.fileId,
            fileName: contentDetail?.name,
            fileUrl: contentDetail?.fileUrl
          }
        ]
      }
    });
  };

  const handleEditCategory = () => {
    navigate(`/contents/edit-category`, {
      state: {
        fileList: [
          {
            fileId: contentDetail?.fileId
          }
        ]
      }
    });
  };
  const handleEditPermission = () => {
    navigate(`/contents/edit-permission`, {
      state: {
        fileList: [
          {
            fileId: contentDetail?.fileId,
            fileName: contentDetail?.name
          }
        ]
      }
    });
  };

  const handleDownload = async () => {
    if (!contentDetail?.fileUrl) return;
    download(contentDetail.name, contentDetail.fileUrl);
    // saveAs(contentDetail?.fileUrl, contentDetail?.name);
    // message.success('后台下载中，请稍候');
    await downloadRecord({ fileIds: [contentDetail.fileId] });
  };

  const renderPreview = () => {
    switch (contentDetail?.suffix) {
      case imageSuffix.find((suffix) => suffix === contentDetail?.suffix):
        return (
          <Image
            width={'100%'}
            height={'100%'}
            src={contentDetail?.icon}
            preview={false}
          />
        );
      case videoSuffix.find((suffix) => suffix === contentDetail?.suffix):
        return <Player width={500} src={contentDetail?.fileUrl} muted />;
      case 'pdf':
        return (
          <div>
            <Icon component={PdfFileIcon} />
            {contentDetail?.name}
          </div>
        );
      case 'ai':
        return (
          <div>
            <Icon component={AiFileIcon} />
            {contentDetail?.name}
          </div>
        );
      case 'ps':
        return (
          <div>
            <Icon component={PsFileIcon} />
            {contentDetail?.name}
          </div>
        );
      case 'indd':
        return (
          <div>
            <Icon component={InddFileIcon} />
            {contentDetail?.name}
          </div>
        );
      case 'word':
        return (
          <div>
            <Icon component={WordFileIcon} />
            {contentDetail?.name}
          </div>
        );
      case 'zip':
        return (
          <div>
            <Icon component={ZipFileIcon} />
            {contentDetail?.name}
          </div>
        );
      default:
        return (
          <div>
            <Icon component={DefaultFileIcon} />
            {contentDetail?.name}
          </div>
        );
    }
  };

  const handleDeleteAll = async () => {
    if (!contentDetail?.id) return;
    try {
      await deleteFile({
        fileIds: [contentDetail.fileId]
      });
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  const hasAuth = (key: string) => {
    const auth = userInfos.authority
      .find((auth) => auth.resourceKey === 'content')
      ?.children.findIndex((child) => child.resourceKey === key);
    return auth !== undefined ? auth > -1 : false;
  };

  const handleOnOk = async () => {
    await handleDeleteAll();
  };

  useEffect(() => {
    getContentDetailData();
  }, []);

  return (
    <div className="wrapper detail-page">
      {!noPermission ? (
        <>
          <div className="detail-header">
            <div className="page-title">{contentDetail?.name}</div>
            <div className="button-list">
              {location.state?.from && (
                <ZeissButton
                  className="detail-button color-btn"
                  icon={<LeftOutlined />}
                  onClick={handleClickBack}
                >
                  返回
                </ZeissButton>
              )}
              {hasAuth('delete') && (
                <ZeissPopconfirm
                  title={'提示'}
                  content={'将在所有分类下删除该内容，确定要删除吗？'}
                  onOk={handleOnOk}
                >
                  <ZeissButton
                    className="danger-btn detail-button"
                    danger
                    icon={<Icon component={DeleteIcon} />}
                  >
                    删除
                  </ZeissButton>
                </ZeissPopconfirm>
              )}
              {hasAuth('update_category') && (
                <ZeissButton
                  className="detail-button color-btn"
                  onClick={handleEditCategory}
                >
                  编辑分类
                </ZeissButton>
              )}
              {hasAuth('update_permission') && (
                <ZeissButton
                  className="detail-button color-btn"
                  onClick={handleEditPermission}
                >
                  编辑权限
                </ZeissButton>
              )}
              {hasAuth('share') && (
                <ZeissButton
                  className="detail-button color-btn"
                  onClick={handleShare}
                >
                  分享
                </ZeissButton>
              )}
              {hasAuth('download') && (
                <ZeissButton
                  type="primary"
                  className="detail-button"
                  onClick={handleDownload}
                >
                  下载
                </ZeissButton>
              )}
            </div>
          </div>
          <div className="detail-content">
            <div className="detail-content-header">详情</div>
            <div className="detail-wrapper">
              <Row>
                <Col span={8} className="detail">
                  上传时间：
                  {`${dayjs(contentDetail?.createTime).format(
                    'YYYY/MM/DD HH:mm:ss'
                  )}`}
                </Col>
                <Col span={8} className="detail">
                  上传者：{contentDetail?.createUser}
                </Col>
                <Col span={8} className="detail">
                  标签：
                  {contentDetail?.labels?.split(',').map((label, index) => (
                    <Tag
                      key={label + index}
                      bordered={false}
                      color={tagsColors[0]}
                      className="table-tag"
                    >
                      {label}
                    </Tag>
                  ))}
                  {!contentDetail?.labels && '无'}
                </Col>
                {contentDetail?.paths.map((path, index) => (
                  <Col key={path} span={8} className="detail">{`内容分类${
                    index + 1
                  }：${path}`}</Col>
                ))}
                <Col span={8} className="detail">
                  数据分级：
                  <ClassTypeTag
                    value={contentDetail?.dataClassification || 0}
                    icon={false}
                  />
                  {}
                </Col>
                {/* <div className="detail">
                <Row gutter={16}>
                </Row>
              </div> */}
              </Row>
            </div>
            <div className="detail-content-header">内容预览</div>
            <div className="content-preview">
              {/* {imageSuffix.find((suffix) => suffix === contentDetail?.suffix) && (
              <Image
                width={'100%'}
                src={contentDetail?.icon}
                preview={{ src: contentDetail?.fileUrl }}
              />
            )}
            {videoSuffix.find((suffix) => suffix === contentDetail?.suffix) && (
              <Player width={500} src={contentDetail?.fileUrl} muted />
            )} */}
              {renderPreview()}
            </div>
          </div>
        </>
      ) : (
        <Empty description={'内容不存在'} className="empty-detail" />
      )}
    </div>
  );
};

export default CategoryAndFileDetail;
