import { valueZHCN } from '@/i18n';
import { CategoryListItem } from '@api/category/type';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist({
  key: 'recoil-persist', // this key is using to store data in local storage
  storage: localStorage // configurate which storage will be used to store the data
});

const userInfo = atom({
  key: 'userInfo',
  default: {},
  effects_UNSTABLE: [persistAtom]
});

const dataClassifications = atom({
  key: 'dataClassifications',
  default: [],
  effects_UNSTABLE: [persistAtom]
});

const userList = atom({
  key: 'userList',
  default: []
});
const zoneList = atom({
  key: 'zoneList',
  default: [],
  effects_UNSTABLE: [persistAtom]
});

const i18nLang = atom({
  key: 'i18nLang',
  default: localStorage.getItem('i18nextLng') ?? valueZHCN
});

const isSocketInfo = atom({
  key: 'isSocketInfo',
  default: false
});

const newsNumber = atom({
  key: 'newsNumber',
  default: 0
});

const selectedFile = atom({
  key: 'selectedFile',
  default: [] as CategoryListItem[]
});

export {
  i18nLang,
  isSocketInfo,
  newsNumber,
  userInfo,
  userList,
  zoneList,
  selectedFile,
  dataClassifications
};
