import { CategoryListItem } from '@api/category/type';
import { getFileList } from '@api/home';
import { FC, useEffect, useState } from 'react';
import Content from './components/content';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';

const NewContent: FC = () => {
  const [categoryList, setCategoryList] = useState<CategoryListItem[]>([]);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize: 10,
    current: 1,
    showQuickJumper: true,
    showSizeChanger: true
  });

  const getSorter = (
    sorter: SorterResult<CategoryListItem> | SorterResult<CategoryListItem>[],
    key: string
  ) => {
    return sorter instanceof Array
      ? sorter.find((item) => item.columnKey === key)?.order
      : sorter?.columnKey === key
      ? sorter.order
      : undefined;
  };

  const getNewContentList = async (
    paginationConfig?: {
      current?: number;
      pageSize?: number;
    },
    filters?: Record<string, FilterValue | null>,
    sorter?: SorterResult<CategoryListItem> | SorterResult<CategoryListItem>[]
  ) => {
    try {
      const timeOrder = getSorter(sorter || [], 'createTime');
      const suffixOrder = getSorter(sorter || [], 'suffix');
      const data = await getFileList({
        timeOrder: timeOrder || 'descend',
        fileTypeOrder: suffixOrder || undefined,
        pageNumber: paginationConfig?.current || pagination.current,
        pageSize: paginationConfig?.pageSize || pagination.pageSize
      });
      setCategoryList(data.records);
      setPagination({
        ...pagination,
        pageSize: data.size,
        total: data.total,
        current: data.current
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNewContentList();
  }, []);

  return (
    <div className="wrapper">
      <Content
        data={categoryList}
        fetchData={getNewContentList}
        pagination={pagination}
        setPagination={setPagination}
      />
    </div>
  );
};

export default NewContent;
