import globalHeader from './zh_CN/globalHeader';
import login from './zh_CN/login';
import menu from './zh_CN/menu';
import rc from './zh_CN/rc';
import user from './zh_CN/user';
import component from './zh_CN/components';
import account from './zh_CN/account';
import category from './zh_CN/category';

export default {
  'navBar.lang': '语言',
  'operation.add': '新增',
  'btn.delete': '删除',
  'btn.edit': '编辑',
  'operation.success': '操作成功！',
  'operation.delSuccess': '删除成功！',
  'operation.delFailed': '删除失败！',
  'operation.createSuccess': '创建成功！',
  'operation.createFailed': '创建失败！',
  'operation.editSuccess': '编辑成功！',
  'operation.editFailed': '编辑失败！',
  'operation.notifySuccess': '通知成功！',
  'operation.notifyFailed': '通知失败！',
  'btn.reset': '重置',
  'btn.confirm': '确认',
  'btn.login': '登录',
  'btn.cancel': '取消',
  'btn.close': '关闭',
  'btn.back': '返回',
  'btn.export': '导出',
  'btn.submit': '提交',
  'btn.save': '保存',
  'btn.view': '查看',
  'btn.search': '搜索',
  'btn.create': '新建',
  'btn.addNewOne': '新增',
  'btn.upload': '点击上传',
  'btn.notify': '通知',
  'btn.tip': '提示',
  'btn.noNotify': '暂不通知',
  'btn.download': '下载',
  'btn.downloadFail': '下载失败',
  'btn.yes': '是',
  'btn.no': '否',
  'common.selectDate': '选择日期',
  'common.last1year': '近一年',
  'common.last3year': '近三年',
  'common.index': '序号',
  'common.name': '名称',
  'common.startDate': '开始时间',
  'common.endDate': '结束时间',
  'common.no': '否',
  'common.all': '全部',
  'common.more': '更多',
  'common.status': '状态',
  'common.formInputRequired': '请输入 {{field}}',
  'common.formSelectRequired': '请选择 {{field}}',
  'common.createDate': '创建日期',
  'common.pleaseSelectDataToDel': '请先选择您需要删除的数据',
  'common.pleaseSelectDataToExport': '请先选择您需要导出的数据',
  'common.pleaseSelectDataToDownload': '请先选择您需要下载的数据',
  'common.pleaseSelectData': '请先选择您的数据',
  'input.placeholder': '请输入',
  'confirm.back': '填写信息尚未保存，确认离开吗？',
  'confirm.back.nosave': '填写信息尚未保存，确认离开吗？',
  'confirm.delete': '确认删除吗',
  'date.month1': '1月',
  'date.month2': '2月',
  'date.month3': '3月',
  'date.month4': '4月',
  'date.month5': '5月',
  'date.month6': '6月',
  'date.month7': '7月',
  'date.month8': '8月',
  'date.month9': '9月',
  'date.month10': '10月',
  'date.month11': '11月',
  'date.month12': '12月',
  'common.year': '年',
  'common.month': '月',
  'common.day': '日',
  'common.goCheck': '去查看',
  'common.samePassword': '请再次输入相同的密码',
  'common.passwordUpdated': '密码更新成功',
  'common.version': '当前版本',
  'pages.order.confirmDel': '确认要删除吗',
  'pages.templates.operate': '操作',
  'pages.templates.newBuilt': '新建资源树',
  'pages.templates.edit': '编辑资源树',
  'pages.templates.childBuilt': '新建子资源',
  'pages.limit.resourceName': '资源名称',
  'pages.limit.resourceKey': '资源关键字',
  'pages.limit.type': '资源类型',
  'pages.limit.sortNum': '排序',
  'pages.limit.visible': '是否可见',
  'pages.limit.remark': '备注',
  operation: '操作',
  ...globalHeader,
  ...menu,
  ...login,
  ...user,
  ...rc,
  ...component,
  ...account,
  ...category
};
