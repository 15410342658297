import { addUserRoleBatch, queryAllRoleShow } from '@api/role';
import { RoleItem } from '@api/role/type';
import { listResponseRecordsFace } from '@api/user/type';
import { Form, Select, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import ZeissButton from '@components/button';
import { uuid } from '@utils/tool';

interface UserEditProps {
  editUser: listResponseRecordsFace[];
  setEditUser: (user: listResponseRecordsFace[]) => void;
  fetchUsers: () => Promise<void>;
}

const UserEdit: FC<UserEditProps> = ({ editUser, setEditUser, fetchUsers }) => {
  const [roles, setRoles] = useState<RoleItem[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<
    { roleKey: string; name: string; id: string }[]
  >([]);

  const [form] = Form.useForm();

  const handleSelectChange = async (
    items: { value: string; label: string; id: string }[],
    options: any
  ) => {
    setSelectedRoles(
      options.map((item: { value?: string; label?: string; id: string }) => ({
        roleKey: item.value || '',
        name: item.label || '',
        id: item.id
      }))
    );
  };

  const handleCancel = () => {
    setEditUser([]);
  };

  const handleSubmit = async () => {
    try {
      await addUserRoleBatch({
        userId: editUser.map((user) => user.userId),
        roleIds: selectedRoles.map((role) => +role.id)
      });
      await fetchUsers();
      message.success('编辑用户成功');
      setEditUser([]);
    } catch (error) {
      console.error(error);
    }
  };

  const getRoles = async () => {
    const { roles } = await queryAllRoleShow();
    setRoles(roles || []);
  };

  useEffect(() => {
    getRoles();
    const roles: { id: string; name: string; roleKey: string }[] = [];
    const defaultRoles: { id: string; label: string; value: string }[] = [];
    editUser.forEach((user) => {
      roles.push(
        _.flatten(
          user.roleList?.map((role) => ({
            id: `${role.id}`,
            name: role.roleName,
            roleKey: role.roleKey
          })) || []
        )
      );
      defaultRoles.push(
        _.flatten(
          user.roleList?.map((role) => ({
            id: `${role.id}`,
            label: role.roleName,
            value: role.roleKey
          })) || []
        )
      );
    });
    setSelectedRoles(_.uniqBy(_.flatten(roles), 'id'));
    form.setFieldValue('roles', _.uniqBy(_.flatten(defaultRoles), 'id'));
  }, []);

  return (
    <div className="assign-role-wrapper">
      <div className="page-subtitle">分配角色</div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item label={'选择角色'} name={'roles'}>
          <Select
            key={uuid()}
            labelInValue
            className="role-select"
            mode="tags"
            style={{ width: '100%' }}
            onChange={handleSelectChange}
            bordered={false}
            options={roles.map((role) => ({
              value: role.roleKey,
              label: role.roleName,
              id: `${role.id}`
            }))}
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item className="buttons-wrapper">
          <ZeissButton className="color-btn" onClick={handleCancel}>
            取消
          </ZeissButton>
          <ZeissButton type="primary" htmlType="submit">
            保存
          </ZeissButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserEdit;
