import Header from '@components/header';
import { Button, Form, FormInstance, Input, message } from 'antd';
import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loginBg from '@assets/image/loginBg.png';
import { useTranslation } from 'react-i18next';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import VertifyCode, { ImperativeHandleProps } from '@components/vertify-code';
import { useRecoilState } from 'recoil';
import { newsNumber, userInfo } from '@store/index';
import { emailReg, passwordRdeg } from '@constants/reg';
import { passwordEdit, sendOpreationEmailCode } from '@api/common';
import { clearStorage } from '@utils/localStorageService';
import { TOKEN_KEY } from '@constants';
import ZeissButton from '@components/button';
import './index.scss';

const ForgetPassword: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClickBack = () => {
    navigate('/login');
  };

  // const [userInfos, setUserInfo] = useRecoilState(userInfo);
  const cRef = useRef<ImperativeHandleProps>(null);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState();
  const [form] = Form.useForm();

  const sendTextmessage = async () => {
    // 此处入参应改为account
    const email = form.getFieldValue('email');
    if (!emailReg.test(email)) {
      message.error('邮箱格式不正确');
      return;
    }
    // 邮箱验证码倒计时
    cRef.current?.startReduceTimer();

    try {
      // 调邮箱验证码接口
      await sendOpreationEmailCode({ email });
      message.success('发送邮件成功,等待接收');
    } catch (error) {
      cRef.current?.stopReduceTimer();
    }
  };

  // 提交
  const handleSubmit = async (params: {
    email: string;
    validateCode: string;
    newPassword: string;
  }) => {
    setLoading(true);
    const values = {
      email: params?.email,
      code: params?.validateCode,
      newPassword: params?.newPassword
    };
    try {
      await passwordEdit(values);
      message.success('密码修改成功，即将跳转登录');
      setTimeout(() => {
        clearStorage(TOKEN_KEY);
        navigate('/login');
      }, 3000);
    } catch {
      message.error('修改失败');
    } finally {
      setLoading(false);
    }
  };

  const onSubmitClick = () => {
    form.validateFields().then((values) => {
      values?.account && setAccount(values?.account);
      handleSubmit(values);
    });
  };

  return (
    <div className="login">
      <div className="login-content">
        <img className="bg-img" src={loginBg} alt="login" />
        <div className="login-modal">
          <div className="main">
            <p className="title">{t('page.login.recoverPassword')}</p>
            <div className="form">
              <Form form={form} onFinish={onSubmitClick}>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: '邮箱不能为空' },
                    { pattern: emailReg, message: '请填写正确的邮箱格式' }
                  ]}
                >
                  <Input
                    className="zeiss-reset-input zeiss-input-border-bottom"
                    placeholder="请输入您的邮箱账号"
                  />
                </Form.Item>

                {/* 验证码表单 */}
                <VertifyCode cRef={cRef} onTap={sendTextmessage} />
                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: t('login.form.password.errMsg') || ''
                    },
                    {
                      pattern: passwordRdeg,
                      message: '密码至少6位字符，同时包含数字和小写字母'
                    }
                  ]}
                >
                  <Input.Password
                    className="zeiss-reset-input zeiss-input-border-bottom"
                    placeholder="设置密码（>6位字符，同时包含数字和小写字母)"
                  />
                </Form.Item>

                <Form.Item
                  name="confirmPassword"
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: t('login.form.password.errMsg') || ''
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('密码不一致,请重新输入')
                        );
                      }
                    })
                  ]}
                >
                  <Input.Password
                    className="zeiss-reset-input zeiss-input-border-bottom"
                    placeholder="再次输入确认密码"
                  />
                </Form.Item>

                <Form.Item>
                  <ZeissButton
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ marginTop: 10 }}
                    block
                  >
                    确认修改
                  </ZeissButton>
                </Form.Item>
              </Form>
              <div className="forget-password-buttons">
                <Button type="link" onClick={handleClickBack}>
                  返回登录
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
