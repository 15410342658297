import { RcFile } from 'antd/es/upload';

const getImageQuality = (size: number) => {
  if (size < 1024 * 1024) {
    return 1;
  } else if (size < 10 * 1024 * 1024) {
    return 0.1;
  } else if (size < 50 * 1024 * 1024) {
    return 0.02;
  } else {
    return 0.01;
  }
};

async function compressedImage(
  file: RcFile | string | Blob,
  callback: (url: File) => void,
  failCallback?: (url: string) => void
) {
  const quality = getImageQuality((file as File).size);
  const base64: any = await blob2Base64(file);
  // 创建 Image 对象
  const img = new Image();
  img.src = base64;
  img.width = 500;
  img.height = 500;

  img.onload = function () {
    // 获取 canvas 元素
    const canvas: any = document.createElement('canvas');
    // 绘制图像到 canvas
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, img.width, img.height);
    // 使用 toDataURL 方法压缩图像
    const dataUrl = canvas.toDataURL('image/jpeg', quality);
    // 使用新的 Data URL 更新图像
    callback(
      dataURLtoFile(
        dataUrl,
        (file as RcFile).name.split('.')[0] +
          '-icon.' +
          (file as RcFile).name.split('.')[1]
      )
    );
  };
  img.onerror = function (err) {
    console.log(err);
    failCallback?.(
      'https://czcn57r03sa001dt.blob.core.chinacloudapi.cn/iqs-dmms-bff-service/svg%2Bxml/iqs-dmms-bff-service/20231026/356ec086-1546-48f1-912a-5a82d6c0a312.svg%2Bxml?sv=2020-02-10&st=2023-10-24T03%3A01%3A29Z&se=3023-10-25T03%3A01%3A00Z&sr=c&sp=racwdle&sig=HzKNzBl12kUbEul1g05zpkUK%2F%2FYNxDpR1aJS%2FdgnWis%3D'
    );
    // message.error('Image load error');
    // throw new Error('img load error');
  };
}

function blob2Base64(blob: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const base64 = reader.result?.toString() || '';
      resolve(base64);
    });
    reader.addEventListener('error', () => {
      reject(new Error('message'));
    });
    reader.readAsDataURL(blob);
  });
}

export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(','),
    mime = arr[0]?.match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]);
  let n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export default compressedImage;
