import { BASE_ROUTER_URL } from '@constants';
import type { BreadcrumbProps as AntdBreadcrumbProps } from 'antd';
import { pathToRegexp } from 'path-to-regexp';

type MenuDataItem = any;
type newAntdBreadcrumbProps = AntdBreadcrumbProps & {
  routes: any;
};

// /userInfo/2144/id => ['/userInfo','/userInfo/2144,'/userInfo/2144/id']
export const urlToList = (url?: string): string[] => {
  if (!url || url === '/') {
    return ['/'];
  }
  const urlList = url.split('/').filter((i) => i);
  return urlList.map((_, index) => `/${urlList.slice(0, index + 1).join('/')}`);
};

export interface BreadcrumbProps {
  location?: any;
  breadcrumbMap?: any;
  t: (message: string) => string;
}

export const getBreadcrumb = (
  breadcrumbMap: Map<string, MenuDataItem>,
  url: string
): MenuDataItem => {
  let breadcrumbItem = breadcrumbMap.get(url);
  if (!breadcrumbItem) {
    // 按照 route config 定义的顺序找到第一个匹配的路径
    const keys: string[] = Array.from(breadcrumbMap.keys()) || [];
    const targetPath = keys.find((path) =>
      pathToRegexp(path.replace('?', '')).test(url)
    );
    if (targetPath) breadcrumbItem = breadcrumbMap.get(targetPath);
  }
  return breadcrumbItem || { path: '' };
};

const conversionFromLocation = (
  routerLocation: BreadcrumbProps['location'],
  breadcrumbMap: Map<string, MenuDataItem>,
  props: BreadcrumbProps
): newAntdBreadcrumbProps['routes'] => {
  const pathSnippets = urlToList(
    routerLocation?.pathname?.replace(`${BASE_ROUTER_URL}`, '')
  );
  const extraBreadcrumbItems: newAntdBreadcrumbProps['routes'] = pathSnippets
    .map((url: string) => {
      const currentBreadcrumb = getBreadcrumb(breadcrumbMap, url);
      const name = props.t(currentBreadcrumb.locale);
      const { hideInBreadcrumb } = currentBreadcrumb;
      return name && !hideInBreadcrumb
        ? {
            path: url,
            breadcrumbName: name,
            component: currentBreadcrumb.component
          }
        : { path: '', component: '', breadcrumbName: '' };
    })
    .filter((item: any) => item?.path);

  return extraBreadcrumbItems;
};

/** 将参数转化为面包屑 */
export const genBreadcrumbProps = (
  props: BreadcrumbProps
): newAntdBreadcrumbProps['routes'] => {
  const { location, breadcrumbMap } = props;

  // 根据 location 生成 面包屑
  if (location?.pathname && breadcrumbMap) {
    return conversionFromLocation(location, breadcrumbMap, props);
  }
  return [];
};
