import { ReactElement } from 'react';
import { Alert } from 'antd';
import './index.scss';

const PageA = (): ReactElement => {
  return (
    <div>
      <Alert message="新增的用户默认进入首页，需要配置权限" type="info" />
    </div>
  );
};

export default PageA;
