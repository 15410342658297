import { Avatar, Checkbox, Select, SelectProps, Spin, Tag } from 'antd';
import { FC, ReactNode, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Updater } from 'use-immer';
import { AuthListResult, UserResult } from '@api/home/type';
import { OptionProps } from 'antd/es/select';
import {
  CloseOutlined,
  UserOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

// Usage of DebounceSelect
export interface UserValue {
  label: string;
  value: string;
}

interface UserSelectProps {
  roles: AuthListResult['roles'];
  users: UserResult[];
  selectedRoles: UserValue[];
  setSelectedRoles: Updater<(UserValue & { users: UserResult[] })[]>;
  selectedUsers: UserValue[];
  setSelectedUsers: Updater<UserValue[]>;
  userNeedMessage?: UserValue[];
  setUserNeedMessage?: Updater<UserValue[]>;
  selectUserAll: boolean;
  setSelectUserAll: (value: boolean) => void;
  selectMessageAll?: boolean;
  setSelectMessageAll?: (value: boolean) => void;
}

interface TagRole {
  value: string;
  label: string;
  roleId?: string;
  users: UserResult[];
  roleName?: string;
}

export type CustomTagProps = {
  label: React.ReactNode;
  value: any;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
};

const UserSelect: FC<UserSelectProps> = ({
  roles,
  users,
  selectedUsers,
  setSelectedUsers,
  selectedRoles,
  setSelectedRoles,
  userNeedMessage,
  setUserNeedMessage,
  selectUserAll,
  setSelectUserAll,
  setSelectMessageAll
}) => {
  const handleSelectedRoleChange = (role: UserValue, checked: boolean) => {
    console.log('role');
    console.log(role);
  };

  const handleSelectAllChange = () => {
    if (selectUserAll) {
      setSelectedUsers([]);
      setUserNeedMessage?.([]);
    } else {
      setSelectedUsers((draft) => [
        ...draft,
        { value: 'all', label: '所有用户' }
      ]);
      setUserNeedMessage?.((draft) => [
        ...draft,
        { value: 'all', label: '所有用户' }
      ]);
    }
    setSelectUserAll(!selectUserAll);
    setSelectMessageAll?.(!selectUserAll);
  };

  const handleSelectChange = (newValue: UserValue[], option: any) => {
    const roleList = (option as TagRole[])
      .filter((item) => item.roleId)
      .map((item) => ({ ...item, label: item.roleName || '' }));

    const userList = (
      option as {
        value: string;
        label: string;
        userId?: string;
        username?: string;
      }[]
    )
      .filter((item) => item.userId)
      .map((item) => ({ ...item, label: item.username || '' }));
    const userNeedMessageList = _.uniqBy(
      _.flatten(
        roleList.map(
          (role) =>
            role.users?.map((user) => ({
              ...user,
              value: user.userId,
              label: user.username
            })) || []
        )
      ).concat(userList),
      'value'
    );
    setSelectedRoles(roleList);
    if (!userList.filter((value) => value.value === 'all').length) {
      setSelectUserAll(false);
      setSelectMessageAll?.(false);
    }
    setSelectedUsers(userList);
    setUserNeedMessage?.(userNeedMessageList);
  };

  const handleTagCloseClick = (props: CustomTagProps) => {
    const resultUsers = selectedUsers.filter(
      (user) => user.value !== props.value
    );
    const resultRoles = (selectedRoles as TagRole[]).filter(
      (role) => role.value !== props.value
    );
    const userNeedMessageList = _.uniqBy(
      _.flatten(
        resultRoles.map(
          (role) =>
            role.users?.map((user) => ({
              ...user,
              value: user.userId,
              label: user.username
            })) || []
        )
      ).concat(resultUsers),
      'value'
    );
    setSelectedUsers(resultUsers);
    setSelectedRoles(resultRoles);
    setUserNeedMessage?.(userNeedMessageList);
  };

  const getOption = useMemo(() => {
    const options: { value: string; label: ReactNode; name?: string }[] = [];
    roles.map((role) => {
      options.push({
        ...role,
        value: role.roleId || '',
        label: (
          <div className="option-item-label">
            <UsergroupAddOutlined
              className="option-icon"
              style={{ marginRight: 8 }}
            />
            {role.roleName || ''}
          </div>
        ),
        name: role.roleName
      });
    });
    users.forEach((user) => {
      options.push({
        ...user,
        value: user.userId,
        label: (
          <div className="option-item-label">
            <UserOutlined className="option-icon" style={{ marginRight: 8 }} />
            {user.username}
            {user.username.includes('（ext）') && (
              <span
                style={{
                  marginLeft: 16,
                  fontSize: 12,
                  borderRadius: 4,
                  background: '#C2CDD6',
                  padding: '2px 8px'
                }}
              >
                外部用户
              </span>
            )}
          </div>
        ),
        name: user.username
      });
    });
    return options;
  }, [users, roles]);

  const tagRender = (props: CustomTagProps) => {
    return (
      // id长度小于19是角色id，19位是用户id
      <Tag
        className={`custom-tag ${props.value.length < 10 ? 'role-tag ' : ''}`}
      >
        {props.label}
        <CloseOutlined
          className="custom-tag-icon"
          onClick={() => handleTagCloseClick(props)}
        />
      </Tag>
    );
  };

  return (
    <div className="select-content">
      <Select
        allowClear
        className="user-select"
        labelInValue
        mode="multiple"
        options={getOption}
        bordered={false}
        onChange={handleSelectChange}
        value={_.cloneDeep(selectedUsers.concat(selectedRoles))}
        placeholder="请输入"
        showSearch
        optionFilterProp="name"
        tagRender={tagRender}
      />
      <div className="selected-user-wrapper">
        {selectedRoles.map((role) => (
          <div className="selected-user" key={role.value}>
            <div className="user-info">
              <span className="user-name">{role.label}</span>
            </div>
            <Checkbox
              onChange={(e: CheckboxChangeEvent) =>
                handleSelectedRoleChange(role, e.target.checked)
              }
            />
          </div>
        ))}
        <div className="selected-user" key={'all'}>
          <div className="user-info">
            <span className="user-name">所有用户</span>
          </div>
          <Checkbox
            checked={selectUserAll}
            onChange={() => handleSelectAllChange()}
          />
        </div>
        {/* {selectedUsers.map(
          (user) =>
            user.value !== 'all' && (
              <div className="selected-user" key={`select-${user.value}`}>
                <div className="user-info">
                  <Avatar style={{ backgroundColor: '#0072EF' }}>
                    {user.label?.split('')[0]}
                  </Avatar>
                  <span className="user-name">{user.label}</span>
                </div>
                <Checkbox
                  checked={
                    selectedUsers.findIndex(
                      (item) => item.value === user.value
                    ) > -1
                  }
                  onChange={() => handleSelectedUserChange(user)}
                />
              </div>
            )
        )} */}
      </div>
    </div>
  );
};

export default UserSelect;
