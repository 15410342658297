import useRoutes from '@/authority/useRoutes';
import AuthRoute from '@authority/AuthRoute';
import { BASE_NAME, TOKEN_KEY } from '@constants/index';
// import { compsRouter } from '@router/index';
import { routerInterFace } from '@router/types';
import { dataClassifications, i18nLang, userInfo } from '@store';
import { transformRoute } from '@umijs/route-utils';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import React, { ReactElement, Suspense, useEffect } from 'react';
import { AliveScope } from 'react-activation';
import { getI18n } from 'react-i18next';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import LazyLoader from './LazyLoader';
import { dayjsMap, pcLocale } from './i18n';
import appConfig from '@/appConfig';
import _ from 'lodash';
import { getDataClassifications, userLimitList } from '@api/permission';
import { getStorage } from '@utils/localStorageService';
import ForgetPassword from '@views/forget-password';
dayjs.extend(localeData);

// PC端
const Admin = React.lazy(() => import('./views/admin'));
const Login = React.lazy(() => import('./views/login'));
const Error = React.lazy(() => import('./views/notFound'));

const theme = {
  token: {
    colorPrimary: '#0072EF'
  }
};

// 页面針定坑
// const Redirect = ({ to }: { to: string }): ReactElement | null => {
//   const navigate = useNavigate();
//   useEffect(() => {
//     navigate(to);
//   }, []);
//   return null;
// };

const App = (): ReactElement => {
  const [userInfos, setUserInfo] = useRecoilState(userInfo);
  const lang = useRecoilValue(i18nLang);
  const ilang = lang || getI18n().language;
  const pLocle = pcLocale[ilang];
  dayjs.locale(dayjsMap[ilang]);
  dayjs().localeData();
  const routes = useRoutes(appConfig.routerType);
  const { menuData } = transformRoute(routes);
  const [, setDataClassifications] = useRecoilState(dataClassifications);
  // 获取权限，并加在用户信息中
  const getUserAuthority = () => {
    userLimitList().then((res: { userResources: any }) => {
      const userInfo = _.cloneDeep(userInfos);
      userInfo.authority = res?.userResources;
      setUserInfo(userInfo);
    });
  };
  // 获取数据分级枚举信息
  const getDataClassificationsQuirt = () => {
    getDataClassifications().then((res) => {
      setDataClassifications(res);
    });
  };
  // 判断是否登录
  const userTokens = getStorage(TOKEN_KEY);
  useEffect(() => {
    if (userTokens) {
      getUserAuthority();
      getDataClassificationsQuirt();
    }
  }, [userTokens]);

  // const flatRouterList = useMemo(() => getFlatRouterList(menuData), [menuData]);

  function convertDataToRoutes(data: routerInterFace[]): ReactElement[] {
    const routes: ReactElement[] = [];

    data.forEach((item) => {
      const routeElement = (
        <Route key={item.path} path={item.path} element={item.component}>
          {item.children && convertDataToRoutes(item.children)}
        </Route>
      );
      routes.push(routeElement);
    });

    return routes;
  }

  return (
    <ConfigProvider
      locale={pLocle}
      theme={theme}
      prefixCls="zeiss"
      popupMatchSelectWidth={true}
    >
      <div className="App" key={ilang}>
        <BrowserRouter basename={BASE_NAME}>
          <AliveScope>
            <Suspense fallback={<LazyLoader delay={300} />}>
              <AuthRoute>
                <Routes>
                  <Route path={'/'} element={<Admin />}>
                    {convertDataToRoutes(menuData)}
                    <Route path="/login" element={<Login />}></Route>
                    <Route
                      path="/forget-password"
                      element={<ForgetPassword />}
                    ></Route>
                  </Route>
                  <Route path="*" element={<Error />}></Route>
                </Routes>
              </AuthRoute>
            </Suspense>
          </AliveScope>
        </BrowserRouter>
      </div>
    </ConfigProvider>
  );
};

export default App;
