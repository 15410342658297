import ZeissButton from '@components/button';
import {
  Button,
  Table,
  TablePaginationConfig,
  TableProps,
  message
} from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import UserDetailHeader from './user-detail-header';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as ChecklistIcon } from '@/assets/image/checklist-icon.svg';
import { listResponseRecordsFace } from '@api/user/type';
import { queryAllRoleShow } from '@api/role';
import { RoleItem } from '@api/role/type';

interface UserDetailTableProps {
  data: listResponseRecordsFace[];
  pagination: TablePaginationConfig;
  handleTableChange: TableProps<listResponseRecordsFace>['onChange'];
  handleSearch: (searchValue?: string) => void;
  setEditUser: (user: listResponseRecordsFace[]) => void;
}

const UserDetailTable: FC<UserDetailTableProps> = ({
  data,
  pagination,
  handleTableChange,
  handleSearch,
  setEditUser
}) => {
  const [roles, setRoles] = useState<RoleItem[]>([]);
  const [multiple, setMultiple] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<listResponseRecordsFace[]>(
    []
  );

  const columns = [
    {
      title: '用户名',
      dataIndex: 'nickName',
      key: 'nickName'
    },
    {
      title: '邮箱',
      dataIndex: 'account',
      key: 'account'
    },
    {
      title: '角色',
      dataIndex: 'role',
      key: 'role',
      filters: roles.map((role) => ({
        text: role.roleName || '',
        value: role.id || ''
      })),
      ellipsis: true,
      render: (_: unknown, record: listResponseRecordsFace) =>
        record.roleList?.map((item) => item.roleName).join('、')
    },
    {
      title: '操作',
      key: 'actions',
      render: (_: unknown, record: listResponseRecordsFace) => (
        <>
          <Button
            type="link"
            onClick={() => handleClickEdit(record)}
            disabled={record.deleteStatus === 1}
          >
            分配角色
          </Button>
        </>
      )
    }
  ];

  const handleClickEdit = (record: listResponseRecordsFace) => {
    setEditUser([record]);
  };

  const getRoles = async () => {
    const { roles } = await queryAllRoleShow();
    setRoles(roles);
  };

  const handleClickMultiple = (value: boolean) => {
    // 点击取消多选，清空选中列表
    setMultiple(value);
    if (!value) {
      setSelectedUsers([]);
    }
  };

  const handleAssignRole = () => {
    if (!selectedUsers.length) {
      message.warning('请先选择要操作的用户');
    }
    if (selectedUsers.find((user) => user.deleteStatus)) {
      message.error('选中用户中包含被冻结的用户，无法分配角色，请解冻后再操作');
      return;
    }
    setEditUser(selectedUsers);
  };

  const buttons = (
    <>
      {!multiple ? (
        <ZeissButton
          className="color-btn"
          onClick={() => handleClickMultiple(true)}
          icon={<Icon component={ChecklistIcon} />}
        >
          选择
        </ZeissButton>
      ) : (
        <ZeissButton
          className="color-btn"
          onClick={() => handleClickMultiple(false)}
          icon={<Icon component={ChecklistIcon} />}
        >
          取消选择
        </ZeissButton>
      )}
      <ZeissButton
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleAssignRole}
      >
        分配角色
      </ZeissButton>
    </>
  );

  const extraProps = useMemo(
    () =>
      multiple
        ? ({
            rowSelection: {
              type: 'checkbox',
              onChange: (
                _: string[],
                selectedRows: listResponseRecordsFace[]
              ) => {
                setSelectedUsers(selectedRows);
              }
            }
          } as TableProps<listResponseRecordsFace>)
        : {},
    [multiple]
  );

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <div>
      <UserDetailHeader
        text={'请输入用户名或邮箱进行搜索'}
        buttons={buttons}
        handleSearch={handleSearch}
      />
      <Table
        rowKey={'id'}
        columns={columns}
        dataSource={data}
        pagination={pagination}
        onChange={handleTableChange}
        {...extraProps}
      />
    </div>
  );
};

export default UserDetailTable;
