import { FC, useEffect, useState } from 'react';

export const FullSpinner = () => {
  return <h2>Loading...</h2>;
};

export interface LazyLoaderProps {
  delay?: number;
}

const LazyLoader: FC<LazyLoaderProps> = ({ delay = 250, ...props }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? <FullSpinner {...props} /> : null;
};

export default LazyLoader;
