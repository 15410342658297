import Icon from '@ant-design/icons';
import { Input } from 'antd';
import { FC, ReactNode, useState } from 'react';
import { ReactComponent as SearchIcon } from '@/assets/image/search-icon.svg';
import { SearchProps } from 'antd/es/input';
import '../index.scss';

interface UserDetailHeaderProps {
  text?: string;
  handleSearch?: (searchValue: string) => void;
  buttons?: ReactNode;
}

const UserDetailHeader: FC<UserDetailHeaderProps> = ({
  text,
  handleSearch,
  buttons
}) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const handleSearchUser: SearchProps['onSearch'] = async (value: string) => {
    handleSearch?.(value);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleClickSearchIcon = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleSearchUser(searchInput);
  };
  return (
    <div className="search-header">
      <div className="search-wrapper">
        <Input.Search
          placeholder={text || '请输入搜索内容'}
          onChange={handleSearchInputChange}
          onSearch={handleSearch}
          suffix={
            <Icon
              component={SearchIcon}
              className="search-button"
              onClick={handleClickSearchIcon}
            />
          }
          bordered={false}
        />
      </div>
      <div className="header-buttons-wrapper">{buttons}</div>
    </div>
  );
};

export default UserDetailHeader;
