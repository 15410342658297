import { Spin, SpinProps } from 'antd';
import React, { Component } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { useOverlay } from './overlay/index';

interface LoadingProps extends SpinProps {}

let container: HTMLElement | null = null;
let rootInstance: Root | null = null;

class Loading extends Component<LoadingProps> {
  static displayName = 'Loading';
  static show: (config?: LoadingProps) => void;
  static hide: () => void;
  render(): React.ReactElement<LoadingProps> {
    return <Spin {...this.props} />;
  }
}

const clear = (): void => {
  if (container && rootInstance) {
    rootInstance.unmount(); // container 还在，render的div没了
    rootInstance = null;
    container.remove();
    container = null;
  }
};

const addInstance = (props: LoadingProps): void => {
  clear();
  container = useOverlay('loading-container', {
    center: true
  });
  if (container) {
    rootInstance = createRoot(container);
    rootInstance.render(<Loading {...props} />);
  }
};

Loading.show = (props: LoadingProps = {}) => {
  // const trueProps = typeof props === 'string' ? { tip: props } : props
  addInstance(props);
};

Loading.hide = () => {
  clear();
};

export { Loading };

export type { LoadingProps };
