import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { clearStorage, getStorage } from '@utils/localStorageService';
import { TOKEN_KEY } from '@constants';
import { useRecoilState } from 'recoil';
import { userInfo } from '@store/index';
/**
 * 处理路由权限
 * @param
 * */

const AuthRouter = (props: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, _] = useSearchParams();
  const [userInfoFromStore] = useRecoilState(userInfo);
  useEffect(() => {
    const redirectTo = searchParams.get('redirectTo');
    // 这里判断token是否存在以及authority和roleList
    if (
      !getStorage(TOKEN_KEY) ||
      !userInfoFromStore?.authority ||
      !userInfoFromStore?.roleList
    ) {
      clearStorage(TOKEN_KEY);
      navigate(redirectTo ? `/login?redirectTo=${redirectTo}` : '/login');
      return;
    }
    // 这里判断条件是：token 存在并且是匹配到路由并且是已经登录的状态
    if (getStorage(TOKEN_KEY)) {
      // 如果你已经登录了，但是你通过浏览器里直接访问login的话不允许直接跳转到login路由，必须通过logout来控制退出登录或者是token过期返回登录界面
      if (location.pathname === '/' || location.pathname === '/login') {
        navigate(redirectTo ? `/login?redirectTo=${redirectTo}` : '/login');
      } else {
        // 如果是其他路由就跳到其他的路由
        navigate(location.pathname);
      }
    }
  }, []);
  return props.children;
};
export default AuthRouter;
