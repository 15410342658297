import { FC, useEffect, useState } from 'react';
import HistoryTable from './components/history-table';
import { useImmer } from 'use-immer';
import { HistoryListRecord } from '@api/history/types';
import { shareHistoryExport, shareHistoryList } from '@api/history';
import { TableProps, message } from 'antd';
import ZeissButton from '@components/button';
import { downloadFileBlob, uuid } from '@utils/tool';
import UserDetailHeader from '../permission-management/component/user-detail-header';
import { SorterResult } from 'antd/es/table/interface';

type ShareHistory = HistoryListRecord & {
  children?: ShareHistory[];
};

const ShareHistory: FC = () => {
  const [shareList, setShareList] = useImmer<ShareHistory[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [pagination, setPagination] = useState({
    sizeCanChange: true,
    total: 0,
    pageSize: 10,
    current: 1,
    showQuickJumper: true,
    showSizeChanger: true
  });

  const getShareList = async (
    pageNumber?: number,
    pageSize?: number,
    searchValue?: string,
    createTimeValue?: number
  ) => {
    const { records, total, size, current } = await shareHistoryList({
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 10,
      searchContent: searchValue,
      createTimeValue
    });

    setShareList(records || []);
    setPagination({ ...pagination, pageSize: size, total, current });
  };

  const handleTableChange: TableProps<HistoryListRecord>['onChange'] = async (
    paginationConfig,
    filters,
    sorter
  ) => {
    setPagination({
      ...pagination,
      pageSize: paginationConfig.pageSize || 10,
      current: paginationConfig.current || 1
    });
    await getShareList(
      paginationConfig.current,
      paginationConfig.pageSize,
      searchValue,
      (sorter as SorterResult<HistoryListRecord>).order === 'ascend' ? 1 : 2
    );
  };

  const handleExportShareHistory = async () => {
    if (!selectedRowKeys.length) {
      message.error('请先选择要下载的记录');
      return;
    }
    const data = await shareHistoryExport({ ids: selectedRowKeys });
    downloadFileBlob(data);
  };

  const columns = [
    {
      title: '操作人',
      dataIndex: 'createUser',
      key: 'createUser'
    },
    {
      title: '邮箱',
      dataIndex: 'mailbox',
      key: 'mailbox'
    },
    {
      title: '操作时间',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: true
    },
    {
      title: '操作内容',
      dataIndex: 'content',
      key: 'content',
      ellipsis: true,
      render: (_: unknown, record: HistoryListRecord) =>
        record.content.join('、')
    },
    {
      title: '分享给',
      dataIndex: 'shareUser',
      key: 'shareUser',
      ellipsis: true,
      render: (_: unknown, record: HistoryListRecord) =>
        record.shareUser.join('、')
    }
  ];

  const buttons = [
    <ZeissButton
      key={'export'}
      type="primary"
      onClick={handleExportShareHistory}
    >
      下载
    </ZeissButton>
  ];

  useEffect(() => {
    getShareList(1, pagination.pageSize, searchValue);
  }, [searchValue]);

  return (
    <div>
      <div className="page-title">分享历史列表</div>
      <UserDetailHeader
        text={'请输入操作人/邮箱/操作内容/分享对象进行搜索'}
        buttons={buttons}
        handleSearch={(searchValue) => setSearchValue(searchValue)}
      />
      <HistoryTable
        data={shareList}
        columns={columns}
        pagination={pagination}
        handleTableChange={handleTableChange}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </div>
  );
};

export default ShareHistory;
