import { Loading } from '@components/loading/loading';
import { RcFile } from 'antd/es/upload';
import { dataURLtoFile } from './image';

async function uploadVideo(
  file: string | Blob | RcFile,
  callback: (url: File) => void
) {
  Loading.show();
  const video = document.createElement('video') as HTMLVideoElement;
  const source = document.createElement('source');
  source.src = URL.createObjectURL(file as Blob);
  video.appendChild(source);
  video.setAttribute('crossOrigin', 'anonymous');
  video.currentTime = 1;
  video.onloadeddata = () => {
    const canvas: any = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext('2d')
      ?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const dataURL = canvas.toDataURL('image/png', 0.1);
    callback(dataURLtoFile(dataURL, (file as RcFile).name + 'icon'));
    Loading.hide();
  };
}

export default uploadVideo;
