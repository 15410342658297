import { PlusOutlined } from '@ant-design/icons';
import ZeissButton from '@components/button';
import { Button, Popconfirm, Table, TablePaginationConfig } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { deleteCategory, getCategoryList } from '@api/category';
import { useImmer } from 'use-immer';
import { CategoryListItem, QueryCategoryListParams } from '@api/category/type';
import ZeissBreadcrumb from '@components/breadcrumb';
import { useNavigate } from 'react-router-dom';

const CategoryManagement: FC = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useImmer<CategoryListItem[]>([]);
  const [pagination, setPagination] = useState({
    total: 96,
    pageSize: 10,
    current: 1
  });
  const navigate = useNavigate();

  const queryCategoryList = async (params: QueryCategoryListParams) => {
    const data = await getCategoryList({ ...params, type: 1 });
    setCategories(data?.records);
    setPagination({
      ...pagination,
      pageSize: data?.size || 10,
      current: data?.current || 1,
      total: data?.total || 10
    });
  };

  useEffect(() => {
    queryCategoryList({});
  }, []);

  const handleClickRootCreate = () => {
    navigate(`/create-category`);
  };

  const handleClickEditSubCategory = (record: CategoryListItem) => {
    navigate(`/create-category?id=${record.item.id}`);
  };

  const handleClickDeleteSubCategory = async (node: CategoryListItem) => {
    if (!node.id) return;
    try {
      await deleteCategory({
        deletes: [{ id: node.id, parentId: node.parentId }]
      });
      await queryCategoryList({});
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeTable = async (paginationConfig: TablePaginationConfig) => {
    setPagination({
      ...pagination,
      pageSize: paginationConfig.pageSize || 10,
      current: paginationConfig.current || 1
    });

    try {
      await queryCategoryList({
        pageNumber: paginationConfig.current,
        pageSize: paginationConfig.pageSize
      });
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      key: 'name',
      title: '分类名称',
      dataIndex: 'name',
      width: '80%'
    },
    {
      key: 'actions',
      title: t('pages.user.Operation'),
      dataIndex: 'id',
      render: (_: unknown, record: CategoryListItem) => {
        return (
          <>
            <Popconfirm
              title={t('btn.delete')}
              description={t('page.category.deleteConfirm')}
              onConfirm={() => handleClickDeleteSubCategory(record)}
              okText={t('btn.confirm')}
              cancelText={t('btn.cancel')}
              disabled={!!record.item.hasChildren}
            >
              <Button type="link" disabled={!!record.item.hasChildren}>
                {t('btn.delete')}
              </Button>
            </Popconfirm>
            <ZeissButton
              type="link"
              onClick={() => handleClickEditSubCategory(record)}
            >
              {t('btn.edit')}
            </ZeissButton>
          </>
        );
      }
    }
  ];

  return (
    <div className="category-wrapper wrapper">
      <ZeissBreadcrumb />
      <div className="category-header">
        <div className="page-title">{t('page.category.title')}</div>
        <ZeissButton
          type="primary"
          onClick={handleClickRootCreate}
          icon={<PlusOutlined />}
        >
          {t('page.category.addCategory')}
        </ZeissButton>
      </div>
      <div className="category-table-wrapper">
        <Table
          dataSource={categories}
          columns={columns}
          rowKey={'id'}
          size="small"
          pagination={pagination}
          onChange={handleChangeTable}
        />
      </div>
    </div>
  );
};

export default CategoryManagement;
