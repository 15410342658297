import { getLabelList, queryFileLabel, updateLabel } from '@api/home';
import ZeissButton from '@components/button';
import { Select, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import '../../content-list/upload.scss';
import { useRecoilState } from 'recoil';
import { CategoryListItem } from '@api/category/type';
import { selectedFile } from '@store';

const EditTags: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tagList, setTagList] = useState<{ value: string; label: string }[]>(
    []
  );
  const [selectedTags, setSelectedTags] = useState<
    { value: string; label: string }[]
  >([]);
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [selectedFileList, setSelectedFileList] =
    useRecoilState<CategoryListItem[]>(selectedFile);

  const getLabels = async (name?: string) => {
    try {
      const data = await getLabelList({ name });
      setTagList(
        data?.map((item) => ({ value: item.id, label: item.name })) || []
      );
    } catch (error) {}
  };

  const getFileLabel = async () => {
    try {
      await queryFileLabel({ categoryId: '' });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = async (
    items: { value: string; label: string }[]
  ) => {
    setSelectedTags(
      items.map((item) => ({
        value: item.value,
        label: item.label || item.value
      }))
    );
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleEditTags = async () => {
    try {
      await updateLabel({
        fileIds,
        labels: selectedTags.map((tag) => tag.label).join(',')
      });
      message.success('编辑标签成功');
      setSelectedFileList([]);
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLabels();
  }, []);

  useEffect(() => {
    const fileList = location.state?.fileList;
    if (fileList?.length === 1) {
      const defaultTags: { value: string; label: string }[] = [];
      (fileList[0].labels as string).split(',').forEach((label) => {
        const item = tagList.find((tag) => tag.label === label);
        if (!item) return;
        defaultTags.push(item);
      });
      setSelectedTags(_.uniqBy(defaultTags, 'value'));
    }
  }, [location.state?.fileList, tagList]);

  useEffect(() => {
    const fileList = location.state?.fileList;
    if (!fileList) return;
    setFileIds((fileList as { fileId: string }[]).map((file) => file.fileId));
  }, [location.state?.fileList]);

  return (
    <div className="wrapper">
      <div className="page-subtitle">编辑标签</div>
      <div className="upload-select-wrapper" style={{ width: 600 }}>
        <Select
          labelInValue
          className="upload-select"
          // fieldNames={{ value: 'id', label: 'name' }}
          mode="tags"
          style={{ width: '100%' }}
          placeholder="选择标签"
          onChange={handleSelectChange}
          bordered={false}
          options={tagList}
          showSearch
          optionFilterProp="name"
          value={selectedTags}
        />
      </div>
      <div style={{ marginTop: 40 }}>
        <ZeissButton className="color-btn" onClick={handleCancel}>
          取消
        </ZeissButton>
        <ZeissButton
          type="primary"
          style={{ marginLeft: 8 }}
          onClick={handleEditTags}
        >
          确定
        </ZeissButton>
      </div>
    </div>
  );
};

export default EditTags;
