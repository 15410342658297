import Icon from '@ant-design/icons';
import { ReactComponent as DeleteIcon } from '@/assets/image/delete-icon.svg';
import {
  Button,
  Col,
  Input,
  Popconfirm,
  Row,
  UploadProps,
  message
} from 'antd';
import uploadIcon from '@/assets/image/upload-icon.svg';
import './index.scss';
import Dragger from 'antd/es/upload/Dragger';
import { useEffect } from 'react';

// TODO 测试图片
import { useImmer } from 'use-immer';
import type { UploadRequestOption, RcFile } from 'rc-upload/lib/interface';
import { BannerInfo } from '@api/banner/type';
import { uploadBannersService } from '@api/banner';

interface BannerProps {
  item: BannerInfo;
  index: number;
  onDelete: (title: string) => void;
  onUpdate: (banner: BannerInfo) => void;
}
const BannerBox: React.FC<BannerProps> = (props: BannerProps) => {
  const { item, index, onDelete, onUpdate } = props;
  const [banner, setBanner] = useImmer<BannerInfo>(item);
  useEffect(() => {
    onUpdate(banner);
  }, [banner]);

  const handleUploadFile = async (options: UploadRequestOption) => {
    const { file } = options;
    const formData = new FormData();
    formData.append('file', file);

    const data = await uploadBannersService({ formData });
    setBanner((draft) => ({ ...draft, url: data.fileUrl }));
  };

  const handleMobileUploadFile = async (options: UploadRequestOption) => {
    const { file } = options;
    const formData = new FormData();
    formData.append('file', file);

    const data = await uploadBannersService({ formData });
    setBanner((draft) => ({ ...draft, mobileUrl: data.fileUrl }));
  };

  const checkImage = (
    file: RcFile,
    ratio: [number, number],
    width?: number,
    height?: number
  ) => {
    return new Promise<boolean>((resolve, reject) => {
      const url = window.URL.createObjectURL(file);
      const imageElement = new Image();
      imageElement.onload = () => {
        if (
          imageElement.width / imageElement.height <= ratio[0] ||
          imageElement.width / imageElement.height >= ratio[1]
        ) {
          message.error('图片尺寸不符合');
          resolve(false);
        } else {
          resolve(true);
        }
      };
      imageElement.src = url;
    });
  };

  const handleBeforeUpload = async (file: RcFile) => {
    return await checkImage(file, [3.8, 4.2]);
  };

  const handleMobileBeforeUpload = async (file: RcFile) => {
    return await checkImage(file, [1.6, 1.9]);
  };

  const uploadProps: UploadProps = {
    showUploadList: false,
    name: 'file',
    multiple: false,
    accept: ''
  };

  const handleChangeLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBanner((d) => {
      d.targetUrl = e.target?.value;
    });
  };
  return (
    <div className="banner-box">
      <Row>
        <Col span={16}>
          <div className="header-title">
            <div className="title">轮播图{index + 1}</div>
            <Popconfirm
              title={`确认删除轮播图${index + 1}?`}
              onConfirm={() => onDelete(banner.title)}
            >
              <Button
                className="delete-icon"
                type="link"
                icon={<Icon component={DeleteIcon} />}
              >
                删除
              </Button>
            </Popconfirm>
          </div>
          <div className="cell">
            <Dragger
              {...{
                ...uploadProps,
                beforeUpload: handleBeforeUpload,
                customRequest: handleUploadFile
              }}
            >
              {banner.url ? (
                <img width={'100%'} height={'200px'} src={banner.url} alt="" />
              ) : (
                <>
                  <p className="ant-upload-drag-icon">
                    <img src={uploadIcon} alt="" />
                  </p>
                  <p className="ant-upload-text">导入PC端图片</p>
                  <p className="ant-upload-hint">
                    拖动图片到此处（图片尺寸4：1）
                  </p>
                </>
              )}
            </Dragger>
            <Dragger
              {...{
                ...uploadProps,
                beforeUpload: handleMobileBeforeUpload,
                customRequest: handleMobileUploadFile
              }}
              className="mobile-banner"
            >
              {banner.mobileUrl ? (
                <img
                  width={'375px'}
                  height={'210px'}
                  src={banner.mobileUrl}
                  alt=""
                />
              ) : (
                <>
                  <p className="ant-upload-drag-icon">
                    <img src={uploadIcon} alt="" />
                  </p>
                  <p className="ant-upload-text">导入Mobile图片</p>
                  <p className="ant-upload-hint">
                    拖动图片到此处（图片尺寸16：9）
                  </p>
                </>
              )}
            </Dragger>
          </div>
          <div className="cell">
            <span className="link-title">超链接</span>
            <Input
              className="banner-link"
              placeholder="请输入"
              value={banner.targetUrl}
              onChange={handleChangeLink}
            ></Input>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BannerBox;
