/**
 * @module axios配置
 */
import i18n, { notificationMap, valueZHCN } from '@/i18n';
import { Loading } from '@components/loading/loading';
import { BASE_ROUTER_URL, IS_DEV, TOKEN_KEY } from '@constants';
import { message } from 'antd';
import axios from 'axios';
import { getRequestCommonHeaders } from './requestCommonHeaders';
import { clearStorage } from './localStorageService';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const myAxios = axios.create({ cancelToken: source.token });
myAxios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// 超时时间设置
myAxios.defaults.timeout = 30000;
// axios请求拦截
myAxios.interceptors.request.use((conf: any) => {
  if (!conf.url.includes('loading=false')) {
    Loading.show();
  }
  conf.headers = {
    ...getRequestCommonHeaders(conf?.headers['Content-Type'])
  };
  // 全局api添加当前国际化type参数
  // const lan = localStorage.getItem('i18nextLng') ?? valueZHCN;
  // const lanType = notificationMap[lan];
  // conf.data = { ...conf.data, lanType };
  // api 处理
  return conf;
});

// axios返回拦截
myAxios.interceptors.response.use(
  (response) => {
    if (response.config?.responseType === 'blob') {
      Loading.hide();
      return response;
    }
    // eslint-disable-next-line
    // @ts-ignore
    if (response.config.skipStatus) {
      Loading.hide();
      if (response.status === 200) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response.data.msg || 'Error');
      }
    }
    if (response.status === 200 && response.data.code === 200) {
      // pathname
      // origin
      Loading.hide();
      const data = response.data.data;

      return Promise.resolve(data);
    } else {
      Loading.hide();
      const code: number = response.data?.code;
      switch (response.data.code) {
        case 50000:
          message.error('服务器开小差了！');
          break;
        // case 401:
        //   message.error(
        //     '未登录或登录已过期，请重新登录'
        //   );
        //   setTimeout(() => {
        //     window.location.href = IS_DEV
        //       ? '/login'
        //       : `${BASE_ROUTER_URL}/login`;
        //   }, 2000);
        //   window.localStorage.removeItem('recoil-persist');
        //   clearStorage(TOKEN_KEY);
        //   break;
        case 403:
          message.error(
            'You have been deleted or re-granted by the administrator, please log in again！'
          );
          setTimeout(() => {
            window.location.href = `${BASE_ROUTER_URL}/login`;
          }, 2000);
          window.localStorage.removeItem('recoil-persist');
          clearStorage(TOKEN_KEY);
          break;
        default:
          message.error(
            i18n.exists(`pages.rc.${code}`)
              ? i18n.t(`pages.rc.${code}`)
              : response.data.msg
          );
      }
      return Promise.reject(response.data.msg || 'Error');
    }
  },
  (error) => {
    Loading.hide();
    if (error?.response) {
      switch (error.response.status) {
        case 400:
          message.error('错误请求！');
          break;
        case 401:
          message.error('未授权请求重新登录！');
          break;
        case 403:
          message.error('拒绝访问！');
          break;
        case 404:
          message.error('请求错误，未找到该资源！');
          break;
        case 405:
          message.error('请求方法未允许');
          break;
        case 408:
          message.error('错误请求！');
          break;
        case 500:
          message.error('服务端出错！');
          break;
        case 501:
          message.error('网络未实现！');
          break;
        case 502:
          message.error('网络错误！');
          break;
        case 503:
          message.error('服务不可用！');
          break;
        case 504:
          message.error('网络超时！');
          break;
        case 505:
          message.error('http版本不支持该请求！');
          break;
      }
      return Promise.reject(error.response.status);
    }
  }
);

export default myAxios;
