export default {
  'account.multiple': '多选',
  'account.cancelMultiple': '取消多选',
  'account.bulkCreate': '批量新建',
  'account.createAccount': '新建账号',
  'account.btn.create': '新建并发送通知',
  'account.username': '用户名',
  'account.importAccount': '导入名单',
  'account.uploadFile': '上传文件',
  'account.btn.freeze': '冻结',
  'account.btn.bulkFreeze': '批量冻结',
  'account.btn.thaw': '解冻',
  'account.btn.bulkThaw': '批量解冻',
  'account.btn.frozen': '冻结',
  'account.btn.activity': '活跃',
  'account.bulkFreezeConfirm': '确认批量冻结所选账号？',
  'account.freezeConfirm': '确认冻结所选账号？',
  'account.bulkThawConfirm': '确认批量解冻所选账号？',
  'account.thawConfirm': '确认解冻所选账号？',
  'account.unknownStatus': '未知状态',
  'account.errMsg': '错误信息'
};
