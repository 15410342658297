import { FC, useEffect, useState } from 'react';
import HistoryTable from './components/history-table';
import { useImmer } from 'use-immer';
import { HistoryListRecord } from '@api/history/types';
import { downloadHistoryExport, downloadHistoryList } from '@api/history';
import { TableProps, message } from 'antd';
import UserDetailHeader from '../permission-management/component/user-detail-header';
import ZeissButton from '@components/button';
import { downloadFileBlob } from '@utils/tool';
import { SorterResult } from 'antd/es/table/interface';

const DownloadHistory: FC = () => {
  const [downloadList, setDownloadList] = useImmer<HistoryListRecord[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const [pagination, setPagination] = useState({
    sizeCanChange: true,
    total: 0,
    pageSize: 10,
    current: 1,
    showQuickJumper: true,
    showSizeChanger: true
  });

  const getDownloadList = async (
    pageNumber?: number,
    pageSize?: number,
    searchValue?: string,
    createTimeValue?: number
  ) => {
    const { records, total, size, current } = await downloadHistoryList({
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 10,
      searchContent: searchValue,
      createTimeValue
    });
    setPagination({ ...pagination, total, pageSize: size, current });
    setDownloadList(records || []);
  };

  const handleTableChange: TableProps<HistoryListRecord>['onChange'] = async (
    paginationConfig,
    filters,
    sorter
  ) => {
    setPagination({
      ...pagination,
      pageSize: paginationConfig.pageSize || 10,
      current: paginationConfig.current || 1
    });
    await getDownloadList(
      paginationConfig.current,
      paginationConfig.pageSize,
      searchValue,
      (sorter as SorterResult<HistoryListRecord>).order === 'ascend' ? 1 : 2
    );
  };

  const handleExportDownloadHistory = async () => {
    if (!selectedRowKeys.length) {
      message.error('请先选择要下载的记录');
      return;
    }
    const data = await downloadHistoryExport({ ids: selectedRowKeys });
    downloadFileBlob(data);
  };

  const columns = [
    {
      title: '操作人',
      dataIndex: 'createUser',
      key: 'createUser'
    },
    {
      title: '邮箱',
      dataIndex: 'mailbox',
      key: 'mailbox'
    },
    {
      title: '操作时间',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: true
    },
    {
      title: '操作内容',
      dataIndex: 'content',
      key: 'content'
    }
  ];

  const buttons = [
    <ZeissButton
      key={'export'}
      type="primary"
      onClick={handleExportDownloadHistory}
    >
      下载
    </ZeissButton>
  ];

  useEffect(() => {
    getDownloadList(1, pagination.pageSize, searchValue);
  }, [searchValue]);

  return (
    <div>
      <div className="page-title">下载历史列表</div>
      <UserDetailHeader
        text={'请输入操作人/邮箱/操作内容进行搜索'}
        buttons={buttons}
        handleSearch={(searchValue) => setSearchValue(searchValue)}
      />
      <HistoryTable
        data={downloadList}
        columns={columns}
        pagination={pagination}
        handleTableChange={handleTableChange}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </div>
  );
};

export default DownloadHistory;
