// import httpServer from '@utils/request'
import request from '@/utils/request';
import { EmaiCodeParam, loginInterface } from './type';

export const getDicList = (): Promise<{}> => {
  return request({
    url: '/dic/list',
    method: 'post'
  });
};

export const getLogin = (data: loginInterface): Promise<{ token: string }> => {
  return request({
    url: '/system/login/signin',
    method: 'post',
    data
  });
};

export const getSSOLogin = (): Promise<{
  authenticationUrl: string;
}> => {
  return request({
    url: '/system/login/authUrl',
    method: 'post',
    data: { clientType: 2 }
  });
};

export const sendOpreationEmailCode = (
  data: EmaiCodeParam
): Promise<{ code: number }> => {
  return request({
    url: '/system/login/sendEmailCode',
    method: 'post',
    data
  });
};

export const passwordEdit = (data: any): Promise<{}> => {
  return request({
    url: '/system/user/forgetPassword',
    method: 'post',
    data
  });
};

export const getLogout = (): Promise<{}> => {
  return request({
    url: '/system/login/signout',
    method: 'post'
  });
};

export const getCurrentUser = (): Promise<{}> => {
  return request({
    url: '/system/user/getUserDetail',
    method: 'post'
  });
};

export const getClientOptions = (data: any): Promise<{}> => {
  return request({
    url: '/message/getClientOptions',
    method: 'post',
    data
  });
};
