import { Layout, Menu, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import './index.scss';
import _ from 'lodash';
import { useImmer } from 'use-immer';
import { getNavigator } from '@api/home';
import { CategoryListItem } from '@api/category/type';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import ZeissButton from '@components/button';
import type { SelectEventHandler } from 'rc-menu/lib/interface';
import { addTreeKeyToArray } from '@utils/tool';
import Icon from '@ant-design/icons';
import { ReactComponent as ExpandIcon } from '@/assets/image/expand.svg';
import { ReactComponent as CollapseIcon } from '@/assets/image/collapse.svg';

export const HomeTabs = ['Home', 'NEW', 'HOT', 'SEARCH'];

const Contents: FC = () => {
  const { Sider } = Layout;
  const navigate = useNavigate();
  const location = useLocation();

  const [navigatorTree, setNavigatorTree] = useImmer<CategoryListItem[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const getNavigatorList = async () => {
    const data = await getNavigator({});
    setNavigatorTree(data);
  };

  useEffect(() => {
    getNavigatorList();
  }, [location.pathname]);

  const formatItems = (item: any) => {
    const result = _.cloneDeep(item);

    const addPropertyToNestedObject = (obj: any) => {
      for (const key in obj) {
        obj['onTitleClick'] = (info: { key: string }) => {
          setSelectedKeys([info.key]);
          const openIndex = openKeys.findIndex((key) => key === info.key);
          if (openIndex > -1) {
            setOpenKeys(openKeys.filter((key) => key !== info.key));
          } else {
            setOpenKeys(openKeys.concat([info.key]));
          }
          navigate(`/contents/category/${info.key}`);
        };
        obj['label'] = obj.name;
        obj['key'] = obj.id;
        if (typeof obj[key] === 'object') {
          // 如果当前属性是对象，则递归调用函数
          addPropertyToNestedObject(obj[key]);
        }
      }
      return obj;
    };
    return addPropertyToNestedObject(result);
  };

  const handleExpandAll = () => {
    setOpenKeys(addTreeKeyToArray(navigatorTree));
  };

  const handleSelected: SelectEventHandler = (info) => {
    setSelectedKeys(info.selectedKeys);
  };

  return (
    <Layout>
      <Sider width={240} className="content-aside">
        <div className="collapse-wrapper">
          {openKeys.length ? (
            <Tooltip title={'全部收起'}>
              <Icon
                className="collapse-icon"
                component={CollapseIcon}
                onClick={() => setOpenKeys([])}
              />
            </Tooltip>
          ) : (
            <Tooltip title={'全部展开'}>
              <Icon
                className="collapse-icon"
                component={ExpandIcon}
                onClick={handleExpandAll}
              />
            </Tooltip>
          )}
        </div>
        <Link
          className={`${
            location.pathname.includes('/home') && 'active'
          } home-nav`}
          to={'/contents/home'}
        >
          Home
        </Link>
        <Menu
          className="left-menu"
          theme="light"
          mode="inline"
          items={navigatorTree?.map((item) => formatItems(item))}
          style={{ width: '100%' }}
          onClick={(value) => {
            navigate(`/contents/category/${value.key}`);
          }}
          openKeys={openKeys}
          // selectedKeys={selectedKeys}
          onSelect={handleSelected}
        />
      </Sider>
      <div className="wrapper-container" style={{ width: '100%' }}>
        <Outlet />
      </div>
    </Layout>
  );
};

export default Contents;
