import { CategoryListItem } from '@api/category/type';
import {
  deleteFile,
  downloadRecord,
  updateDataClassificationQuirte
} from '@api/home';
import { FC, useEffect, useState } from 'react';
import ContentHeader from './content-header/content-header';
import ContentTable from './content-table/content-table';
import {
  Button,
  Drawer,
  FloatButton,
  Form,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Table,
  TablePaginationConfig,
  Tag
} from 'antd';
import ContentView from './content-table/content-view';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dataClassifications, selectedFile, userInfo } from '@store';
import Icon, { ShoppingCartOutlined } from '@ant-design/icons';
import { UserInfoAuthority, hasAuth } from '@utils/tool';
import ZeissButton from '@components/button';
import { ReactComponent as DeleteIcon } from '@/assets/image/delete-icon.svg';
import { ReactComponent as DownIcon } from '@/assets/image/download.svg';
import _ from 'lodash';
import download from '@utils/download';
import './content.scss';
import dayjs from 'dayjs';
import ZeissModal from '@components/zeiss-modal';
import ZeissSelect from '@components/select';

export const tagsColors = ['volcano', 'processing', 'cyan', 'blue'];
export const getTagColorIndex = () => Math.floor(Math.random() * 5);

interface ContentProps {
  data: CategoryListItem[];
  fetchData: (pagination?: TablePaginationConfig) => Promise<void>;
  pagination: TablePaginationConfig;
  setPagination: (pagination: TablePaginationConfig) => void;
  breadcrumb?: { paths: string; pathNames: string };
  loading?: boolean;
}

const Content: FC<ContentProps> = ({
  data,
  fetchData,
  pagination,
  setPagination,
  breadcrumb,
  loading
}) => {
  const [form] = Form.useForm();
  const [multiple, setMultiple] = useState<boolean>(false);
  const [viewType, setViewType] = useState<'list' | 'view'>('list');
  const [open, setOpen] = useState<boolean>(false);
  const [userInfos, setUserInfo] = useRecoilState<{
    authority: UserInfoAuthority[];
  }>(userInfo);
  const [selectedFileList, setSelectedFileList] =
    useRecoilState<CategoryListItem[]>(selectedFile);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const handleFloatButtonClick = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDownLoad = async () => {
    // TODO 下载接口
    const params = selectedFileList
      .filter((item) => item.item.fileUrl)
      .map((item) => ({
        id: item.item.fileId,
        fileName: item.name,
        url: item.item.fileUrl || ''
      }));
    params.forEach((file) => download(file?.fileName, file?.url));
    await downloadRecord({ fileIds: _.compact(params.map((file) => file.id)) });
    await fetchData();
    setOpen(false);
    setSelectedFileList([]);
  };

  const handleRemoveItem = (record: CategoryListItem) => {
    setSelectedFileList((draft) =>
      draft.filter((item) => item.id !== record.id)
    );
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      render: (_: unknown, record: CategoryListItem) => record.item.suffix
    },
    {
      title: '操作',
      key: 'actions',
      render: (_: unknown, record: CategoryListItem) => (
        <ZeissButton danger onClick={() => handleRemoveItem(record)}>
          移除
        </ZeissButton>
      )
    }
  ];

  // 数据分级
  const dataClassificationss = useRecoilValue(dataClassifications).map(
    (v: { valueMsg: string; code: number }) => ({
      label: v.valueMsg,
      value: v.code
    })
  );
  const [dataClassificationModal, setDataClassificationModal] = useState(false);
  const [chooseIds, setChooseIds] = useState<string[]>([]);
  const updateDataClassification = (chooseId: string[]) => {
    setChooseIds(chooseId);
    setDataClassificationModal(true);
  };
  const handleDataClassSubmit = async () => {
    form.validateFields().then(async (res) => {
      await updateDataClassificationQuirte({
        fileIds: chooseIds,
        dataClassification: res.dataClassification
      });
      await fetchData();
      setMultiple(false);
      setSelectedFileList([]);
      setDataClassificationModal(false);
    });
  };

  return (
    <>
      <ContentHeader
        list={data}
        viewType={viewType}
        setViewType={setViewType}
        multiple={multiple}
        setMultiple={setMultiple}
        fetchData={fetchData}
        breadcrumb={breadcrumb}
        expandedRowKeys={expandedRowKeys}
        setExpandedRowKeys={setExpandedRowKeys}
        updateDataClassification={updateDataClassification}
      />
      {viewType === 'list' && (
        <ContentTable
          list={data}
          multiple={multiple}
          pagination={pagination}
          setPagination={setPagination}
          fetchData={fetchData}
          expandedRowKeys={expandedRowKeys}
          setExpandedRowKeys={setExpandedRowKeys}
          loading={loading}
          updateDataClassification={updateDataClassification}
        />
      )}
      {viewType === 'view' && (
        <ContentView list={data || []} multiple={multiple} />
      )}
      <FloatButton
        onClick={handleFloatButtonClick}
        badge={{
          count: selectedFileList.length,
          overflowCount: 100
        }}
      />
      <Drawer
        title={'下载列表'}
        open={open}
        onClose={handleDrawerClose}
        width={1000}
        extra={
          <>
            <ZeissButton
              danger
              onClick={() => setSelectedFileList([])}
              className="remove-all-button"
            >
              移除全部
            </ZeissButton>
            {hasAuth(userInfos.authority, 'download') && (
              <ZeissButton
                className="primary-btn action-button"
                type="primary"
                onClick={handleDownLoad}
                icon={<Icon component={DownIcon} />}
              >
                下载
              </ZeissButton>
            )}{' '}
          </>
        }
      >
        <div className="drawer">
          <Table
            dataSource={selectedFileList}
            columns={columns}
            pagination={false}
          />
        </div>
      </Drawer>
      <ZeissModal
        title="编辑数据分级"
        closable
        centered
        open={dataClassificationModal}
        maskClosable={false}
        onOk={() => handleDataClassSubmit()}
        onCancel={() => setDataClassificationModal(false)}
        okText="Confirm"
        cancelText="Cancel"
        afterClose={() => form.resetFields()}
      >
        <Form form={form} layout="vertical" name="roleForm" labelAlign="left">
          <Form.Item
            label="数据分级"
            name="dataClassification"
            rules={[{ required: true }]}
          >
            <ZeissSelect options={dataClassificationss} />
          </Form.Item>
        </Form>
      </ZeissModal>
    </>
  );
};

export default Content;
