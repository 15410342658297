export default {
  'page.login.title': '登录',
  'page.login.other': '其他方式：',
  'page.login.account': '账号名称',
  'page.login.accountPlaceholder': '请输入您的账号',
  'page.login.passwordPlaceholder': '请输入您的密码',
  'page.login.pleaseLogin': '请登录',
  'page.login.password': '账号密码',
  'page.login.loginSuccess': '登录成功！',
  'page.login.logout': '退出登录',
  'page.login.changePassword': '更改密码',
  'page.login.forgetPassword': '忘记密码',
  'page.login.oldPassword': '旧密码',
  'page.login.newPassword': '新密码',
  'page.login.confrmPassword': '密码确认',
  'page.login.ok': '登录',
  'page.login.sso': 'SSO登录',
  'page.login.passwordDiff': '两个密码不一致',
  'page.login.passwordUpdateSuccess': '密码更新成功！',
  'page.login.compsLibrary': '组件库',
  'page.login.recoverPassword': '找回密码',
  'page.login.userAgreementInfo': '登录即表示您同意',
  'page.login.userAgreement': '隐私政策',
  'page.login.vertifyCode': '输入验证码',
  'login.form.password.errMsg': '密码不能为空'
};
