import { createAccount } from '@api/user';
import { emailReg } from '@constants/reg';
import { Form, Input, message } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import ZeissBreadcrumb from '@components/breadcrumb';
import ZeissButton from '@components/button';
import { useNavigate } from 'react-router-dom';

const CreateAccount: FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleCreateAccount = async () => {
    await createAccount({
      account: form.getFieldValue('email'),
      nickName: form.getFieldValue('username')
    });
    message.success('创建成功');
    form.setFieldsValue({ email: '', username: '' });
  };

  const handleCancelClick = () => {
    navigate({ pathname: `/account-list` });
  };

  return (
    <div className="create-account-wrapper wrapper">
      <ZeissBreadcrumb />
      <div className="page-subtitle">{t('account.createAccount')}</div>
      <div className="create-account-form-wrapper">
        <Form
          form={form}
          layout="vertical"
          wrapperCol={{ span: 24 }}
          className="create-account-form"
          onFinish={handleCreateAccount}
        >
          <div className="create-account-input-wrapper">
            <Form.Item
              className="create-account-form-item"
              name={'username'}
              label={t('account.username')}
              rules={[
                { required: true, message: '用户名不能为空' }
                // { pattern: /.{6,}/, message: '用户名应为6位及以上' }
              ]}
            >
              <Input
                className="custom-input"
                bordered={false}
                placeholder="请输入"
              />
            </Form.Item>
            <Form.Item
              className="create-account-form-item"
              name={'email'}
              label={t('pages.user.Email')}
              rules={[
                { required: true, message: '邮箱不能为空' },
                { pattern: emailReg, message: '请填写正确的邮箱格式' }
              ]}
            >
              <Input
                className="custom-input"
                bordered={false}
                placeholder="请输入"
              />
            </Form.Item>
          </div>
          <Form.Item className="create-account-buttons">
            <ZeissButton className="color-btn" onClick={handleCancelClick}>
              {t('btn.cancel')}
            </ZeissButton>
            <ZeissButton type="primary" htmlType="submit">
              {t('account.btn.create')}
            </ZeissButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateAccount;
