import Icon from '@ant-design/icons/lib/components/Icon';
import { Select, SelectProps } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

export const svgIcon = (
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.93945 0.32959L4.7197 3.54934L1.49995 0.32959L0.439453 1.39009L4.7197 5.67034L8.99995 1.39009L7.93945 0.32959Z"
      fill="#828D9E"
    />
  </svg>
);

const ZeissSelect = (props: SelectProps & { arrowEvent?: boolean }): any => {
  const { t } = useTranslation();
  const [suffixIcon, setSuffixIcon] = useState<ReactNode>(
    <Icon component={() => svgIcon} />
  );
  const { arrowEvent, ...rest } = props;
  const key: any = props.fieldNames?.value ?? 'value';
  const optsKey: any = props.fieldNames?.options ?? 'options';
  const AwesomeProps = {
    ...rest
  };
  useEffect(() => {
    if (props.suffixIcon) {
      setSuffixIcon(props.suffixIcon);
    }
  }, []);
  if (!props.mode) {
    AwesomeProps.value = (props as any)[optsKey]?.some(
      (t: any) => t[key] === props.value
    )
      ? props.value
      : null;
  }

  return (
    <Select
      className={`${props.className ?? ''} zeiss-reset-select`}
      placeholder={t('component.select.placeholder')}
      suffixIcon={suffixIcon}
      {...AwesomeProps}
    />
  );
};

ZeissSelect.OptGroup = Select.OptGroup;
ZeissSelect.Option = Select.Option;

export default ZeissSelect;
