export const TOKEN_KEY: string = 'iqsDmms_token';

export const USERINFO_KEY: string = 'iqsDmms_userInfo';

// export const BASE_NAME: string = '/iqs-dmms';
export const BASE_NAME: string = '';

export const IS_DEV: boolean = process.env.NODE_ENV === 'development';

export const IS_PROD: boolean = process.env.REACT_APP_ENV === 'production';

export const REACT_APP_ENV: string = process.env.REACT_APP_ENV ?? '';

export const BASE_ROUTER_URL: string = BASE_NAME;

export const DEFAULT_DATETIME_FORMAT: string = 'YYYY-MM-DD HH:mm:ss';

export const DEFAULT_DATE_FORMAT: string = 'YYYY-MM-DD';

export const BASE_URL: string = process.env.REACT_APP_BASE_URL ?? '';

export const SOCKET_URL: string = process.env.REACT_APP_SOCKET_URL ?? '';

export const AZURE_SERVICE: string = process.env.REACT_APP_AZURE_SERVICE ?? '';

export const LOGIN_URL_DEV: string =
  'https://leads.dev.zeiss.com.cn/saas-ec-admin/login';
export const DEFAULT_ROUTER: string = '/contents';
