import Icon from '@ant-design/icons';
import { ReactComponent as PdfFileIcon } from '@/assets/image/file/pdf-file-icon.svg';
import { ReactComponent as AiFileIcon } from '@/assets/image/file/ai-file-icon.svg';
import { ReactComponent as InddFileIcon } from '@/assets/image/file/indd-file-icon.svg';
import { ReactComponent as PsFileIcon } from '@/assets/image/file/ps-file-icon.svg';
import { ReactComponent as WordFileIcon } from '@/assets/image/file/word-file-icon.svg';
import { ReactComponent as ZipFileIcon } from '@/assets/image/file/zip-file-icon.svg';
import { ReactComponent as DefaultFileIcon } from '@/assets/image/default-file-icon.svg';

const getFileIcon = (suffix: string) => {
  switch (suffix) {
    case 'pdf':
      return <Icon component={PdfFileIcon} className="file-icon" />;
    case 'ai':
      return <Icon component={AiFileIcon} className="file-icon" />;
    case 'ps':
      return <Icon component={PsFileIcon} className="file-icon" />;
    case 'indd':
      return <Icon component={InddFileIcon} className="file-icon" />;
    case 'word':
      return <Icon component={WordFileIcon} className="file-icon" />;
    case 'docx':
      return <Icon component={WordFileIcon} className="file-icon" />;
    case 'zip':
      return <Icon component={ZipFileIcon} className="file-icon" />;
    default:
      return <Icon component={DefaultFileIcon} className="file-icon" />;
  }
};

export default getFileIcon;
