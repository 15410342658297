import { Button, Modal, ModalProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

interface ZeissModalButtonProps
  extends Pick<
    ModalProps,
    | 'onCancel'
    | 'onOk'
    | 'okButtonProps'
    | 'cancelButtonProps'
    | 'okText'
    | 'cancelText'
  > {}

const ZeissModalButton = (props: ZeissModalButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className="zeiss-modal-foot-wrap">
      <Button
        ghost
        {...props.cancelButtonProps}
        className="zeiss-reset-button zeiss-modal-foot-button"
        onClick={props.onCancel}
      >
        {props.cancelText || t('btn.cancel')}
      </Button>
      <Button
        className="zeiss-reset-button zeiss-modal-foot-button"
        type="primary"
        {...props.okButtonProps}
        onClick={props.onOk}
      >
        {props.okText || t('btn.confirm')}
      </Button>
    </div>
  );
};

const ZeissModal = (props: ModalProps): ReactElement => {
  return (
    <Modal
      footer={<ZeissModalButton {...props} />}
      className="zeiss-reset-modal"
      width={props.width ?? 405}
      {...props}
    >
      {props.children}
    </Modal>
  );
};

export default ZeissModal;
