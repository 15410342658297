import { Button, ButtonProps } from 'antd';

const ZeissButton = (props: ButtonProps): any => {
  const { className, ...reset } = props;
  return (
    <Button className={`zeiss-reset-button ${className ?? ''}`} {...reset} />
  );
};

export default ZeissButton;
