const download = (filename: string, url: string) => {
  // let a: HTMLAnchorElement = document.createElement('a');
  // a.download = filename;
  // a.href = url.split('&comp')[0];
  // document.body.appendChild(a);
  // a.click(); // 触发a标签的click事件
  // document.body.removeChild(a);

  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = url.split('&comp')[0];
  document.body.appendChild(iframe);
  setTimeout(() => iframe.remove(), 2 * 60 * 1000);
};

export default download;
