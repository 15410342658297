import request from '@/utils/request';
import { BannerInfo, BannerResponse } from './type';

export const getBannersService = (data: {
  url?: string;
  title?: string;
}): Promise<BannerInfo[]> => {
  return request({
    url: '/carouselMap/list',
    method: 'post',
    data
  });
};
export const saveBannersService = (data: {
  maps: BannerInfo[];
}): Promise<any> => {
  return request({
    url: '/carouselMap/add',
    method: 'post',
    data
  });
};

export const uploadBannersService = (data: {
  formData: FormData;
}): Promise<any> => {
  return request({
    url: '/carouselMap/upload',
    method: 'post',
    data: data.formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
