import { routerListTS } from './types';
import CreateCategory from '@views/pages/category-management/create';
import CategoryAndFileDetail from '@views/pages/detail';
import ShareFile from '@views/pages/detail/components/share-file';
import EditCategory from '@views/pages/detail/components/edit-category';
import EditPermission from '@views/pages/detail/components/edit-permission';
import EditTags from '@views/pages/detail/components/edit-tags';
import UploadFile from '@views/pages/content-list/upload';
import PermissionManagement from '@views/pages/permission-management';
import AccountManagement from '@views/pages/account-management';
import CreateAccount from '@views/pages/account-management/create';
import BulkCreateAccount from '@views/pages/account-management/bulk-create';
import History from '@views/pages/history';
import CategoryManagement from '@views/pages/category-management';
import BannerConfig from '@views/pages/banner-config';
import Home from '@views/pages/home';
import { Navigate } from 'react-router-dom';
import Contents from '@views/pages/content-list';
import NewContent from '@views/pages/content-list/new';
import HotContent from '@views/pages/content-list/hot';
import ContentList from '@views/pages/content-list/content-list';
import SearchResult from '@views/pages/content-list/search-result';
import RoleTable from '@views/pages/permission-management/role-table';
import InsideUserTable from '@views/pages/permission-management/inside-user-table';
import OutsideUserTable from '@views/pages/permission-management/outside-user-table';

const authList: routerListTS[] = [
  {
    path: '/contents',
    name: 'contents',
    authKey: 'contentList',
    component: <Contents />,
    children: [
      {
        path: '/contents',
        name: 'contents',
        authKey: 'contentList',
        ignore: true,
        component: <Navigate to={'/contents/home'} />
      },
      {
        path: '/contents/home',
        name: 'home',
        authKey: 'contentList',
        ignore: true,
        component: <Home />
      },
      {
        path: '/contents/category/:categoryId',
        name: 'category',
        authKey: 'contentList',
        ignore: true,
        component: <ContentList />
      },
      {
        path: '/contents/new',
        name: 'category',
        authKey: 'contentList',
        ignore: true,
        component: <NewContent />
      },
      {
        path: '/contents/hot',
        name: 'category',
        authKey: 'contentList',
        ignore: true,
        component: <HotContent />
      },
      {
        path: '/contents/detail/:fileId',
        name: 'detail',
        authKey: 'contentList',
        ignore: true,
        component: <CategoryAndFileDetail />
      },
      {
        path: '/contents/upload',
        name: 'upload',
        authKey: 'contentList',
        ignore: true,
        component: <UploadFile />
      },
      {
        path: '/contents/search-result',
        name: 'search-result',
        component: <SearchResult />,
        ignore: true
      },
      {
        path: '/contents/share',
        name: 'share',
        component: <ShareFile />,
        ignore: true
      },
      {
        path: '/contents/edit-category',
        name: 'edit-category',
        component: <EditCategory />,
        ignore: true
      },
      {
        path: '/contents/edit-permission',
        name: 'edit-permission',
        component: <EditPermission />,
        ignore: true
      },
      {
        path: '/contents/edit-tags',
        name: 'edit-tags',
        component: <EditTags />,
        ignore: true
      }
    ]
  },
  {
    path: '/permission',
    name: 'permission',
    authKey: 'authManagement',
    component: <PermissionManagement />,
    children: [
      {
        path: '/permission',
        name: 'permission',
        ignore: true,
        authKey: 'authManagement',
        component: <Navigate to={'/permission/roles'} />
      },
      {
        path: '/permission/roles',
        name: 'roles',
        ignore: true,
        authKey: 'authManagement',
        component: <RoleTable />
      },
      {
        path: '/permission/inside',
        name: 'inside',
        ignore: true,
        authKey: 'authManagement',
        component: <InsideUserTable />
      },
      {
        path: '/permission/outside',
        name: 'outside',
        ignore: true,
        authKey: 'authManagement',
        component: <OutsideUserTable />
      }
    ]
  },
  {
    path: 'account-list',
    name: 'account-list',
    authKey: 'accountConfig',
    component: <AccountManagement />
  },
  {
    path: 'create-account',
    name: 'create-account',
    component: <CreateAccount />,
    ignore: true
  },
  {
    path: 'bulk-create',
    name: 'bulk-create',
    component: <BulkCreateAccount />,
    ignore: true
  },
  {
    path: 'history',
    name: 'history',
    authKey: 'operateHistory',
    component: <History />
  },
  {
    path: 'categories',
    name: 'categories',
    authKey: 'categoryManagement',
    component: <CategoryManagement />
  },
  {
    path: 'create-category',
    name: 'create-category',
    component: <CreateCategory />,
    ignore: true
  },
  {
    path: 'banner-config',
    name: 'banner-config',
    authKey: 'mapManagement',
    component: <BannerConfig />
  }
  // {
  //   path: 'upload-file',
  //   name: 'upload-file',
  //   component: <UploadFile />,
  //   ignore: true
  // },
  // {
  //   path: 'detail',
  //   name: 'detail',
  //   component: <CategoryAndFileDetail />,
  //   ignore: true
  // },
];
export default authList;
