import ZeissButton from '@components/button';
import { Button, Divider, Popconfirm, Table, TableProps, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import UserDetailHeader from './component/user-detail-header';
import RoleEdit from './role-edit';
import { deleteRole, queryRoleListPage } from '@api/role';
import { useImmer } from 'use-immer';
import { RoleItem } from '@api/role/type';

const protectedRole = [
  'default',
  'IQS_Management',
  'IQS_Mkt',
  'IQS_Sales',
  'IQS_Service',
  'Other_Zeiss_Employee',
  'dealer',
  'dealer',
  '软件_Dealer',
  'O系列_Dealer',
  'CAPTUM_Dealer',
  '显微镜_Dealer',
  'HOM_Dealer',
  'GOM_Dealer',
  '三坐标_Dealer',
  'CT_dealer'
];

const bannedActionRole = ['default', 'IQS_Mkt'];

const RoleTable: FC = () => {
  const [editRole, setEditRole] = useState<
    { id: string; adminRoleId: string; roleKey?: string } | undefined
  >(undefined);
  const [roleList, setRoleList] = useImmer<RoleItem[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState({
    sizeCanChange: true,
    total: 0,
    pageSize: 10,
    current: 1,
    showQuickJumper: true,
    showSizeChanger: true
  });

  const columns = [
    {
      title: '角色名称',
      dataIndex: 'roleName',
      key: 'roleName'
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: true
    },
    {
      title: '创建人',
      dataIndex: 'createUser',
      key: 'createUser'
    },
    {
      title: '用户数量',
      dataIndex: 'userNum',
      key: 'userNum'
    },
    {
      title: '角色描述',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: unknown, record: RoleItem) => (
        <>
          {!protectedRole.find((role) => role === record.roleKey) && (
            <Popconfirm
              title={'提示'}
              description={'是否确定删除此角色'}
              onConfirm={() => handleDeleteRole(record.id, record.adminRoleId)}
              okText="确认"
              cancelText="取消"
            >
              <Button type="link" size="small" danger>
                删除
              </Button>
            </Popconfirm>
          )}
          <Divider type="vertical" />
          {!bannedActionRole.find((role) => role === record.roleKey) && (
            <Button
              type="link"
              size="small"
              onClick={() => handleEditRole(record)}
            >
              编辑
            </Button>
          )}
        </>
      )
    }
  ];

  const handleDeleteRole = async (id?: number, adminRoleId?: number) => {
    if (!id || !adminRoleId) {
      message.error('出现错误');
      return;
    }
    try {
      await deleteRole({ id, adminRoleId });
      message.success('删除成功');
      await fetchRoleList(searchValue);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditRole = (record: RoleItem) => {
    if (!record.id || !record.adminRoleId) return;
    setEditRole({
      id: `${record.id}`,
      adminRoleId: `${record.adminRoleId}`,
      roleKey: `${record.roleKey}`
    });
  };

  const handleTableChange: TableProps<RoleItem>['onChange'] = async (
    paginationConfig,
    filter,
    sorter
  ) => {
    const createTimeSort =
      sorter instanceof Array
        ? sorter.find((item) => item.columnKey === 'createTime')
        : sorter;
    setPagination({
      ...pagination,
      pageSize: paginationConfig.pageSize || 10,
      current: paginationConfig.current || 1
    });
    await getRoleList(
      paginationConfig.current,
      paginationConfig.pageSize,
      searchValue,
      createTimeSort?.order
        ? createTimeSort.order === 'ascend'
          ? 1
          : 2
        : undefined
    );
  };

  const handleClickAddRole = () => {
    setEditRole({ id: 'new', adminRoleId: 'new' });
  };

  const handleSearch = async (searchValue: string) => {
    setSearchValue(searchValue);
    await fetchRoleList(searchValue);
  };

  const getRoleList = async (
    pageNumber?: number,
    pageSize?: number,
    searchName?: string,
    createTimeValue?: 1 | 2
  ) => {
    const res = await queryRoleListPage({
      pageNumber,
      pageSize,
      searchName,
      createTimeValue
    });
    setRoleList(res.records || []);
    setPagination((draft) => ({
      ...draft,
      current: res.current || 1,
      total: res.total,
      pageSize: res.size || 10
    }));
  };

  const fetchRoleList = async (searchValue?: string) => {
    await getRoleList(1, pagination.pageSize, searchValue);
  };

  useEffect(() => {
    getRoleList();
  }, []);

  const buttons = (
    <>
      <ZeissButton type="primary" onClick={handleClickAddRole}>
        新增角色
      </ZeissButton>
    </>
  );
  return (
    <>
      {editRole ? (
        <RoleEdit
          role={editRole}
          setEditRole={setEditRole}
          fetchRoleList={fetchRoleList}
        />
      ) : (
        <div className="roles-management">
          <div className="page-title">角色管理</div>
          <UserDetailHeader
            text="请输入角色名称、创建人和角色描述搜索"
            buttons={buttons}
            handleSearch={handleSearch}
          />
          <Table
            dataSource={roleList}
            columns={columns}
            rowKey={'id'}
            pagination={{
              ...pagination,
              showTotal: () => `Total ${pagination.total} items`
            }}
            onChange={handleTableChange}
          />
        </div>
      )}
    </>
  );
};

export default RoleTable;
