import { Loading } from '@components/loading/loading';
import blobFileClient, {
  TransferProgressEventProps
} from '@utils/azure-storage-blob';
import type { MenuProps, UploadProps } from 'antd';
import {
  Button,
  Checkbox,
  Dropdown,
  Progress,
  Space,
  Spin,
  Upload,
  message
} from 'antd';
import type {
  ItemRender,
  RcFile,
  UploadFile as UploadFileProps
} from 'antd/es/upload/interface';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import './index.scss';
import { fileNameCheck } from '@api/home';
import { FileListItem } from '@views/pages/content-list/upload';
import compressedImage from '@utils/image';
import uploadVideo from '@utils/video';
import { Updater, useImmer } from 'use-immer';
import ZeissButton from '@components/button';
import { UploadFile } from 'antd/lib';
import Icon from '@ant-design/icons';
import { ReactComponent as EmptyFileIcon } from '@/assets/image/empty-file.svg';
import { ReactComponent as FileDeleteIcon } from '@/assets/image/file-delete.svg';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import formatFileSize from '@utils/file-size';
import { useRecoilValue } from 'recoil';
import { dataClassifications } from '@store';
import ClassTypeTag from '@components/classTypeTag';

const { Dragger } = Upload;

export const getFileName = (url: string) => {
  const parts = url?.split('/');
  const filename = parts[parts?.length - 1]?.match(/[^?]+/)?.[0] || '';
  return filename;
};

const getOriginFile = (url: string) => {
  return url.split('&comp')[0];
};

export const defaultFileListFn = (
  src: string,
  name?: string
): UploadFileProps & { dataClassification: number } => {
  return {
    uid: nanoid(),
    name: name || nanoid(),
    status: 'done',
    url: src,
    dataClassification: 0
  };
};

interface ZeissUploadProps {
  fileList?: Array<UploadFileProps & { dataClassification: number }>;
  onChange?: Updater<Array<UploadFileProps & { dataClassification: number }>>;
  accept?: string;
  disabled?: boolean;
  buttonName?: string;
  maxCount?: number;
  marginTop?: number;
  children?: ReactNode;
  maxFileSize?: number;
  showUploadList?: boolean;
  afterUpload?: (uid: string, url: string) => void;
}

const DraggerOrUpload = (props: any) => {
  switch (props.modeType) {
    case 'upload':
      return <Upload {...props}>{props.children}</Upload>;
    case 'dragger':
      return <Dragger {...props}>{props.children}</Dragger>;
    default:
      return <div />;
  }
};

const ZeissUpload: FC<ZeissUploadProps> = ({
  fileList = [],
  onChange,
  accept = '*',
  disabled = false,
  maxCount,
  showUploadList = true,
  maxFileSize,
  afterUpload,
  ...props
}) => {
  const [selectedList, setSelectedList] = useImmer<UploadFile[]>([]);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const dataClassificationss = useRecoilValue(dataClassifications);
  const items: MenuProps['items'] = dataClassificationss.map(
    (v: { code: number; valueMsg: string }) => ({
      key: v.code.toString(),
      label: v.valueMsg
    })
  );
  const upLoadParams: UploadProps = {
    customRequest: async (options: UploadRequestOption) => {
      const { file, onSuccess, onError, onProgress } = options;
      !showUploadList && Loading.show();
      blobFileClient
        .uploadFileToBlob(file, {
          onProgress: (progress: TransferProgressEventProps) => {
            const files = file as File;
            // 更新上传进度
            if (files?.size) {
              const percent = Math.round(
                (progress.loadedBytes / files.size) * 100
              );
              onProgress?.({ percent });
            }
          }
        })
        .then((res) => {
          const {
            _response: {
              request: { url: sourceUrl }
            }
          } = res;
          if ((file as RcFile).type.includes('image')) {
            compressedImage(
              file,
              (dataUrl) => {
                blobFileClient.uploadFileToBlob(dataUrl).then((res) => {
                  const {
                    _response: {
                      request: { url: iconUrl }
                    }
                  } = res;
                  afterUpload?.((file as RcFile).uid, iconUrl);
                });
              },
              (defaultUrl) => {
                afterUpload?.((file as RcFile).uid, defaultUrl);
              }
            );
          } else if ((file as File).type.includes('video')) {
            uploadVideo(file, (dataUrl) => {
              blobFileClient.uploadFileToBlob(dataUrl).then((res) => {
                const {
                  _response: {
                    request: { url: iconUrl }
                  }
                } = res;
                afterUpload?.((file as RcFile).uid, iconUrl);
              });
            });
          }
          onSuccess?.(sourceUrl);
        })
        .catch((err) => {
          onError?.(err);
        })
        .finally(() => {
          !showUploadList && Loading.hide();
        });
    },
    beforeUpload: async (file) => {
      const fileListCheckResult = !!(fileList as any[])?.find(
        (item) =>
          (item as UploadFileProps & { dataClassification: number })
            .fileName === (file as File).name
      );
      if (fileListCheckResult) {
        message.error('文件名称重复，请修改文件名称再次上传');
        return Upload.LIST_IGNORE;
      }
      const fileCheckResult = await fileNameCheck({
        fileName: (file as File).name || ''
      });
      if (fileCheckResult) {
        message.error('文件名称重复，请修改文件名称再次上传');
        return Upload.LIST_IGNORE;
      }
    },
    progress: {
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`
    }
  };

  const handleChange: UploadProps['onChange'] = async ({
    file,
    fileList: newFileList
  }) => {
    const { status, response } = file;
    if (status === 'removed') {
      try {
        const data = await blobFileClient.deleteBlobIfItExists(response);
        if (data.succeeded) {
          const fileListTemp = newFileList.map((item) => {
            return item;
          });
          onChange?.(
            fileListTemp as Array<
              UploadFileProps & { dataClassification: number }
            >
          );
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      const fileListTemp = newFileList.map((item) => {
        return item;
      });
      onChange?.(
        fileListTemp as Array<UploadFileProps & { dataClassification: number }>
      );
    }
  };

  const handleFileItemChange = (
    e: CheckboxChangeEvent,
    selected: UploadFile
  ) => {
    if (e.target.checked) {
      setSelectedList((draft) => draft.concat([selected]));
    } else {
      setSelectedList((draft) =>
        draft.filter((item) => item.uid !== selected.uid)
      );
    }
  };

  const handleAllItemChange = (
    e: CheckboxChangeEvent,
    fileList: UploadFile[]
  ) => {
    if (e.target.checked) {
      setSelectedList(fileList);
    } else {
      setSelectedList([]);
    }
  };

  const handleDelete = async (file: UploadFile) => {
    try {
      setSelectedList((draft) => draft.filter((item) => item.uid !== file.uid));
      onChange?.(fileList.filter((item) => item.uid !== file.uid));
      await blobFileClient.deleteBlobIfItExists(file.response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAllDelete = async () => {
    const removeList = selectedList.map((selected) =>
      blobFileClient.deleteBlobIfItExists(selected.response)
    );
    try {
      setListLoading(true);
      await Promise.allSettled(removeList);
      const temp: Array<UploadFileProps & { dataClassification: number }> = [];
      fileList.forEach((file) => {
        if (
          !selectedList.find(
            (selected) =>
              selected.uid ===
              (file as UploadFileProps & { dataClassification: number }).uid
          )
        ) {
          temp.push(file);
        }
      });
      onChange?.(temp);
      setSelectedList([]);
    } catch (error) {
      console.error(error);
    } finally {
      setListLoading(false);
    }
  };

  // 数据分级select
  const handleSelectChange = (item: any, files: any) => {
    const temp: Array<UploadFileProps & { dataClassification: number }> = [];
    fileList.forEach((file) => {
      if (file.uid === files.uid) {
        temp.push({ ...file, dataClassification: item.key });
      } else {
        temp.push(file);
      }
    });
    onChange?.(temp);
  };

  const renderFileList: any = (
    originNode: ReactElement,
    file: UploadFile & { dataClassification: number },
    fileList: UploadFile[],
    actions: {
      download: () => void;
      preview: () => void;
      remove: () => void;
    }
  ) => {
    const findIndex = fileList.findIndex((item) => item.uid === file.uid);
    return (
      <Spin spinning={listLoading}>
        <div className="upload-list">
          {findIndex === 0 && (
            <div className="file-list-header">
              <Checkbox
                checked={selectedList.length === fileList.length}
                onChange={(e) => handleAllItemChange(e, fileList)}
              >
                全选
              </Checkbox>
              <ZeissButton danger onClick={handleAllDelete}>
                批量删除
              </ZeissButton>
            </div>
          )}
          {findIndex === 0 && (
            <ul className="fileListTableHead">
              <li></li>
              <li>名称</li>
              <li>数据分级</li>
              <li>操作</li>
            </ul>
          )}
          <div className="list">
            <ul key={file.uid} className="file-list-item">
              <li>
                <Checkbox
                  checked={!!selectedList.find((item) => item.uid === file.uid)}
                  onChange={(e) => handleFileItemChange(e, file)}
                />
              </li>
              <li>
                <p className="title">{file.name}</p>
                <p className="desc">
                  {formatFileSize(file.originFileObj?.size || 0)}
                </p>
              </li>
              <li>
                <div className="dropDown">
                  <Dropdown
                    menu={{
                      items,
                      selectable: true,
                      defaultSelectedKeys: [file.dataClassification as any],
                      onSelect: (e) => handleSelectChange(e, file)
                    }}
                    trigger={['click']}
                  >
                    <div>
                      <ClassTypeTag value={file.dataClassification} />
                    </div>
                  </Dropdown>
                </div>
              </li>
              <li>
                <Icon
                  component={FileDeleteIcon}
                  onClick={() => handleDelete(file)}
                />
              </li>
            </ul>
            <div className="progress">
              <Progress percent={file.percent} size={['100%', 2]} />
            </div>
          </div>
          {file.error && (
            <div className="file-list-item-error">{file.error.message}</div>
          )}
        </div>
      </Spin>
    );
  };

  return (
    <>
      <DraggerOrUpload
        {...upLoadParams}
        fileList={fileList}
        name="file"
        showUploadList={showUploadList}
        onChange={handleChange}
        accept={accept}
        disabled={disabled}
        maxCount={maxCount}
        className="zeiss-upload-wrapper"
        modeType="dragger"
        multiple
        itemRender={renderFileList}
      >
        {props.children}
      </DraggerOrUpload>
    </>
  );
};

export default ZeissUpload;
