import { FC, useState } from 'react';

import './index.scss';
import { Cascader, Select } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ZeissButton from '@components/button';
import { Updater, useImmer } from 'use-immer';
import { uuid } from '@utils/tool';
import { CategoryListItem } from '@api/category/type';

export interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

interface CascaderListProps {
  options: CategoryListItem[];
  cascaderList: CascaderItem[];
  setCascaderList: Updater<CascaderItem[]>;
}

export interface CascaderItem {
  key: string;
  value: (string | number)[];
}

const CascaderList: FC<CascaderListProps> = ({
  options,
  cascaderList,
  setCascaderList
}) => {
  const handleCascaderChange = (
    item: CascaderItem,
    value: (string | number)[]
  ) => {
    setCascaderList((draft) => {
      return draft.map((cascader) =>
        cascader.key === item.key ? { ...cascader, value } : cascader
      );
    });
  };

  const handleAddFirstClassCategory = () => {
    setCascaderList((draft) => [...draft, { key: uuid() }]);
  };

  const handleDeleteCascader = (item: CascaderItem) => {
    setCascaderList((draft) => draft.filter((i) => i.key !== item.key));
  };

  return (
    <div>
      <div className="cascader-wrapper">
        {cascaderList.map((item) => (
          <div key={`cascader-${item.key}`} className="cascader-item">
            <Cascader
              fieldNames={{ value: 'id', label: 'name' }}
              className="cascader"
              options={options}
              onChange={(value) => handleCascaderChange(item, value)}
              placeholder="分类选择"
              bordered={false}
              changeOnSelect={true}
              value={item.value}
            />
            <DeleteOutlined
              style={{ color: 'red', cursor: 'pointer', marginLeft: '24px' }}
              onClick={() => handleDeleteCascader(item)}
            />
          </div>
        ))}
      </div>
      <ZeissButton
        icon={<PlusOutlined />}
        onClick={handleAddFirstClassCategory}
      >
        选择一级分类
      </ZeissButton>
    </div>
  );
};

export default CascaderList;
