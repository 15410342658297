import { TransferProgressEvent } from '@azure/core-http';
import {
  BlobDeleteIfExistsResponse,
  BlobDeleteOptions,
  BlobServiceClient,
  BlockBlobUploadStreamOptions
} from '@azure/storage-blob';
import dayjs from 'dayjs';
import { AZURE_SERVICE } from '@constants';

export interface UploadOptionsProps extends BlockBlobUploadStreamOptions {}

export interface TransferProgressEventProps extends TransferProgressEvent {}

export interface SasConfig {
  sasToken: string;
  containerName: string;
  accountUrl: string;
  expirationTime: number;
}
const setFileName = (file: File) => {
  const date = new Date();
  const type = file.name.split('.')[1]; // file format : mp4/png/jpeg
  return `${type}/iqs-dmms-bff-service/${dayjs(date).format('YYYYMMDD')}/${
    file.name
  }`;
  // return file.name;
  //ex: mp4/20230423/321321323231313.mp4
};
const uploadFileToBlob = async (
  file: any,
  uploadOptions?: BlockBlobUploadStreamOptions
) => {
  if (!file) {
    throw new Error('file error');
  }
  const blobService = new BlobServiceClient(AZURE_SERVICE);
  // create blobClient for container
  const containerClient = blobService.getContainerClient('');
  const fileName = setFileName(file);
  // set mimetype as determined from browser with file upload control
  const blobClient = containerClient.getBlockBlobClient(fileName);
  const options = {
    blobHTTPHeaders: {
      // blobContentType: file.type
      blobContentType: 'application/octet-stream',
      blobContentDisposition: 'attachment'
    },
    blockSize: 4 * 1024 * 1024
    // maxSingleShotSize: 80 * 1024 * 1024,
    // concurrency: 20
  };
  // upload file
  return blobClient.uploadData(file, {
    ...options,
    ...uploadOptions
  });
};

async function deleteBlobIfItExists(
  fileUrl?: string
): Promise<BlobDeleteIfExistsResponse> {
  if (!fileUrl) {
    throw new Error('file error');
  }
  const blobName = fileUrl
    .split('?')[0]
    .split('//')[1]
    .split('/')
    .slice(2, 6)
    .join('/');
  const blobService = new BlobServiceClient(AZURE_SERVICE);
  // create blobClient for container
  const containerClient = blobService.getContainerClient('');
  // Create blob client from container client
  const blockBlobClient = await containerClient.getBlockBlobClient(
    decodeURI(blobName)
  );
  // include: Delete the base blob and all of its snapshots.
  // only: Delete only the blob's snapshots and not the blob itself.
  const options: BlobDeleteOptions = {
    deleteSnapshots: 'include' // or 'only'
  };
  const blobDeleteIfExistsResponse: BlobDeleteIfExistsResponse =
    await blockBlobClient.deleteIfExists(options);

  if (!blobDeleteIfExistsResponse.errorCode) {
    console.log(`deleted blob ${blobName}`);
  }
  return blobDeleteIfExistsResponse;
}

const blobFileClient = {
  uploadFileToBlob,
  deleteBlobIfItExists
};
export default blobFileClient;
