import { CategoryListItem } from '@api/category/type';
import {
  getUploadCategoryList,
  queryFileCategory,
  updateFileCategory
} from '@api/home';
import ZeissButton from '@components/button';
import CascaderList, { CascaderItem } from '@components/cascader';
import { message } from 'antd';
import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import { uuid } from '@utils/tool';
import { useRecoilState } from 'recoil';
import { selectedFile } from '@store';

interface EditedFile {
  fileId: string;
  fileName: string;
  fileUrl: string;
}

const EditCategory: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useImmer<CategoryListItem[]>([]);
  const [cascaderList, setCascaderList] = useImmer<CascaderItem[]>([]);
  const [fileList, setFileList] = useImmer<EditedFile[]>([]);
  const [selectedFileList, setSelectedFileList] =
    useRecoilState<CategoryListItem[]>(selectedFile);

  const getCategories = async () => {
    try {
      const { children } = await getUploadCategoryList({
        rootId: '0',
        path: '/'
      });
      setCategoryList(() => children);
    } catch (error) {}
  };

  const getFileCategory = async (id: string) => {
    const { path } = await queryFileCategory({ fileId: id });
    setCascaderList(
      path.map((item) => ({
        key: uuid(),
        value: item.split('/').filter((i) => i)
      }))
    );
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleEditCategory = async () => {
    for (let i = 0; i < cascaderList.length; i++) {
      if (cascaderList[i].value.length < 2) {
        message.error('不可在一级分类下上传文件，请检查分类选择');
        return;
      }
    }
    try {
      await updateFileCategory({
        fileIds: fileList.map((file) => file.fileId),
        destinationCategoryIds: _.flatten(
          cascaderList.map(
            (cascader) => cascader.value[cascader.value.length - 1]
          )
        )
      });
      message.success('编辑分类成功');
      setSelectedFileList([]);
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    const fileList = location.state?.fileList;
    if (fileList?.length === 1) {
      getFileCategory(fileList[0].fileId);
    }
    setFileList(fileList || []);
  }, [location.state?.fileList]);

  return (
    <div className="wrapper">
      <div className="page-subtitle">选择分类</div>
      <CascaderList
        options={categoryList}
        cascaderList={cascaderList}
        setCascaderList={setCascaderList}
      />
      <div style={{ marginTop: 40 }}>
        <ZeissButton className="color-btn" onClick={handleCancel}>
          取消
        </ZeissButton>
        <ZeissButton
          type="primary"
          style={{ marginLeft: 8 }}
          onClick={handleEditCategory}
        >
          确定
        </ZeissButton>
      </div>
    </div>
  );
};

export default EditCategory;
