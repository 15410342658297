// import httpServer from '@utils/request'
import myAxios from '@/utils/request';
import {
  listResponseInterFace,
  paramsInterface,
  LogoutProps,
  password,
  AccountParams,
  UserListParams,
  ErrorAccountResult
} from './type';
import request from '@/utils/request';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import { getStorage } from '@utils/localStorageService';
import { TOKEN_KEY } from '@constants';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getUserList = (
  data: UserListParams
): Promise<{ users: listResponseInterFace }> => {
  return request({
    url: '/system/user/queryUserList',
    method: 'post',
    data
  });
};
const getLogout = (data: LogoutProps): Promise<any> => {
  return myAxios({
    url: '/system/login/signout',
    method: 'post',
    data
  });
};

const updatePassword = (data: password): Promise<listResponseInterFace> => {
  return myAxios({
    url: 'system/user/updatePassword',
    method: 'post',
    data
  });
};

const createAccount = (data: AccountParams) => {
  return request({
    url: '/system/user/addUser',
    method: 'post',
    data
  });
};

const deleteAccount = (data: { userIds: string[]; deleteStatus: 0 | 1 }) => {
  return request({
    url: '/system/user/delUser',
    method: 'post',
    data
  });
};

// const uploadFile = process.env.REACT_APP_BASE_URL + '/system/user/importUsers';

const uploadFile = (data: {
  formData: FormData;
}): Promise<{ data: { data: ErrorAccountResult[] } }> => {
  return axios.post(
    process.env.REACT_APP_BASE_URL + '/system/user/importUsers',
    data.formData,
    {
      headers: {
        'x-token': getStorage(TOKEN_KEY) || ''
      }
    }
  );
};

export {
  getUserList,
  getLogout,
  updatePassword,
  createAccount,
  deleteAccount,
  uploadFile
};
