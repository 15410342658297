import request from '@/utils/request';
import {
  CategoryListItem,
  CategoryListResult,
  CategoryResult,
  CreateCategoryParams,
  QueryCategoryListParams,
  UpdateParams,
  queryCategoriesParams
} from './type';

export const createCategory = (data: CreateCategoryParams): Promise<{}> => {
  return request({
    url: '/category/addTree',
    method: 'post',
    data
  });
};

export const getCategories = (
  data: queryCategoriesParams
): Promise<CategoryResult[]> => {
  return request({
    url: '/category/list',
    method: 'post',
    data
  });
};

export const getCategoryList = (
  data: QueryCategoryListParams
): Promise<CategoryListResult> => {
  return request({
    url: '/category/page',
    method: 'post',
    data
  });
};

export const getCategoryDetail = (data: {
  id: string;
}): Promise<{ children: CategoryListItem[] }> => {
  return request({
    url: '/category/oneTree',
    method: 'post',
    data
  });
};

export const deleteCategory = (data: {
  deletes: { id: string; parentId: string }[];
}): Promise<{}> => {
  return request({
    url: '/category/batchDelete',
    method: 'post',
    data
  });
};

export const updateCategory = (data: UpdateParams): Promise<{}> => {
  return request({
    url: '/category/batchEdit',
    method: 'post',
    data
  });
};

export const uploadFile = (data: { formData: FormData }): Promise<any> => {
  return request({
    url: '/content/upload',
    method: 'post',
    data: data.formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
