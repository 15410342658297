import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as FileIcon } from '@/assets/image/file-icon.svg';
import { Avatar, Col, Form, Row, Select, message } from 'antd';
import Icon, { CloseOutlined } from '@ant-design/icons';
import { useImmer } from 'use-immer';
import '../index.scss';
import UserSelect, {
  UserValue
} from '@views/pages/content-list/components/user-select';
import { getAuthList, shareFile } from '@api/home';
import { UserResult } from '@api/home/type';
import _ from 'lodash';
import ZeissButton from '@components/button';
import { useRecoilState } from 'recoil';
import { CategoryListItem } from '@api/category/type';
import { selectedFile } from '@store';

interface SharedFile {
  fileId: string;
  fileName: string;
  fileUrl: string;
}

const ShareFile: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFileList, setSelectedFileList] = useImmer<SharedFile[]>([]);
  const [users, setUsers] = useState<UserResult[]>([]);

  const [selectedUsers, setSelectedUsers] = useImmer<UserValue[]>([]);
  const [globalFileList, setGlobalFileList] =
    useRecoilState<CategoryListItem[]>(selectedFile);

  const handleDeleteFile = (file: SharedFile) => {
    setSelectedFileList((draft) =>
      draft.filter((item) => item.fileId !== file.fileId)
    );
  };

  const handleDeleteUser = (user: UserValue) => {
    setSelectedUsers((draft) =>
      draft.filter((item) => item.value !== user.value)
    );
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleShare = async () => {
    let emailErr = false;
    selectedUsers.forEach((user) => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailPattern.test(user.value)) {
        emailErr = true;
        return;
      }
    });
    if (emailErr) {
      message.error('存在分享用户不符合邮箱规则，请重新选择');
      return;
    }
    try {
      await shareFile({
        files: selectedFileList,
        emails: selectedUsers.map((user) => user.value)
      });
      setGlobalFileList([]);
      message.success('分享成功');
    } catch (error) {
      console.error(error);
    }
  };

  const getUsers = async () => {
    try {
      const data = await getAuthList();
      setUsers(data.users);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    setSelectedFileList(location.state?.fileList || []);
  }, [location.state?.fileList]);

  return (
    <div className="wrapper share-file">
      <div className="page-subtitle">分享文件</div>
      <div className="file-list">
        <Row gutter={20}>
          {selectedFileList.map((file) => (
            <Col span={8} key={file.fileId}>
              <div className="file-item">
                <div>
                  <Icon component={FileIcon} style={{ marginRight: 8 }} />
                  {file.fileName}
                </div>
                <CloseOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDeleteFile(file)}
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className="page-subtitle">分享用户</div>
      <div className="select-wrapper">
        <Select
          className="user-select"
          labelInValue
          mode="tags"
          options={users}
          bordered={false}
          onChange={(newValue: UserValue[]) => {
            setSelectedUsers(newValue);
          }}
          value={_.cloneDeep(selectedUsers)}
          placeholder="请输入"
          fieldNames={{ value: 'email', label: 'username' }}
          showSearch
          optionFilterProp="username"
        />
        <div className="selected-user-wrapper">
          {selectedUsers.map(
            (user) =>
              user.value !== 'all' && (
                <div className="selected-user" key={`select-${user.value}`}>
                  <div className="user-info">
                    <Avatar style={{ backgroundColor: '#0072EF' }}>
                      {user.label?.split('')[0] || '未知'}
                    </Avatar>
                    <span className="user-name">
                      {user.label || user.value}
                    </span>
                  </div>
                  <CloseOutlined onClick={() => handleDeleteUser(user)} />
                </div>
              )
          )}
        </div>
      </div>
      <div>
        <ZeissButton className="color-btn" onClick={handleCancel}>
          取消
        </ZeissButton>
        <ZeissButton
          type="primary"
          style={{ marginLeft: 8 }}
          onClick={handleShare}
        >
          确定
        </ZeissButton>
      </div>
    </div>
  );
};

export default ShareFile;
