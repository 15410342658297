import myAxios from '@/utils/request';
import {
  AddLimitProps,
  AddRoleProps,
  AddUserProps,
  DelLimitProps,
  DelRoleProps,
  DelUserProps,
  DeviceProps,
  EditLimitProps,
  EditRoleProps,
  EditUserProps,
  LimitListProps,
  ResponseProps,
  RoleListProps,
  SetUserRoleProps,
  UserListProps,
  serRoleLimitProps
} from './type';

const userList = (data: UserListProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/user/queryUserList', method: 'post', data });
};
const userList1 = (data: { role: string }): Promise<ResponseProps> => {
  return myAxios({ url: '/user/list?loading=false', method: 'post', data });
};
const addUser = (data: AddUserProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/user/addUser', method: 'post', data });
};
const editUser = (data: EditUserProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/user/updateUser', method: 'post', data });
};
const delUser = (data: DelUserProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/user/delUser', method: 'post', data });
};
const resetPassword = (data: DelUserProps): Promise<ResponseProps> => {
  return myAxios({ url: '/user/resetPassword', method: 'post', data });
};
const setUserRole = (data: SetUserRoleProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/user/addUserRole', method: 'post', data });
};
const roleList = (data: RoleListProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/role/queryRoleList', method: 'post', data });
};
const addRole = (data: AddRoleProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/role/addRole', method: 'post', data });
};
const editRole = (data: EditRoleProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/role/updateRole', method: 'post', data });
};
const delRole = (data: DelRoleProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/role/delRole', method: 'post', data });
};
const setRoleLimit = (data: serRoleLimitProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/role/addRoleAuth', method: 'post', data });
};
const getDataClassifications = (): Promise<
  Array<{ code: number; valueMsg: string }>
> => {
  return myAxios({
    url: '/content/getDataClassifications',
    method: 'post'
  });
};
const userLimitList = (): Promise<ResponseProps> => {
  return myAxios({
    // url: '/system/user/getUserResourceList',
    url: '/system/user/getUserResourceByAuthZ',
    method: 'post'
  });
};
const LimitTreeList = (): Promise<ResponseProps> => {
  return myAxios({
    url: '/system/resource/queryResourceList',
    method: 'post'
  });
};
const addLimit = (data: AddLimitProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/resource/addResource', method: 'post', data });
};
const editLimit = (data: EditLimitProps): Promise<ResponseProps> => {
  return myAxios({
    url: '/system/resource/updateResource',
    method: 'post',
    data
  });
};
const delLimit = (data: DelLimitProps): Promise<ResponseProps> => {
  return myAxios({ url: '/system/resource/delResource', method: 'post', data });
};
const deviceList = (data: DeviceProps): Promise<ResponseProps> => {
  return myAxios({ url: '/device/type/list', method: 'post', data });
};
const zoneLists = (): Promise<any> => {
  return myAxios({ url: '/system/user/getUserDetail', method: 'post' });
};

export {
  userList,
  addUser,
  userList1,
  editUser,
  delUser,
  roleList,
  setUserRole,
  addRole,
  editRole,
  delRole,
  setRoleLimit,
  userLimitList,
  LimitTreeList,
  addLimit,
  editLimit,
  deviceList,
  delLimit,
  resetPassword,
  zoneLists,
  getDataClassifications
};
