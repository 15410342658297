import { CategoryListItem } from '@api/category/type';
import { Card, Checkbox, Image } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FC, useEffect } from 'react';
import { ReactComponent as FolderIcon } from '@/assets/image/file/folder-icon.svg';

import Icon from '@ant-design/icons';
import './index.scss';
import { useImmer } from 'use-immer';
import getFileIcon from '@utils/getFileIcon';
import getFilePreview from '@utils/getFilePreview';
import { selectedFile } from '@store';
import { useRecoilState } from 'recoil';

interface ListViewProps {
  list: CategoryListItem[];
  multiple: boolean;
}

const ContentView: FC<ListViewProps> = ({ list, multiple }) => {
  const [showList, setShowList] = useImmer<CategoryListItem[]>([]);
  const [selectedFileList, setSelectedFileList] =
    useRecoilState<CategoryListItem[]>(selectedFile);

  const handleCheckboxChange = (file: CategoryListItem) => {
    if (
      selectedFileList.findIndex(
        (item) => item.item.fileId === file.item.fileId
      ) > -1
    ) {
      setSelectedFileList(
        selectedFileList.filter((item) => item.item.fileId !== file.item.fileId)
      );
    } else {
      setSelectedFileList([...selectedFileList, file]);
    }
  };

  const handleCheckedAll = (e: CheckboxChangeEvent) => {
    setSelectedFileList(
      e.target.checked ? list.filter((item) => item.item.fileId) : []
    );
  };

  const renderPreviewIcon = (file: CategoryListItem) => {
    if (file.item.icon) {
      return (
        <Image
          style={{ maxHeight: 85, maxWidth: 85 }}
          src={file.item.icon}
          // preview={{ src: file.item.fileUrl }}
          preview={{
            imageRender: () => {
              return getFilePreview(file.item.suffix, file.item.fileUrl);
            }
          }}
        />
      );
    }
    if (!file.item.fileId) {
      return <Icon component={FolderIcon} className="file-icon" />;
    }
    if (file.item.suffix) {
      return getFileIcon(file.item.suffix);
    }
    return <></>;
  };

  const handleDoubleClick = (file: CategoryListItem) => {
    if (!file.children?.length) return;
    setShowList(file.children);
  };

  useEffect(() => {
    setShowList(list);
  }, [list]);

  return (
    <div className="view-wrapper">
      {multiple && (
        <div className="view-header">
          <Checkbox
            indeterminate={
              selectedFileList.length > 0 &&
              selectedFileList.length < showList.length
            }
            onChange={handleCheckedAll}
            checked={showList.length === selectedFileList.length}
          >
            全选
          </Checkbox>
        </div>
      )}
      <div className="view-content">
        {showList.map((file) => (
          <Card
            className="file-card"
            key={file.id}
            onDoubleClick={() => handleDoubleClick(file)}
          >
            <div className="file-card-content">
              {multiple && file.item.fileUrl && (
                <Checkbox
                  onChange={() => handleCheckboxChange(file)}
                  checked={
                    selectedFileList.findIndex(
                      (item) => item.item.fileId === file.item.fileId
                    ) > -1
                  }
                />
              )}
            </div>
            <div className="image-wrapper">{renderPreviewIcon(file)}</div>
            <p
              className="card-title"
              style={{
                textAlign: 'center',
                wordBreak: 'break-all',
                maxWidth: 90
              }}
            >
              {file.name}
            </p>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ContentView;
