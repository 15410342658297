const formatFileSize = (size: number) => {
  if (size < 1024) {
    return size + 'B';
  } else if (size < 1024 * 1024) {
    let temp = size / 1024;
    temp = +temp.toFixed(2);
    return temp + 'KB';
  } else if (size < 1024 * 1024 * 1024) {
    let temp = size / (1024 * 1024);
    temp = +temp.toFixed(2);
    return temp + 'MB';
  } else {
    let temp = size / (1024 * 1024 * 1024);
    temp = +temp.toFixed(2);
    return temp + 'GB';
  }
};

export default formatFileSize;
