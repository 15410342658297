import { BannerInfo } from '@api/banner/type';
import { Carousel } from 'antd';
import './index.scss';
const contentStyle: React.CSSProperties = {
  width: '100%',
  height: 'auto',
  textAlign: 'center',
  display: 'inline-block'
};
interface SwapperProps {
  banners?: BannerInfo[];
}
const SwapperImg: React.FC<SwapperProps> = (props: SwapperProps) => {
  const { banners } = props;
  return (
    <Carousel autoplay autoplaySpeed={5000} className="swapper-img-container">
      {banners?.map((item, index) => (
        <a href={item.targetUrl} target="_blank" key={index}>
          <img
            style={contentStyle}
            width={'100%'}
            height={'200px'}
            className="swapper-img-box"
            src={item.url}
            alt=""
          />
        </a>
      ))}
    </Carousel>
  );
};

export default SwapperImg;
