import { HistoryListRecord } from '@api/history/types';
import { Checkbox, Table } from 'antd';
import {
  ColumnGroupType,
  ColumnType,
  TablePaginationConfig,
  TableProps
} from 'antd/es/table';
import { FC } from 'react';

export interface HistoryTableProps {
  data: HistoryListRecord[];
  columns: (
    | ColumnGroupType<HistoryListRecord>
    | ColumnType<HistoryListRecord>
  )[];
  pagination: TablePaginationConfig;
  handleTableChange: TableProps<HistoryListRecord>['onChange'];
  selectedRowKeys: string[];
  setSelectedRowKeys: (keys: string[]) => void;
}

const HistoryTable: FC<HistoryTableProps> = ({
  data,
  columns,
  pagination,
  handleTableChange,
  selectedRowKeys,
  setSelectedRowKeys
}) => {
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys as string[]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  return (
    <Table
      rowKey={'id'}
      dataSource={data}
      columns={columns}
      pagination={{
        ...pagination,
        showTotal: () => `Total ${pagination.total} items`
      }}
      onChange={handleTableChange}
      rowSelection={rowSelection}
    />
  );
};

export default HistoryTable;
