import request from '@/utils/request';
import { HistoryListResult, ListParams } from './types';

export const shareHistoryList = (
  data: ListParams
): Promise<HistoryListResult> => {
  return request({
    url: '/share/history/shareHistoryList',
    method: 'post',
    data
  });
};
export const shareHistoryExport = (data: { ids: string[] }): Promise<null> => {
  return request({
    responseType: 'blob',
    url: '/share/history/shareHistoryExport',
    method: 'post',
    data
  });
};
export const downloadHistoryList = (
  data: ListParams
): Promise<HistoryListResult> => {
  return request({
    url: '/download/history/downloadHistoryList',
    method: 'post',
    data
  });
};
export const downloadHistoryExport = (data: {
  ids: string[];
}): Promise<null> => {
  return request({
    responseType: 'blob',
    url: '/download/history/downloadHistoryExport',
    method: 'post',
    data
  });
};
