export default {
  'component.checks.checkAll': 'Check all',
  'component.checks.checked': 'Checked',
  'component.select.placeholder': 'Please select ',
  'component.page.total': 'Total {{total}} items',
  'component.form.rules.letterOrNumber': 'Please input letters and numbers',
  'component.form.rules.number': 'Please input numbers',
  'component.searchCriteriaWrap.filter': 'Filter',
  'component.searchCriteriaWrap.moreFilter': 'More Filter',
  'component.searchCriteriaWrap.lessFilter': 'Pack Up Filter'
};
