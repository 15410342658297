import AppSensors from '@components/appSensors';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import App from './App';
import WindowResize from './components/window-resize';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RecoilRoot>
    <WindowResize>
      <AppSensors>
        {/* <React.StrictMode> */}
        {/* <AppContextProviders> */}
        <App />
        {/* </AppContextProviders> */}
        {/* </React.StrictMode> */}
      </AppSensors>
    </WindowResize>
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
