import { FC, useEffect, useState } from 'react';
import './index.scss';
import { Menu } from 'antd';
import RoleTable from './role-table';
import InsideUserTable from './inside-user-table';
import OutsideUserTable from './outside-user-table';

type ActiveTab = 'roles' | 'inside' | 'outside';

const PermisssionManagement: FC = () => {
  const [activeTab, setActiveTab] = useState<ActiveTab>('roles');

  const menuItems = [
    {
      label: '角色管理',
      key: 'roles'
    },
    {
      label: '内部用户',
      key: 'inside'
    },
    {
      label: '外部用户',
      key: 'outside'
    }
  ];
  const MenuContent = {
    roles: <RoleTable />,
    inside: <InsideUserTable />,
    outside: <OutsideUserTable />
  };

  useEffect(() => {
    setActiveTab('roles');
  }, []);
  return (
    <div className="permission">
      <div className="sider">
        <Menu
          items={menuItems}
          className="permission-menu"
          onClick={(info) => setActiveTab(info.key as ActiveTab)}
          selectedKeys={[activeTab]}
        />
      </div>
      <div className="wrapper">{MenuContent[activeTab]}</div>
    </div>
  );
};

export default PermisssionManagement;
