export default {
  'component.checks.checkAll': '全选',
  'component.checks.checked': '已选',
  'component.select.placeholder': '请选择',
  'component.page.total': '共 {{total}} 条',
  'component.form.rules.letterOrNumber': '请输入字母和数字',
  'component.form.rules.number': '请输入数字',
  'component.searchCriteriaWrap.filter': '筛选',
  'component.searchCriteriaWrap.moreFilter': '更多筛选',
  'component.searchCriteriaWrap.lessFilter': '收起筛选'
};
