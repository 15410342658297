import { TOKEN_KEY } from '@constants';
import { getStorage } from './localStorageService';

const getRequestCommonHeaders = (
  contentType: string
): {
  timestamp: number;
  'Content-Type': string;
  'x-token'?: string | null;
} => {
  const token: string = getStorage(TOKEN_KEY);
  return {
    'x-token': token,
    timestamp: +new Date(),
    'Content-Type': contentType || 'application/json;charset=UTF-8'
  };
};

export { getRequestCommonHeaders };
