export default {
  'component.menu.engineering': '蔡司工程师管理',
  'component.menu.orderManage': '订单管理',
  'menu.messageNotification': '消息通知列表',
  'menu.orderManage.list': '订单管理',
  'menu.orderManage.detail': '订单详情',
  'menu.installTask.list': '安装任务',
  'menu.installTask.detail': '安装任务详情',
  'menu.templates.list': '模板',
  'menu.templates.preparation': '任务准备模板',
  'menu.templates.gantt': '任务安装模板',
  'menu.templates.preparation-create': '编辑准备模板',
  'menu.templates.gantt-create': '编辑任务安装模板',
  'menu.userManage': '用户管理',
  'menu.roleManage': '角色管理',
  'menu.limitManage': '权限管理',
  'menu.dailyReport.list': '日报',
  'menu.dailyReport.detail': '日志详情',
  'menu.installTask.dashBoard': '任务看板',
  'menu.svoList.list': '服务订单列表',
  'menu.svoList.detail': '服务订单详情',
  'menu.summary.list': '安装管理',
  'menu.summary.preparation': '任务准备计划',
  'menu.summary.gantt': '任务安装计划',
  'menu.summary.dashBoard': '任务看板',
  'menu.overview': '总览',
  'menu.general': '通用',
  'menu.operation': '设置',
  'menu.roleManager': '管理权限',
  'menu.roleManager.userManage': '用户管理',
  'menu.roleManager.roleManage': '角色管理',
  'menu.roleManager.limitManage': '资源管理',
  'menu.workHours.list': '工时管理',
  'title.management.system': 'IQS Asset Center',
  'menu.templates.gantt-view': '查看任务安装模板',
  'menu.templates.preparation-view': '查看准备模板',
  'menu.customer.list': '客户管理',
  'menu.customer.detail': '客户详情',
  'menu.contents': '内容列表',
  'menu.permission': '权限管理',
  'menu.account': '账号配置',
  'menu.history': '操作历史',
  'menu.categories': '分类管理',
  'menu.account-list': '账号配置',
  'menu.create-account': '新增账号',
  'menu.bulk-create': '批量新增',
  'menu.create-category': '新建分类',
  'menu.banner-config': '轮播图管理'
};
