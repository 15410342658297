export default {
  'component.menu.engineering': 'Engineering',
  'component.menu.orderManage': 'Order',
  'menu.messageNotification': 'Notification Management',
  'menu.orderManage.list': 'Project Management',
  'menu.orderManage.detail': 'Order Detail',
  'menu.installTask.list': 'Installation Management',
  'menu.installTask.detail': 'Installation Management Detail',
  'menu.templates.list': 'Templates',
  'menu.templates.preparation': 'Preparation Template',
  'menu.templates.gantt': 'Installation Template',
  'menu.templates.preparation-create': 'Create Preparation Template',
  'menu.templates.gantt-create': 'Create Installation Template',
  'menu.dailyReport.list': 'Daily Report',
  'menu.dailyReport.detail': 'Daily Report Detail',
  'menu.installTask.dashBoard': 'Dashboard',
  'menu.svoList.list': 'Service Order List',
  'menu.svoList.detail': 'Service Order Details',
  'menu.summary.list': 'Installation Management',
  'menu.summary.preparation': 'Preparation Plan',
  'menu.summary.gantt': 'Installation Plan',
  'menu.summary.dashBoard': 'Dashboard',
  'menu.general': 'General',
  'menu.overview': 'Overview',
  'menu.operation': 'Operation',
  'menu.roleManager': 'RoleManager',
  'menu.roleManager.userManage': 'User Management',
  'menu.roleManager.roleManage': 'Role Management',
  'menu.roleManager.limitManage': 'Limit Management',
  'menu.workHours.list': 'Timesheet',
  'title.management.system': 'IQS Asset Center',
  'menu.templates.gantt-view': 'View Installation Template',
  'menu.templates.preparation-view': 'View Preparation Template',
  'menu.customer.list': 'Customer Management',
  'menu.customer.detail': 'Customer Details',
  'menu.contents': 'Contents',
  'menu.permission': 'Permissions',
  'menu.account': 'Accounts',
  'menu.history': 'History',
  'menu.categories': 'Categories',
  'menu.account-list': 'Account List',
  'menu.create-account': 'Create Account',
  'menu.bulk-create': 'Bulk Create',
  'menu.create-category': 'Create Category'
};
