/** 权限鉴权类型 */
export enum ZAuthType {
  /** 或权限（至少匹配一个） */
  'some' = 'some',
  /** 与权限（全部匹配） */
  'every' = 'every'
}

/** 页面权限 */
export enum ZAuthPagesRole {
  'copms' = 'copms',
  'manager' = 'manager',
  'workManage' = 'workManage',
  'overview' = 'overview',
  'orderManager' = 'orderManager',
  'queryUserList' = 'queryUserList',
  'queryRoleList' = 'queryRoleList',
  'installation' = 'installation',
  'templates' = 'templates',
  'svolist' = 'svolist',
  'notice' = 'notice',
  'daily' = 'daily',
  'customerManagement' = 'customerManagement',
  'admin' = 'admin'
}

/** 入口/按钮 权限 */
export enum ZAuthDetailRole {
  'summary' = 'summary',
  'daily_inner' = 'daily_inner',
  'daily_outer' = 'daily_outer',
  'customerDelete' = 'customerDelete',
  'resourceManager' = 'resourceManager',
  'operation' = 'operation',
  'roleManager' = 'roleManager',
  'userManager' = 'userManager'
}

/** 角色权限类型，统一配置 */
export const ZAuthRole = {
  ...ZAuthPagesRole,
  ...ZAuthDetailRole
};
