import { useCallback, useMemo } from 'react';
import { ZAuthType } from './authType';
import { userInfo } from '@store/index';
import { useRecoilState } from 'recoil';
/**
 * 转换为权限标识数组
 * @param auth 权限标识
 * @returns
 */

const getAuthKeys = (auth: string | string[]) => {
  return Array.isArray(auth) ? auth : [auth];
};

/** 权限Hook */
export const useAuth = () => {
  const [userInfoFromStore] = useRecoilState<any>(userInfo);
  const { authority, roleList } = userInfoFromStore;
  // 筛选出关键权限标识列表
  const getAllAuthority = (keyList: any[], keydata: any[]) => {
    keyList?.forEach((item) => {
      keydata.push(item?.resourceKey);
      if (item?.children.length) {
        getAllAuthority(item?.children, keydata);
      }
    });
    keydata = keydata.filter((item) => item !== null);
    return keydata;
  };
  const authKeys = useMemo(
    () => (authority ? getAllAuthority(authority, ['comps']) : []),
    [authority]
  );
  const roleKeys = useMemo(
    () =>
      roleList ? roleList.map((item: { roleKey: any }) => item.roleKey) : [],
    [roleList]
  );

  // 校验是否具备权限
  const hasAuth = useCallback(
    (auth: string | string[], type?: ZAuthType) =>
      getAuthKeys(auth)[type ?? ZAuthType.some]((key) =>
        authKeys.includes(key)
      ),
    [authKeys]
  );

  const ret: [typeof authKeys, typeof roleKeys, typeof hasAuth] = [
    authKeys,
    roleKeys,
    hasAuth
  ];
  return ret;
};
