export default {
  'account.multiple': 'Multiple',
  'account.cancelMultiple': 'Cancel Multiple',
  'account.bulkCreate': 'Bulk Create',
  'account.createAccount': 'Create',
  'account.btn.create': 'Create & Send Email',
  'account.username': 'Username',
  'account.importAccount': 'Import',
  'account.uploadFile': 'Upload File',
  'account.btn.freeze': 'Freeze',
  'account.btn.bulkFreeze': 'Bulk Freeze',
  'account.btn.thaw': 'Thaw',
  'account.btn.bulkThaw': 'Bulk Thaw',
  'account.btn.frozen': 'Frozen',
  'account.btn.activity': 'Activity',
  'account.bulkFreezeConfirm':
    'Are you sure to batch freezeing all selected accounts?',
  'account.freezeConfirm': 'Are you sure to freeze all selected accounts?',
  'account.bulkThawConfirm':
    'Are you sure to batch thawing all selected accounts?',
  'account.thawConfirm': 'Are you sure to thaw all selected accounts?',
  'account.unknownStatus': 'Unknown',
  'account.errMsg': 'Error Msg'
};
