import { genBreadcrumbProps } from '@/utils/getBreadcrumbProps';
import { Breadcrumb } from 'antd';
import { transformRoute } from '@umijs/route-utils';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { ReactComponent as BreadcrumbSeparator } from '@/assets/image/breadcrumb-separator.svg';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import './index.scss';
import useRoutes from '@authority/useRoutes';
import appConfig from '@/appConfig';

const ZeissBreadcrumb = (): ReactElement => {
  const routeList = useRoutes(appConfig.routerType);
  const { t } = useTranslation();
  const location = useLocation();
  const { breadcrumb } = transformRoute(routeList);
  const routes = genBreadcrumbProps({ location, breadcrumbMap: breadcrumb, t });
  const handleClick = (event: any) => {
    event.preventDefault();
  };
  const itemRender = (
    item: BreadcrumbItemType,
    _: any,
    items: BreadcrumbItemType[],
    paths: string[]
  ) => {
    const last = items.indexOf(item) === items.length - 1;
    return last ? (
      <span className="breadcrumb-wrap-color">{item.breadcrumbName}</span>
    ) : (
      <Link
        to={paths.slice(-1).join('/')}
        state={{ ...location.state }}
        className="breadcrumb-wrap-color"
        onClick={handleClick}
      >
        {item.breadcrumbName}
      </Link>
    );
  };

  return (
    <div className="zeiss-breadcrumb-wrap">
      <Breadcrumb
        items={routes as any}
        separator={<BreadcrumbSeparator />}
        itemRender={itemRender}
      ></Breadcrumb>
    </div>
  );
};

export default ZeissBreadcrumb;
