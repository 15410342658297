/* eslint-disable @typescript-eslint/restrict-plus-operands */

import { CategoryListItem } from '@api/category/type';
import { IS_PROD } from '@constants';
import { routerInterFace } from '@router/types';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { UserInfo } from 'os';
import { RouteObject } from 'react-router-dom';
dayjs.extend(isoWeek);

interface optionsListInterface {
  value: string | number;
  label: string | number;
}

export const optionsListFormat = (
  data: any[] = [],
  keyText: string = 'value',
  valText: string = 'label'
): optionsListInterface[] =>
  data?.map((val) => ({
    value: val[keyText],
    label: val[valText]
  }));

export const rulesDefault: any[] = [{ required: true }];

export const getFormRules = (...data: any[]): any[] => {
  return [...rulesDefault, ...data];
};

export const formatSeconds = (value: number): string => {
  //  秒
  let second = value;
  //  分
  let minute = 0;
  //  小时
  let hour = 0;
  //  天
  //  let day = 0
  //  如果秒数大于60，将秒数转换成整数
  if (second > 60) {
    //  获取分钟，除以60取整数，得到整数分钟
    minute = Math.trunc(second / 60);
    //  获取秒数，秒数取佘，得到整数秒数
    second = Math.trunc(second % 60);
    //  如果分钟大于60，将分钟转换成小时
    if (minute > 60) {
      //  获取小时，获取分钟除以60，得到整数小时
      hour = Math.trunc(minute / 60);
      //  获取小时后取佘的分，获取分钟除以60取佘的分
      minute = Math.trunc(minute % 60);
      //  如果小时大于24，将小时转换成天
      //  if (hour > 23) {
      //    //  获取天数，获取小时除以24，得到整天数
      //    day = parseInt(hour / 24)
      //    //  获取天数后取余的小时，获取小时除以24取余的小时
      //    hour = parseInt(hour % 24)
      //  }
    }
  }

  let result = '' + Math.trunc(second) + 's';
  if (minute > 0) {
    result = '' + Math.trunc(minute) + 'min' + result;
  }
  if (hour > 0) {
    result = '' + Math.trunc(hour) + 'hour' + result;
  }

  return result;
};

export const browser = (): {
  trident: boolean;
  presto: boolean;
  webKit: boolean;
  gecko: boolean;
  mobile: boolean;
  ios: boolean;
  android: boolean;
  iPhone: boolean;
  iPad: boolean;
  webApp: boolean;
  weixin: boolean;
} => {
  // 获取浏览器UA标识
  const u = navigator.userAgent;
  return {
    trident: u.includes('Trident'), // IE内核
    presto: u.includes('Presto'), // opera内核
    webKit: u.includes('AppleWebKit'), // 苹果、谷歌内核
    gecko: u.includes('Gecko') && !u.includes('KHTML'), // 火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: u.includes('Android') || u.includes('Adr'), // android终端
    iPhone: u.includes('iPhone'), // 是否为iPhone或者QQHD浏览器
    iPad: u.includes('iPad'), // 是否iPad
    webApp: !u.includes('Safari'), // 是否web应该程序，没有头部与底部
    weixin: u.includes('MicroMessenger') // 是否微信 （2015-01-22新增）
  };
};

export const exportExcel = (
  data: any,
  filename: string,
  contentType: string
) => {
  const blob = new Blob([data], { type: contentType });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = filename;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
};

export const downloadUrl = (url: string, filename: string) => {
  const downloadElement = document.createElement('a');
  downloadElement.href = url;
  downloadElement.download = filename;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
};

export const getFlatRouterList = (
  list: routerInterFace[],
  newList?: routerInterFace[]
) => {
  const newArr: any[] = [];
  const arr = list.reduce((routerList, curRouter) => {
    if (curRouter.children && curRouter.children.length >= 0) {
      getFlatRouterList(curRouter.children, routerList);
    } else if (curRouter.component) {
      routerList.push(curRouter);
    }
    return routerList;
  }, newList ?? []);
  arr.forEach((item: any) => {
    return newArr.push({ ...item, path: item.path.replace(/^\/+/, '') });
  });
  return newArr;
};

// export const debounce = <T>(fn: T, delay: number): (() => void) => {
//   let timer: NodeJS.Timeout
//   return function (): void {
//     const args: any[] = Array.prototype.map.call(arguments, (val) => val)
//     if (timer) {
//       clearTimeout(timer)
//     }
//     timer = setTimeout(
//       () => {
//         typeof fn === 'function' && fn.apply(null, args)
//         clearTimeout(timer)
//       },
//       delay > 0 ? delay : 100
//     )
//   }
// }

export const getLoginCodeUrl = (callBackUrl: string): string => {
  return IS_PROD
    ? `https://pros.test.zeiss.com.cn/identity/connect/authorize?response_type=code&client_id=zdp-platform-admin&redirect_uri=${callBackUrl}&scope=openid+profile+email+phone&code_challenge=6CQ6DK8SWnZE9t9_2GWJ6L_-nQ_sTaq9Ht17Z7GQtQQ&code_challenge_method=S256`
    : `https://pros.test.zeiss.com.cn/identity/connect/authorize?response_type=code&client_id=zdp-platform-admin&redirect_uri=${callBackUrl}&scope=openid+profile+email+phone&code_challenge=6CQ6DK8SWnZE9t9_2GWJ6L_-nQ_sTaq9Ht17Z7GQtQQ&code_challenge_method=S256`;
};

export const getMonthArray = () => {
  const months = [];
  for (let month = 1; month <= 12; month++) {
    months.push(month);
  }
  return months;
};

export const getYearArray = (minYear: number, maxYear: number) => {
  const years = [];
  for (let year = minYear; year <= maxYear; year++) {
    years.push(year);
  }
  return years;
};

export const calculateWorkingDays = (startDate: string, endDate: string) => {
  let start = dayjs(startDate).startOf('d');
  const end = dayjs(endDate);
  let count = 0;

  while (start.isBefore(end, 'day') || start.isSame(end, 'day')) {
    if (!(start.day() === 0 || start.day() === 6)) {
      count++;
    }
    start = start.add(1, 'day').startOf('d');
  }

  return count;
};

export const deepEqual = (obj1: any, obj2: any) => {
  // 如果两个对象都是 null 或者 undefined，返回 true
  if (
    obj1 === null ||
    obj2 === null ||
    obj1 === undefined ||
    obj2 === undefined
  ) {
    return obj1 === obj2;
  }

  // 如果两个对象的类型不同，返回 false
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // 如果两个对象都是基本类型，直接比较它们的值
  if (typeof obj1 !== 'object' && typeof obj2 !== 'object') {
    return obj1 === obj2;
  }

  // 如果两个对象的属性个数不同，返回 false
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  // 递归比较两个对象的每个属性值
  for (const prop in obj1) {
    if (!deepEqual(obj1[prop], obj2[prop])) {
      return false;
    }
  }

  return true;
};

/**
 *
 * @param date1 开始时间
 * @param date2 结束时间
 * @returns []
 */
export const ganttTime = (date1: string, date3: string) => {
  const num = dayjs(date3).diff(dayjs(date1), 'd');
  const getWeeks = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const arr = [];
  for (let i = 0; i < num + 1; i++) {
    const date = dayjs(date1).add(i, 'd').format('YYYY-MM-DD');
    const month = months[dayjs(date).month()];
    const yearMonth = dayjs(date).format('YYYY-MM');
    const getWeek = getWeeks[dayjs(date).day()];

    const index = arr.findIndex((v) => v.date === yearMonth);

    if (index === -1) {
      arr.push({
        months: month,
        date: yearMonth,
        dataList: [{ date, week: getWeek }]
      });
    } else {
      arr[index].dataList.push({ date, week: getWeek });
    }
  }
  return arr;
};

/**
 * date1 开始时间
 * date2 结束时间
 * need 包含时间
 * unwanted 不需要的时间
 * 间距时间多少天
 */
export const ganttTime1 = (
  date1: string,
  date2: string,
  need: string[] = [],
  unwanted: string[] = []
) => {
  const lastDateArr = [];
  const num =
    dayjs(dayjs(date2).hour(23).minute(59).second(59)).diff(
      dayjs(dayjs(date1).hour(0).minute(0).second(0)),
      'd'
    ) + 1;
  for (let i = 0; i < num; i++) {
    const date = dayjs(date1).add(i, 'd').format('YYYY-MM-DD');
    const weeks = dayjs(date).day();
    if (
      ((weeks !== 6 && weeks !== 0) || need.includes(date)) &&
      !unwanted.includes(date)
    ) {
      lastDateArr.push(date);
    }
  }
  return lastDateArr;
};

/**
 *
 * @param date1 初始时间
 * @param date2 结束时间
 * @returns 返回这个区间的所有时间 数组
 */
export const ganttTime2 = (date1: string, date2: string) => {
  const lastDateArr = [];
  const num =
    dayjs(dayjs(date2).hour(23).minute(59).second(59)).diff(
      dayjs(dayjs(date1).hour(0).minute(0).second(0)),
      'd'
    ) + 1;
  for (let i = 0; i < num; i++) {
    const date = dayjs(date1).add(i, 'd').format('YYYY-MM-DD');
    lastDateArr.push(date);
  }
  return lastDateArr;
};

export const milestoneStatus = (type: number) => {
  switch (type) {
    case 1:
      return '#1E8565';
    case 2:
      return '#D9E906';
    case 3:
      return '#E71E1E';
    default:
      return '';
  }
};

/**
 *
 * @param list 所有连续时间
 * @returns  返回week array
 */
export const getWeek = (list: string[]) => {
  const arr: any = [];
  let arrTemp: number[] = [];
  let i = 0;
  list.forEach((res, index) => {
    const week = dayjs(res).isoWeek();
    if (i !== week && index !== 0) {
      arr.push(arrTemp);
      arrTemp = [week];
      if (index === list.length - 1) {
        arr.push(arrTemp);
      }
    } else {
      arrTemp.push(week);
      if (index === list.length - 1) {
        arr.push(arrTemp);
      }
    }
    i = week;
  });
  return arr;
};

export const ganttTime3 = (
  date1: any,
  workDayNum: number,
  need: string[] = [],
  unwanted: string[] = []
) => {
  date1 = dayjs(date1).hour(0).minute(0).second(0);
  const lastDateArr = [];
  let i = 0;
  while (lastDateArr.length < workDayNum) {
    const date = dayjs(date1).add(i, 'd').format('YYYY-MM-DD');
    const weeks = dayjs(date).day();
    if (
      ((weeks !== 6 && weeks !== 0) || need.includes(date)) &&
      !unwanted.includes(date)
    ) {
      lastDateArr.push(date);
    }
    i++;
  }
  if (lastDateArr.length === 0) {
    return [
      dayjs(date1).format('YYYY-MM-DD'),
      dayjs(date1).format('YYYY-MM-DD')
    ];
  } else if (lastDateArr.length === 1) {
    return [dayjs(date1).format('YYYY-MM-DD'), ...lastDateArr];
  } else {
    return [
      dayjs(date1).format('YYYY-MM-DD'),
      lastDateArr[lastDateArr.length - 1]
    ];
  }
};

export const removeEmptyChildren = (nodes: any) => {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (node.children && node.children.length > 0) {
      // 递归处理子节点
      removeEmptyChildren(node.children);
    }
    // 如果children为空数组，则将children字段移除
    if (node.children && node.children.length === 0) {
      delete node.children;
    }
  }
};

export const uuid = () => {
  let d = new Date().getTime();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now();
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};
export const findSubOpenKey = (keys: string, menuList: any[]): string[] => {
  let subOpentKey: string[] = [];

  menuList?.forEach((item: any) => {
    if (item.key === keys) {
      subOpentKey = [item.key];
    } else if (item?.children?.length) {
      const subOpentKeyInChild = findSubOpenKey(keys, item.children);
      if (subOpentKeyInChild.length) {
        subOpentKey = [item.key, ...subOpentKeyInChild];
      }
    }
  });
  return subOpentKey;
};

export const downloadFileBlob = ({ data, headers }: any) => {
  const fileName = headers['content-disposition']?.split(';')[1].split('=')[1];
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(
    new Blob([data], {
      type: headers['content-type']
    })
  );
  a.download = decodeURIComponent(fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const addTreeKeyToArray = (data: CategoryListItem[]) => {
  const resultArray: string[] = [];
  const addKeyToArray = (children: CategoryListItem[]) => {
    children.forEach((item) => {
      if (item.children) {
        addKeyToArray(item.children);
      }
      resultArray.push(item.id);
    });
  };
  addKeyToArray(data);
  return resultArray;
};

export interface UserInfoAuthority {
  resourceKey: string;
  children: UserInfoAuthority[];
}

export const hasAuth = (authority: UserInfoAuthority[], key: string) => {
  const auth = authority
    .find((auth) => auth.resourceKey === 'content')
    ?.children?.findIndex((child) => child.resourceKey === key);
  return auth !== undefined ? auth > -1 : false;
};
