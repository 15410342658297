import ZeissButton from '@components/button';
import { Checkbox, Col, Form, Input, Row, message } from 'antd';
import { FC, useEffect } from 'react';
import './index.scss';
import {
  modifyRole,
  queryResourceAllList,
  queryRoleDetail,
  saveRole
} from '@api/role';
import { useImmer } from 'use-immer';
import { RoleResource } from '@api/role/type';
import _ from 'lodash';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  commonPermissionText,
  contentColumnPermissionText,
  menuPermissionText,
  otherPermissionText
} from './permission-config';

interface RoleEditProps {
  role: { id: string; adminRoleId: string; roleKey?: string };
  setEditRole: (role: { id: string; adminRoleId: string } | undefined) => void;
  fetchRoleList: () => Promise<void>;
}

const RoleEdit: FC<RoleEditProps> = ({ role, setEditRole, fetchRoleList }) => {
  const [resourceList, setResourceList] = useImmer<RoleResource[]>([]);
  const [commonPermission, setCommonPermission] = useImmer<RoleResource[]>([]);
  const [otherPermission, setOtherPermission] = useImmer<RoleResource[]>([]);
  const [menuPermission, setMenuPermisssion] = useImmer<RoleResource[]>([]);
  const [contentColumnPermission, setcontentColumnPermisssion] = useImmer<
    RoleResource[]
  >([]);
  const [form] = Form.useForm();

  // 平铺数据
  const flatData = (list: RoleResource[]) => {
    let newResourceList: RoleResource[] = [];
    list.forEach((v) => {
      if (v.children && v.children.length > 0) {
        newResourceList = newResourceList.concat(flatData(v.children));
      } else {
        newResourceList = [v, ...newResourceList];
      }
    });
    return newResourceList;
  };
  const handleSubmit = async () => {
    const result = form.getFieldsValue();
    console.log(result, 'result');
    const common = result['file-permission']['common-permission'] || [];
    const other = result['file-permission']['other-permission'] || [];
    const menu = result['menu-permission'] || [];
    const contentColumn = result['content-column-permission'] || [];
    const rolePermisssion = common
      .concat(other)
      .concat(menu)
      .concat(contentColumn);
    const rolePermissionKey = flatData(resourceList)
      .filter((v) => rolePermisssion.includes(v.resourceName))
      .map((v) => v.resourceKey);
    if (!role) return;
    try {
      if (role.id === 'new') {
        await saveRole({
          roleName: result.name,
          remark: result.description,
          rolePermission: rolePermisssion.join(','),
          rolePermissionKey: rolePermissionKey.join(',')
        });
        message.success('新建成功');
        await fetchRoleList();
        setEditRole(undefined);
      } else {
        await modifyRole({
          id: +role.id,
          roleKey: role.roleKey,
          roleName: result.name,
          remark: result.description,
          rolePermission: rolePermisssion.join(','),
          rolePermissionKey: rolePermissionKey.join(',')
        });
        message.success('编辑成功');
        await fetchRoleList();
        setEditRole(undefined);
      }
    } catch (error) {
      console.error('操作失败' + error);
    }
  };

  const handleCancelClick = () => {
    setEditRole(undefined);
  };

  const getResourceAllList = async () => {
    try {
      const data = await queryResourceAllList();
      setResourceList(data.resources || []);
    } catch (error) {
      console.error(error);
    }
  };

  const findPermission = (
    text: { resourceKey: string; resourceName: string }[],
    origin: string[]
  ) => {
    const result: string[] = [];
    text.forEach((item) => {
      origin.forEach((permission) => {
        if (item.resourceName === permission) {
          result.push(item.resourceName);
        }
      });
    });
    return result;
  };

  const getRoleDetail = async () => {
    const res = await queryRoleDetail(role);
    form.setFields([
      { name: 'name', value: res.roleName },
      { name: 'description', value: res.remark },
      {
        name: 'file-permission',
        value: {
          'common-permission': findPermission(
            commonPermissionText,
            res.resourceList || []
          ),
          'other-permission': findPermission(
            otherPermissionText,
            res.resourceList || []
          )
        }
      },
      {
        name: 'menu-permission',
        value: findPermission(menuPermissionText, res.resourceList || [])
      },
      {
        name: 'content-column-permission',
        value: findPermission(
          contentColumnPermissionText,
          res.resourceList || []
        )
      }
    ]);
  };

  const handleCommonPermissionCheckAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      form.setFieldValue(
        ['file-permission', 'common-permission'],
        commonPermission.map((item) => item.resourceName)
      );
    } else {
      form.setFieldValue(['file-permission', 'common-permission'], []);
    }
  };

  const handleOtherPermissionCheckAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      form.setFieldValue(
        ['file-permission', 'other-permission'],
        otherPermission.map((item) => item.resourceName)
      );
    } else {
      form.setFieldValue(['file-permission', 'other-permission'], []);
    }
  };
  const handleMenuPermissionCheckAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      form.setFieldValue(
        ['menu-permission'],
        menuPermission.map((item) => item.resourceName)
      );
    } else {
      form.setFieldValue(['menu-permission'], []);
    }
  };
  const handleContentColumnPermissionCheckAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      form.setFieldValue(
        ['content-column-permission'],
        contentColumnPermission.map((item) => item.resourceName)
      );
    } else {
      form.setFieldValue(['content-column-permission'], []);
    }
  };

  const validator = (
    rule: any,
    value: string | { 'common-permission'?: []; 'other-permission'?: [] }
  ) => {
    if (typeof value === 'string') {
      if (!value) {
        return Promise.reject('文件操作权限设置不能为空');
      }
      return Promise.resolve();
    } else {
      if (
        !value['common-permission']?.length &&
        !value['other-permission']?.length
      ) {
        return Promise.reject('文件操作权限设置不能为空');
      }
      return Promise.resolve();
    }
  };

  useEffect(() => {
    if (!role) return;
    if (role.id !== 'new') {
      getRoleDetail();
    }
  }, [role]);

  useEffect(() => {
    const contentPermission = resourceList
      .find((item) => item.resourceKey === 'content')
      ?.children?.filter((child) => child.resourceKey !== 'contentColumn');

    const filePermission = resourceList
      .find((item) => item.resourceKey === 'content')
      ?.children?.find(
        (child) => child.resourceKey === 'contentColumn'
      )?.children;

    const menuPermission = resourceList.map(
      (resource) =>
        menuPermissionText.findIndex(
          (text) => text.resourceKey === resource.resourceKey
        ) > -1 && resource
    );
    setCommonPermission(
      contentPermission?.filter(
        (item) =>
          item.resourceKey === 'share' || item.resourceKey === 'download'
      ) || []
    );
    setOtherPermission(
      contentPermission?.filter(
        (item) =>
          item.resourceKey !== 'share' && item.resourceKey !== 'download'
      ) || []
    );
    setcontentColumnPermisssion((filePermission as RoleResource[]) || []);
    setMenuPermisssion(_.compact(menuPermission));
  }, [JSON.stringify(resourceList)]);

  useEffect(() => {
    getResourceAllList();
  }, []);

  return (
    <div className="role-form">
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item>
          <div className="title">
            {role.id === 'new' ? '新建角色' : '角色编辑'}
          </div>
        </Form.Item>
        <Form.Item
          className="role-detail"
          label={'角色名称'}
          name={'name'}
          rules={[{ required: true, message: '角色名称不能为空' }]}
        >
          <Input
            placeholder="请输入名称"
            className="custom-input"
            bordered={false}
          />
        </Form.Item>

        <Form.Item
          label={'角色描述'}
          name={'description'}
          className="role-detail"
        >
          <Input
            placeholder="请输入描述"
            className="custom-input"
            bordered={false}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 12 }}>
          <div className="title">文件操作权限设置（必填）</div>
        </Form.Item>

        <Form.Item
          name={'file-permission'}
          rules={[
            { required: true, message: '文件操作权限设置不能为空' },
            { validator }
          ]}
        >
          <div className="permission-title">
            <span>常用权限</span>
            <Checkbox onChange={handleCommonPermissionCheckAll}>全选</Checkbox>
          </div>
          <Form.Item name={['file-permission', 'common-permission']}>
            <Checkbox.Group className="permission-group">
              <Row>
                {commonPermission.map((item) => (
                  <Col key={item.resourceKey} span={6}>
                    <Checkbox
                      value={item.resourceName}
                      style={{ lineHeight: '32px' }}
                    >
                      {item.resourceName}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <div className="permission-title">
            <span>其他权限</span>
            <Checkbox onChange={handleOtherPermissionCheckAll}>全选</Checkbox>
          </div>
          <Form.Item name={['file-permission', 'other-permission']}>
            <Checkbox.Group className="permission-group">
              <Row>
                {otherPermission.map((item) => (
                  <Col key={item.resourceKey} span={6}>
                    <Checkbox
                      value={item.resourceName}
                      style={{ lineHeight: '32px' }}
                    >
                      {item.resourceName}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form.Item>

        <div className="permission-title">
          <div className="title">内容列表可见列权限设置（必填）</div>
          <Checkbox onChange={handleContentColumnPermissionCheckAll}>
            全选
          </Checkbox>
        </div>

        <Form.Item
          name={'content-column-permission'}
          rules={[
            { required: true, message: '内容列表可见列权限设置不能为空' }
          ]}
        >
          <Checkbox.Group className="permission-group">
            <Row>
              {contentColumnPermission.map((item) => (
                <Col key={item.resourceKey} span={6}>
                  <Checkbox
                    value={item.resourceName}
                    style={{ lineHeight: '32px' }}
                  >
                    {item.resourceName}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <div className="permission-title">
          <div className="title">菜单栏权限设置</div>
          <Checkbox onChange={handleMenuPermissionCheckAll}>全选</Checkbox>
        </div>

        <Form.Item name={'menu-permission'}>
          <Checkbox.Group className="permission-group">
            <Row>
              {menuPermission.map((item) => (
                <Col key={item.resourceKey} span={6}>
                  <Checkbox
                    value={item.resourceName}
                    style={{ lineHeight: '32px' }}
                  >
                    {item.resourceName}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item className="create-role-buttons">
          <ZeissButton className="color-btn" onClick={handleCancelClick}>
            取消
          </ZeissButton>
          <ZeissButton type="primary" htmlType="submit">
            确定
          </ZeissButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RoleEdit;
