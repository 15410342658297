import {
  Button,
  Divider,
  Input,
  Popconfirm,
  Table,
  TableProps,
  message
} from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { deleteAccount, getUserList } from '@api/user';
import { useImmer } from 'use-immer';
import { UserListParams, listResponseRecordsFace } from '@api/user/type';
import { SearchProps } from 'antd/lib/input';
import ZeissBreadcrumb from '@components/breadcrumb';
import Icon, { CheckOutlined } from '@ant-design/icons';
import { ReactComponent as SearchIcon } from '@/assets/image/search-icon.svg';
import { ReactComponent as ChecklistIcon } from '@/assets/image/checklist-icon.svg';
import { ReactComponent as ContentIcon } from '@/assets/image/content-icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/image/plus-icon.svg';
import { ReactComponent as SortIcon } from '@/assets/image/sort-icon.svg';
import { ReactComponent as DeleteCircleIcon } from '@/assets/image/delete-circle.svg';
import ZeissButton from '@components/button';
import {
  FilterValue,
  SortOrder,
  SorterResult,
  TablePaginationConfig
} from 'antd/es/table/interface';

const AccountManagement: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [users, setUsers] = useImmer<listResponseRecordsFace[]>([]);
  const [pagination, setPagination] = useState({
    sizeCanChange: true,
    total: 96,
    pageSize: 10,
    current: 1,
    showQuickJumper: true,
    showSizeChanger: true
  });
  const [multiple, setMultiple] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');

  useEffect(() => {
    getUserList({ type: 1, order: 'descend' }).then((res) => {
      const { current, size, total, records } = res.users;
      setUsers(records);
      setPagination((pagination) => ({
        ...pagination,
        current,
        pageSize: size,
        total
      }));
    });
  }, []);

  const getUsers = async (params: UserListParams) => {
    const { users } = await getUserList({
      type: 1,
      order: 'descend',
      ...params
    });
    const { current, size, total, records } = users;
    setUsers(records);
    setPagination((pagination) => ({
      ...pagination,
      current,
      pageSize: size,
      total
    }));
  };

  const handleClickCreate = () => {
    navigate({ pathname: `/create-account` });
  };

  const handleClickFreeze = async (id: string) => {
    await deleteAccount({ userIds: [id], deleteStatus: 1 });
    message.success('冻结成功');
    await getUsers({});
  };

  const handleClickThaw = async (id: string) => {
    await deleteAccount({ userIds: [id], deleteStatus: 0 });
    message.success('解冻成功');
    await getUsers({});
  };

  const handleClickBulk = () => {
    navigate({ pathname: `/bulk-create` });
  };

  const handleSearch: SearchProps['onSearch'] = async (value: string) => {
    await getUsers({ keyword: value });
  };

  const handleClickMultiple = (value: boolean) => {
    // 点击取消多选，清空选中列表
    if (!value) {
      setSelectedIds([]);
    }
    setMultiple(value);
  };

  const handleClickMultipleFreeze = async () => {
    if (!selectedIds.length) {
      message.warning('请先选择内容');
      return;
    }
    await deleteAccount({ userIds: selectedIds, deleteStatus: 1 });
    message.success('批量冻结成功');
    await getUsers({});
  };

  const handleClickMultipleThaw = async () => {
    if (!selectedIds.length) {
      message.warning('请先选择内容');
      return;
    }
    await deleteAccount({ userIds: selectedIds, deleteStatus: 0 });
    message.success('批量解冻成功');
    await getUsers({});
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleClickSearchIcon = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleSearch(searchInput);
  };

  const handleTableChange = async (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<listResponseRecordsFace>
      | SorterResult<listResponseRecordsFace>[]
  ) => {
    setPagination({
      ...pagination,
      pageSize: paginationConfig.pageSize || 10,
      current: paginationConfig.current || 1
    });
    let order: SortOrder | undefined = undefined;
    if (!Array.isArray(sorter)) {
      order = sorter.order || 'descend';
    }
    const deleteStatus =
      filters.deleteStatus?.length === 1 ? +filters.deleteStatus[0] : undefined;
    await getUsers({
      pageNumber: paginationConfig.current,
      pageSize: paginationConfig.pageSize,
      order,
      deleteStatus: deleteStatus as 0 | 1 | undefined
    });
  };

  const columns = [
    {
      title: '用户名',
      dataIndex: 'nickName',
      key: 'nickName',
      width: '30%'
    },
    {
      title: '邮箱',
      dataIndex: 'account',
      key: 'account'
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: true,
      sortIcon: () => <Icon component={SortIcon} />
    },
    {
      title: '创建者',
      dataIndex: 'createUser',
      key: 'createUser'
    },
    {
      title: '帐号状态',
      dataIndex: 'deleteStatus',
      key: 'deleteStatus',
      render: (_: unknown, value: listResponseRecordsFace) => {
        if (value.deleteStatus === 0) {
          return (
            <span className="active">
              <span className="dot active" />
              {t('account.btn.activity')}
            </span>
          );
        } else if (value.deleteStatus === 1) {
          return (
            <span className="frozen">
              <span className="dot frozen" />
              {t('account.btn.frozen')}
            </span>
          );
        } else {
          return t('account.unknownStatus');
        }
      },
      filters: [
        { text: t('account.btn.frozen'), value: 1 },
        { text: t('account.btn.activity'), value: 0 }
      ]
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: unknown, value: listResponseRecordsFace) => (
        <>
          <Popconfirm
            title={t('account.btn.freeze')}
            description={t('account.freezeConfirm')}
            onConfirm={() => handleClickFreeze(value.userId)}
            onCancel={() => {}}
            okText={t('btn.confirm')}
            cancelText={t('btn.cancel')}
          >
            <Button type="link" disabled={value.deleteStatus === 1}>
              {t('account.btn.freeze')}
            </Button>
          </Popconfirm>
          <Divider type="vertical" />
          <Popconfirm
            title={t('account.btn.thaw')}
            description={t('account.thawConfirm')}
            onConfirm={() => handleClickThaw(value.userId)}
            onCancel={() => {}}
            okText={t('btn.confirm')}
            cancelText={t('btn.cancel')}
          >
            <Button type="link" disabled={value.deleteStatus === 0}>
              {t('account.btn.thaw')}
            </Button>
          </Popconfirm>
        </>
      )
    }
  ];

  const extraProps = useMemo(
    () =>
      multiple
        ? ({
            rowSelection: {
              type: 'checkbox',
              onChange: (
                _: string[],
                selectedRows: listResponseRecordsFace[]
              ) => {
                setSelectedIds(selectedRows.map((row) => row.userId));
              }
            }
          } as TableProps<listResponseRecordsFace>)
        : {},
    [multiple]
  );

  return (
    <div className="account-wrapper wrapper">
      <ZeissBreadcrumb />
      <div className="page-title">{t('menu.account')}</div>
      <div className="account-table-header">
        <div className="account-search-wrapper">
          <Input.Search
            placeholder="请输入搜索内容"
            onChange={handleSearchInputChange}
            onSearch={handleSearch}
            suffix={
              <Icon
                component={SearchIcon}
                className="search-button"
                onClick={handleClickSearchIcon}
              />
            }
            bordered={false}
          />
        </div>
        <div className="account-actions-wrapper">
          {!multiple ? (
            <ZeissButton
              className="account-action-button color-btn"
              onClick={() => handleClickMultiple(true)}
              icon={<Icon component={ChecklistIcon} />}
            >
              {t('account.multiple')}
            </ZeissButton>
          ) : (
            <ZeissButton
              className="account-action-button color-btn"
              onClick={() => handleClickMultiple(false)}
              icon={<Icon component={ChecklistIcon} />}
            >
              {t('account.cancelMultiple')}
            </ZeissButton>
          )}
          <Popconfirm
            title={t('account.btn.bulkFreeze')}
            description={t('account.bulkFreezeConfirm')}
            onConfirm={handleClickMultipleFreeze}
            onCancel={() => {}}
            okText={t('btn.confirm')}
            cancelText={t('btn.cancel')}
          >
            <ZeissButton
              className="account-action-button"
              danger
              icon={<Icon component={DeleteCircleIcon} />}
            >
              {t('account.btn.bulkFreeze')}
            </ZeissButton>
          </Popconfirm>
          <Popconfirm
            title={t('account.btn.bulkThaw')}
            description={t('account.bulkThawConfirm')}
            onConfirm={handleClickMultipleThaw}
            onCancel={() => {}}
            okText={t('btn.confirm')}
            cancelText={t('btn.cancel')}
          >
            <ZeissButton
              className="account-action-button color-btn"
              icon={<CheckOutlined />}
            >
              {t('account.btn.bulkThaw')}
            </ZeissButton>
          </Popconfirm>
          <ZeissButton
            className="account-action-button"
            type="primary"
            onClick={handleClickBulk}
            icon={<Icon component={ContentIcon} />}
          >
            {t('account.bulkCreate')}
          </ZeissButton>
          <ZeissButton
            className="account-action-button"
            type="primary"
            onClick={handleClickCreate}
            icon={<Icon component={PlusIcon} />}
          >
            {t('account.createAccount')}
          </ZeissButton>
        </div>
      </div>
      <div className="account-table-wrapper">
        <Table
          rowKey={'id'}
          className="account-table"
          dataSource={users.map((user) => ({ ...user, key: user.userId }))}
          columns={columns}
          pagination={{
            ...pagination,
            showTotal: () => `Total ${pagination.total} items`
          }}
          onChange={handleTableChange}
          size="small"
          {...extraProps}
        />
      </div>
    </div>
  );
};

export default AccountManagement;
