import Icon, { CloseOutlined } from '@ant-design/icons';
import ZeissButton from '@components/button';
import { Popconfirm, Popover } from 'antd';
import { FC, ReactNode, useState } from 'react';
import { ReactComponent as ExclamationIcon } from '@/assets/image/exclamation.svg';
import './index.scss';

interface ZeissPopconfirmProps {
  title: ReactNode;
  content: ReactNode;
  children: ReactNode;
  onOk: () => void;
  onCancel?: () => void;
  footer?: ReactNode;
}

const ZeissPopconfirm: FC<ZeissPopconfirmProps> = ({
  title,
  content,
  children,
  onOk,
  onCancel,
  footer
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleClickOk = () => {
    onOk();
    setOpen(false);
  };

  const handleClickCancel = () => {
    onCancel?.();
    setOpen(false);
  };

  const popoverContent = (
    <div className="popover-container">
      <div className="popover-header">
        <CloseOutlined
          className="popover-close"
          onClick={() => setOpen(false)}
        />
      </div>
      <div className="popover-icon">
        <Icon component={ExclamationIcon} style={{ fontSize: 48 }}></Icon>
      </div>
      <div className="popover-title">{title}</div>
      <div className="popover-content">{content}</div>
      <div>
        {footer || (
          <>
            <ZeissButton
              type="primary"
              style={{ marginRight: 8 }}
              onClick={handleClickOk}
            >
              确定
            </ZeissButton>
            <ZeissButton className="color-btn" onClick={handleClickCancel}>
              取消
            </ZeissButton>
          </>
        )}
      </div>
    </div>
  );

  return (
    <Popover
      content={popoverContent}
      trigger={'click'}
      open={open}
      onOpenChange={handleOpenChange}
      className="custom-popover"
    >
      {children}
    </Popover>
  );
};

export default ZeissPopconfirm;
