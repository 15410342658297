import { authList, rolesList } from '@router/index';
import { useAuth } from './useAuth';
import _ from 'lodash';
/**
 * 处理路由权限
 * @param
 * */
// 根据auth|| role筛选出动态路由列表
const filterLoop = (list: any[], filterKey: string, filterList: string[]) => {
  return list
    .map((item: any) => {
      if (!item?.[filterKey]) return item;
      const newFilter =
        item[filterKey]?.length &&
        item[filterKey]?.find((every: string) => filterList.includes(every));
      if (item[filterKey]?.length && item[filterKey]?.includes(newFilter)) {
        if (item.children?.length) {
          item.children = filterLoop(item.children, filterKey, filterList);
        }
        return item;
      } else {
        return '';
      }
    })
    .filter(Boolean);
};
const useRoutes = (controller?: boolean) => {
  // 获取已经生产权限列表
  const [authkeyList, rolekeyList] = useAuth();
  if (!authkeyList.length || !rolekeyList.length) return [];
  const authData = _.cloneDeep(authList);
  const roleData = _.cloneDeep(rolesList);
  const routerRice = controller
    ? filterLoop(authData, 'auth', authkeyList)
    : filterLoop(roleData, 'role', rolekeyList);
  return routerRice;
};

export default useRoutes;
