import {
  downloadHistoryExport,
  downloadHistoryList,
  shareHistoryExport,
  shareHistoryList
} from '@api/history';
import { HistoryListRecord } from '@api/history/types';
import { downloadFileBlob } from '@utils/tool';
import { Button, Menu } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import DownloadHistory from './download';
import ShareHistory from './share';
import './index.scss';

const HistoryMenu = [
  {
    label: '分享历史',
    key: 'share'
  },
  {
    label: '下载历史',
    key: 'download'
  }
];

type ActiveTab = 'download' | 'share';

const History: FC = () => {
  const [activeTab, setActiveTab] = useState<ActiveTab>('share');

  const MenuContent = {
    download: <DownloadHistory />,
    share: <ShareHistory />
  };

  return (
    <div className="history">
      <div className="sider">
        <Menu
          items={HistoryMenu}
          className="history-menu"
          onClick={(info) => setActiveTab(info.key as ActiveTab)}
          selectedKeys={[activeTab]}
        />
      </div>
      <div className="wrapper">{MenuContent[activeTab]}</div>
    </div>
  );
};

export default History;
