import { ReactNode, useEffect, useState } from 'react';
import BrowserSizeContext, { SizeContextProps } from './context';

const verticalSize = 1200;

const WindowResize = ({ children }: { children: ReactNode }) => {
  const [browserSize, setBrowserSize] = useState<SizeContextProps>({
    width: 0,
    height: 0,
    formLayout: 'horizontal'
  });

  useEffect(() => {
    const handleResize = () => {
      setBrowserSize({
        width: window.innerWidth,
        height: window.innerHeight,
        formLayout: window.innerWidth < verticalSize ? 'vertical' : 'horizontal'
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <BrowserSizeContext.Provider value={browserSize}>
      {children}
    </BrowserSizeContext.Provider>
  );
};

export default WindowResize;
