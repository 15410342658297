import { ReactElement, useEffect, useState } from 'react';
import { ReactComponent as DownWhite } from '@/assets/image/downWhite.svg';
import './index.scss';
import { useRecoilValue } from 'recoil';
import { dataClassifications } from '@store';
const ClassTypeTag = (props: {
  value?: number;
  icon?: boolean;
}): ReactElement => {
  const { value = 0, icon = true } = props;
  const dataClassificationss = useRecoilValue(dataClassifications);
  const [currentType, setCurrentType] = useState('');
  useEffect(() => {
    let values = typeof value === 'string' ? parseInt(value) : value;
    setCurrentType(
      dataClassificationss.find((v: { code: number }) => v.code === values)
        ?.valueMsg
    );
  }, [value]);
  return (
    <div
      className={['class-type-tag', currentType, icon ? 'cursor' : ''].join(
        ' '
      )}
    >
      <span className="point"></span>
      <span>{currentType}</span>
      {icon ? <DownWhite /> : ''}
    </div>
  );
};

export default ClassTypeTag;
