import { Col, Empty, Row } from 'antd';
import SwapperImg from './swapper-img';
import { useEffect, useMemo, useState } from 'react';
import CagetoryCard from './cagetory-card';
import './index.scss';
import SpotCard from './spot-card';
import { UploadOutlined } from '@ant-design/icons';
import { getFileList, getNavigator } from '@api/home';
import { BannerInfo } from '@api/banner/type';
import { CategoryListItem } from '@api/category/type';
import ZeissButton from '@components/button';
import { useNavigate } from 'react-router-dom';
import { getBannersService } from '@api/banner';
import { useRecoilState } from 'recoil';
import { userInfo } from '@store';

const Home: React.FC = () => {
  const [list, setList] = useState<CategoryListItem[]>();
  const spaceOne = useMemo(() => list?.slice(0, 3), [list]);
  const spaceTwo = useMemo(() => list?.slice(3), [list]);

  const [banners, setBanners] = useState<BannerInfo[]>();
  const [hotspots, setHots] = useState<CategoryListItem[]>();
  const [newList, setNewList] = useState<CategoryListItem[]>();
  const [userInfos, setUserInfo] = useRecoilState<{
    authority: { resourceKey: string; children?: { resourceKey: string }[] }[];
  }>(userInfo);

  const navigate = useNavigate();

  const getCategoeyList = async () => {
    const data = await getNavigator({ homeShow: 1 });
    setList(data || []);
  };

  const hasAuth = (key: string) => {
    const auth = userInfos.authority
      .find((auth) => auth.resourceKey === 'content')
      ?.children?.findIndex((child) => child.resourceKey === key);
    return auth !== undefined ? auth > -1 : false;
  };

  const getNewContentList = async () => {
    try {
      const data = await getFileList({ timeOrder: 'descend' });
      setNewList(data?.records || []);
    } catch (error) {
      console.error(error);
    }
  };

  const getHotContentList = async () => {
    try {
      const data = await getFileList({ downloadOrder: 'descend' });
      setHots(data?.records || []);
    } catch (error) {
      console.error(error);
    }
  };
  const initPage = async () => {
    const res = await getBannersService({});
    setBanners(res || []);
  };

  const handleClickUpload = () => {
    navigate(`/contents/upload`);
  };

  useEffect(() => {
    initPage();
    getCategoeyList();
    getNewContentList();
    getHotContentList();
  }, []);

  return (
    <div className="home-container">
      <Row>
        <Col span={17} className="center">
          <div className="bg-container">
            <SwapperImg banners={banners} />
            <div className="cagetory-content">
              <div className="cagetory-content-title">
                <span>重点分类</span>
              </div>
              {!spaceOne?.length && !spaceTwo?.length && <Empty />}
              <Row gutter={20} style={{ marginBottom: '20px' }}>
                {spaceOne?.map((item, index) => (
                  <Col span={8} key={index}>
                    <CagetoryCard info={item} />
                  </Col>
                ))}
              </Row>
              <Row gutter={20}>
                {spaceTwo?.map((item, index) => (
                  <Col span={8} key={index}>
                    <CagetoryCard info={item} />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </Col>
        <Col span={7} className="right">
          {hasAuth('upload') && (
            <div className="action-wrapper">
              <div className="upload-text">上传文件至分类</div>
              <ZeissButton
                type="primary"
                icon={<UploadOutlined />}
                onClick={handleClickUpload}
              >
                去上传
              </ZeissButton>
            </div>
          )}
          <div className="bg-container">
            <SpotCard list={newList} type={'NEW'} />
          </div>
          <div className="bg-container">
            <SpotCard list={hotspots} type={'HOT'} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
