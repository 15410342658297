import { getAuthList, queryFileAuth, updateAuth } from '@api/home';
import { AuthListResult, UserResult } from '@api/home/type';
import ZeissButton from '@components/button';
import UserSelect, {
  UserValue
} from '@views/pages/content-list/components/user-select';
import { message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';
import '../index.scss';
import '../../content-list/upload.scss';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { CategoryListItem } from '@api/category/type';
import { selectedFile } from '@store';

const EditPermission: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserResult[]>([]);
  const [roles, setRoles] = useState<AuthListResult['roles']>([]);
  const [selectedUsers, setSelectedUsers] = useImmer<UserValue[]>([]);
  const [selectedRoles, setSelectedRoles] = useImmer<
    (UserValue & { users: UserResult[] })[]
  >([]);
  const [selectUserAll, setSelectUserAll] = useState<boolean>(false);
  const [selectedFileList, setSelectedFileList] =
    useRecoilState<CategoryListItem[]>(selectedFile);

  const getUsers = async () => {
    try {
      const data = await getAuthList();
      setUsers(data.users);
      setRoles(data.roles);
    } catch (error) {}
  };

  const getFileAuth = async (fileId: string) => {
    if (!users.length || !roles.length) return;
    const { authIds } = await queryFileAuth({ fileId });
    const defaultUsers: UserValue[] = [];
    const defaultRoles: (UserValue & { users: UserResult[] })[] = [];
    authIds.forEach((id) => {
      const user = users.find((user) => user.userId === id);
      if (user) {
        defaultUsers.push({ value: user.userId, label: user.username });
      }
      const role = roles.find((role) => role.roleId === id);
      if (role) {
        defaultRoles.push({
          ...role,
          value: role.roleId || '',
          label: role.roleName || ''
        });
      }
    });
    setSelectedUsers(defaultUsers);
    setSelectedRoles(defaultRoles);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleEditPermission = async () => {
    try {
      await updateAuth({
        fileIds:
          (location.state?.fileList as { fileId: string }[]).map(
            (file) => file.fileId
          ) || [],
        userIds: selectedUsers.filter((item) => item.value === 'all').length
          ? users.map((user) => user.userId)
          : selectedUsers.map((user) => user.value),
        roles: _.compact(
          selectedRoles.map((role) =>
            roles.find((item) => item.roleId === role.value)
          )
        )
      });
      message.success('编辑权限成功');
      setSelectedFileList([]);
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    const fileList = location.state?.fileList;
    if (fileList?.length === 1) {
      getFileAuth(fileList[0].fileId);
    }
  }, [location.state?.fileList, users, roles]);

  return (
    <div className="wrapper">
      <div className="page-subtitle">用户及通知设置</div>
      <div className="user-select-wrapper">
        <div>有效访问用户</div>
        <UserSelect
          roles={roles}
          users={users}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
          selectUserAll={selectUserAll}
          setSelectUserAll={setSelectUserAll}
        />
      </div>
      <div style={{ marginTop: 40 }}>
        <ZeissButton className="color-btn" onClick={handleCancel}>
          取消
        </ZeissButton>
        <ZeissButton
          type="primary"
          style={{ marginLeft: 8 }}
          onClick={handleEditPermission}
        >
          确定
        </ZeissButton>
      </div>
    </div>
  );
};

export default EditPermission;
