export default {
  'page.login.title': 'Login',
  'page.login.other': 'Other Ways:',
  'page.login.account': 'Account',
  'page.login.accountPlaceholder': 'Please enter your account',
  'page.login.passwordPlaceholder': 'Please enter your password',
  'page.login.pleaseLogin': 'Login',
  'page.login.password': 'Password',
  'page.login.loginSuccess': 'Login Successfully',
  'page.login.logout': 'Logout',
  'page.login.changePassword': 'Change Password',
  'page.login.forgetPassword': 'Forget Password',
  'page.login.oldPassword': 'Old Password',
  'page.login.newPassword': 'New Password',
  'page.login.confrmPassword': 'Confirm Password',
  'page.login.ok': 'Login',
  'page.login.sso': 'SSO Login',
  'page.login.passwordDiff': 'Two passwords do not match',
  'page.login.passwordUpdateSuccess': 'Password updated successfully',
  'page.login.compsLibrary': 'Component Library',
  'page.login.recoverPassword': 'Recover Password',
  'page.login.userAgreementInfo': 'Read and agree to ',
  'page.login.userAgreement': 'the User Agreement, Privacy Statement',
  'page.login.vertifyCode': 'Input VertifyCode'
};
