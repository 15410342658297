export default {
  'page.category.addCategory': 'Add',
  'page.category.editCategory': 'Edit',
  'page.category.addRootCategory': 'Create Root',
  'page.category.title': 'Category Management',
  'page.category.deleteConfirm': 'Are you sure to delete selected accounts?',
  'page.category.cannotDeleteCategory':
    'This category contains subcategories or content and cannot be deleted',
  'page.category.firstClassCategory': 'First Class Category',
  'page.category.showInHome': 'Show in Home',
  'page.category.addSecondClass': 'Add Second Class Category'
};
