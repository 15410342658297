import request from '@/utils/request';
import {
  AuthListResult,
  ContentDetailResult,
  ContentParams,
  FileListParams,
  FileListResult,
  HomeCategoryResult,
  HomeResponse,
  TokenResult,
  UserResult
} from './type';
import { CategoryListItem } from '@api/category/type';

export const getHomeService = (): Promise<HomeResponse> => {
  return request({
    url: 'xxxxx',
    method: 'get'
  });
};

export const getAzureToken = (): Promise<TokenResult> => {
  return request({
    url: '/content/sas',
    method: 'post'
  });
};

export const getAuthList = (): Promise<AuthListResult> => {
  return request({
    url: '/content/authList',
    method: 'post'
  });
};

export const getUploadCategoryList = (data: {
  rootId: string;
  path: string;
}): Promise<{ id: string; children: CategoryListItem[] }> => {
  return request({
    url: '/category/detail',
    method: 'post',
    data
  });
};

export const getLabelList = (data: {
  name?: string;
}): Promise<{ id: string; name: string }[]> => {
  return request({
    url: '/label/list',
    method: 'post',
    data
  });
};

export const addLabel = (data: { name: string }): Promise<{ id: string }> => {
  return request({
    url: '/label/add',
    method: 'post',
    data
  });
};

export const addContent = (data: ContentParams): Promise<{}> => {
  return request({
    url: '/content/add',
    method: 'post',
    data
  });
};

export const getNavigator = (data: {
  homeShow?: 0 | 1;
}): Promise<CategoryListItem[]> => {
  return request({
    url: '/content/navigation',
    method: 'post',
    data
  });
};

export const getHomeCategory = (data: {
  categoryId: string;
  pageNumber?: number;
  pageSize?: number;
  timeOrder?: 'ascend' | 'descend';
  fileTypeOrder?: 'ascend' | 'descend';
}): Promise<HomeCategoryResult> => {
  return request({
    url: '/content/page',
    method: 'post',
    data
  });
};

export const getContentDetail = (data: {
  id: string;
}): Promise<ContentDetailResult> => {
  return request({
    url: '/content/detail',
    method: 'post',
    data
  });
};

export const getFileList = (data: FileListParams): Promise<FileListResult> => {
  return request({
    url: '/content/fileList',
    method: 'post',
    data
  });
};
export const shareFile = (data: {
  files: { fileId: string; fileName: string }[];
  emails: string[];
}): Promise<null> => {
  return request({
    url: '/content/share',
    method: 'post',
    data
  });
};
export const queryFileCategory = (data: {
  fileId: string;
}): Promise<{ path: string[] }> => {
  return request({
    url: '/content/getFileCategory',
    method: 'post',
    data
  });
};
export const updateFileCategory = (data: {
  fileIds: string[];
  destinationCategoryIds: string[];
}): Promise<null> => {
  return request({
    url: '/content/updateFileCategory',
    method: 'post',
    data
  });
};
export const updateAuth = (data: {
  fileIds: string[];
  userIds: string[];
  roles: [
    {
      roleId: string;
      roleName: string;
      users: [
        {
          userId: string;
          username: string;
          email: string;
          type: number;
        }
      ];
    }
  ];
}): Promise<null> => {
  return request({
    url: '/content/updateAuth',
    method: 'post',
    data
  });
};
export const queryFileAuth = (data: {
  fileId: string;
}): Promise<{ authIds: string[] }> => {
  return request({
    url: '/content/getFileAuth',
    method: 'post',
    data
  });
};
export const getDownloadZip = (data: {
  files: { fileName: string; url: string }[];
}): Promise<null> => {
  return request({
    responseType: 'blob',
    url: '/content/zip',
    method: 'post',
    data
  });
};
export const updateLabel = (data: {
  fileIds: string[];
  labels: string;
}): Promise<null> => {
  return request({
    url: '/content/updateLabel',
    method: 'post',
    data
  });
};
export const queryFileLabel = (data: {
  categoryId: string;
}): Promise<{ categoryId: string; name: string; labels: string }> => {
  return request({
    url: '/content/fileLabel',
    method: 'post',
    data
  });
};
export const deleteFileMapping = (data: {
  categoryIds: string[];
}): Promise<null> => {
  return request({
    url: '/content/deleteFileMapping',
    method: 'post',
    data
  });
};
export const deleteFile = (data: { fileIds: string[] }): Promise<null> => {
  return request({
    url: '/content/deleteFile',
    method: 'post',
    data
  });
};
export const downloadRecord = (data: { fileIds: string[] }): Promise<null> => {
  return request({
    url: '/content/downloadRecord',
    method: 'post',
    data
  });
};
export const queryCrumbs = (data: {
  categoryId: string;
}): Promise<{ paths: string; pathNames: string }> => {
  return request({
    url: '/content/crumbs',
    method: 'post',
    data
  });
};
export const fileNameCheck = (data: { fileName: string }): Promise<boolean> => {
  return request({
    url: '/content/fileNameCheck',
    method: 'post',
    data
  });
};
export const updateLoginStatus = (): Promise<null> => {
  return request({
    url: '/system/login/updateLoginStatus',
    method: 'post'
  });
};

// 更新数据分级
export const updateDataClassificationQuirte = (data: {
  fileIds: string[];
  dataClassification: number;
}): Promise<null> => {
  return request({
    url: '/content/updateDataClassification',
    method: 'post',
    data
  });
};
