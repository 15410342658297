import { CategoryListItem } from '@api/category/type';
import { getFileList } from '@api/home';
import { FC, useEffect, useState } from 'react';
import Content from './components/content';
import { TablePaginationConfig } from 'antd';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FilterValue, SorterResult } from 'antd/es/table/interface';

const SearchResult: FC = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [searchList, setSearchList] = useState<CategoryListItem[]>([]);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize: 10,
    current: 1,
    showQuickJumper: true,
    showSizeChanger: true
  });

  const getSearchContentList = async (
    paginationConfig?: {
      current?: number;
      pageSize?: number;
    },
    filters?: Record<string, FilterValue | null>,
    sorter?: SorterResult<CategoryListItem> | SorterResult<CategoryListItem>[]
  ) => {
    const timeOrder =
      sorter instanceof Array
        ? sorter.find((item) => item.columnKey === 'createTime')?.order
        : sorter?.columnKey === 'createTime'
        ? sorter.order
        : undefined;
    const suffixOrder =
      sorter instanceof Array
        ? sorter.find((item) => item.columnKey === 'suffix')?.order
        : sorter?.columnKey === 'suffix'
        ? sorter.order
        : undefined;
    const value = searchParams.get('searchValue') || undefined;
    try {
      const data = await getFileList({
        timeOrder: timeOrder || 'descend',
        fileTypeOrder: suffixOrder || 'descend',
        pageNumber: paginationConfig?.current || pagination.current,
        pageSize: paginationConfig?.pageSize || pagination.pageSize,
        keyword: value
      });
      setSearchList(data.records);
      setPagination({
        ...pagination,
        pageSize: data.size,
        total: data.total,
        current: data.current
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearchContentList();
  }, [location.search]);

  return (
    <div className="wrapper">
      <Content
        data={searchList}
        fetchData={getSearchContentList}
        pagination={pagination}
        setPagination={setPagination}
      />
    </div>
  );
};

export default SearchResult;
