export default {
  'pages.user.Management': 'User Management',
  'pages.role.Management': 'Role Management',
  'pages.limit.Management': 'Limit Management',
  'pages.user.New': 'New',
  'pages.user.No': 'No.',
  'pages.user.User': 'User Name',
  'pages.user.Nickname': 'Nickname',
  'pages.user.Role': 'Role Name',
  'pages.user.Mobile': 'Mobile',
  'pages.user.name': 'Name',
  'pages.user.Status': 'Status',
  'pages.user.Area': 'Area',
  'pages.user.Machine': 'Machine Type',
  'pages.user.Remark': 'Remark',
  'pages.user.Email': 'Email',
  'pages.user.Created': 'Created Date',
  'pages.user.Operation': 'Operation',
  'pages.user.Edit': 'Edit',
  'pages.user.Delete': 'Delete',
  'pages.user.Search': 'Search',
  'pages.user.RoleKey': 'Role Key',
  'pages.user.Configure': 'Configure Permissions',
  'pages.user.ResetPassword': 'Reset Password',
  'pages.user.ResetPasswordDesc': 'Please confirm to reset the user password?',
  'pages.user.PermissionsList': ' Permissions List',
  'pages.user.no': 'The password cannot contain Chinese characters'
};
