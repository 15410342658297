export default {
  'page.category.addCategory': '新建分类',
  'page.category.editCategory': '编辑分类',
  'page.category.addRootCategory': '新建一级分类',
  'page.category.title': '分类管理',
  'page.category.deleteConfirm': '确认删除所选分类？',
  'page.category.cannotDeleteCategory': '该分类下包含子分类或内容，不可删除',
  'page.category.firstClassCategory': '一级分类',
  'page.category.showInHome': '是否在首页展示',
  'page.category.addSecondClass': '新增二级分类'
};
