import ZeissBreadcrumb from '@components/breadcrumb';
import ZeissButton from '@components/button';
import { Button, Form, Input, Select, Tree, message } from 'antd';
import { ChangeEvent, FC, Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import Icon, { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  createCategory,
  getCategoryDetail,
  updateCategory
} from '@api/category';
import {
  AddListItem,
  CategoryListItem,
  CreateCategoryParams,
  UpdateParams
} from '@api/category/type';
import { ReactComponent as DeleteIcon } from '@/assets/image/delete.svg';

interface TreeListItem {
  title: string;
  key: string;
  parentKeys: string[];
  id?: string;
  parentId?: string;
  type: CreateCategoryParams['type'];
  hasChildren: CategoryListItem['item']['hasChildren'];
}

interface TreeNode {
  key: string;
  name: string;
  id?: string;
  parentId?: string;
  children?: TreeNode[];
  type: CreateCategoryParams['type'];
  hasChildren: CategoryListItem['item']['hasChildren'];
  item?: {
    id?: string;
    name?: string;
    parentId?: string;
    homeShow?: 0 | 1;
    hasChildren?: 0 | 1;
  };
}

interface Item {
  id: string;
  name: string;
  parentId: string;
  homeShow?: 0 | 1;
  children?: Item[];
  title: string;
  key: string;
  type: CreateCategoryParams['type'];
  hasChildren: CategoryListItem['item']['hasChildren'];
}

const CreateCategory: FC = () => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { Option } = Select;
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const [treeData, setTreeData] = useImmer<TreeNode[]>([]);
  const [treeList, setTreeList] = useImmer<TreeListItem[]>([]);
  const [updateData, setUpdateData] = useImmer<UpdateParams | null>(null);
  const [addTreeList, setAddTreeList] = useImmer<AddListItem[]>([]);
  const [expandedKeys, setExpandedKeys] = useImmer<Key[]>([]);
  const [root, setRoot] = useState<
    { id: string; name: string; homeShow?: 0 | 1 } | undefined
  >(undefined);

  // 使用递归根据分类数组创建分类树
  function buildTree(arr: TreeListItem[]) {
    const tree: TreeNode = {
      key: '1',
      children: [],
      name: '',
      id: '',
      parentId: '',
      type: 1 as CreateCategoryParams['type'],
      hasChildren: 1
    };
    const map: { [key: string]: TreeNode } = { '1': tree };

    arr.forEach((item) => {
      const { key, title, parentKeys, hasChildren, ...rest } = item;
      const newNode = {
        key,
        title,
        children: [],
        name: title,
        parentKeys,
        hasChildren,
        ...rest
      };
      map[key] = newNode;

      let currentNode = tree;
      for (const parentKey of parentKeys) {
        if (!currentNode) break;
        currentNode = map[parentKey];
      }

      if (currentNode) {
        currentNode.children?.push(newNode);
      }
    });

    return tree;
  }

  function objToArray(obj: Item, parentKeys: string[] = []) {
    const { key, title, children, ...rest } = obj;
    const item = { key, title, parentKeys: [...parentKeys], ...rest };
    const result = [item];

    if (children) {
      children.forEach((child) => {
        const childArray = objToArray(child, [...parentKeys, key]);
        result.push(...childArray);
      });
    }
    return result;
  }

  const transformTree = (
    tree: CategoryListItem[],
    floor: number,
    levelCounts: { [key: number]: number }
  ) => {
    const result: Item[] = [];
    const categoryId = search.get('id');
    // 遍历数组tree中的每个元素
    tree.forEach((item) => {
      if (!levelCounts[floor]) {
        levelCounts[floor] = 0;
      }
      levelCounts[floor]++;
      const nodeKey = `${floor}-${levelCounts[floor]}-${item.name}`;
      const newItem: Item = {
        id: item.id,
        name: item.name,
        parentId: item.parentId,
        homeShow: item.item.homeShow,
        title: item.name,
        key: floor === 1 ? '1' : nodeKey,
        type: 1 as CreateCategoryParams['type'],
        hasChildren: item.item.hasChildren
      };

      if (item.id === categoryId) {
        setExpandedKeys([nodeKey]);
      }

      // 如果元素具有子元素，递归处理子元素
      if (item.children && item.children.length > 0) {
        newItem.children = transformTree(item.children, floor + 1, levelCounts);
      }

      // 将新对象添加到数组
      result.push(newItem);
    });

    return result;
  };

  useEffect(() => {
    const result = buildTree(treeList);
    setTreeData(result.children || []);
  }, [treeList]);

  useEffect(() => {
    const categoryId = search.get('id');
    if (categoryId) {
      setExpandedKeys([categoryId]);
      const queryCategoryDetail = async () => {
        try {
          const { children: firstClassTree } = await getCategoryDetail({
            id: categoryId
          });
          if (!firstClassTree) return;
          form.setFieldsValue({
            name: firstClassTree[0].name,
            showInHome: `${firstClassTree[0].item.homeShow}`
          });
          setRoot({
            id: firstClassTree[0].id,
            name: firstClassTree[0].name,
            homeShow: firstClassTree[0].item.homeShow
          });
          const levelCounts = {};
          const formated = transformTree(firstClassTree, 1, levelCounts);
          const list = objToArray(formated[0]).filter(
            (item) => item.key !== formated[0].key
          );
          setTreeList(list);
        } catch (error) {
          console.error(error);
        }
      };

      queryCategoryDetail();
    }
  }, []);

  const handleCreateCategory = async () => {
    const index = treeList.findIndex((item) => !item.title);
    if (index > -1) {
      message.error('存在分类的名称为空，不可保存');
      return;
    }
    const resultTree = {
      name: form.getFieldValue('name'),
      homeShow: form.getFieldValue('showInHome'),
      children: treeData,
      parentId: '0',
      type: 1 as CreateCategoryParams['type']
    };

    if (search.get('id')) {
      // 编辑分类
      if (!treeList.length) {
        message.error('请至少添加一个二级分类');
        return;
      }
      const isRootChange =
        root?.name !== form.getFieldValue('name') ||
        root?.homeShow !== +form.getFieldValue('showInHome');
      try {
        await updateCategory({
          ...updateData,
          editList: isRootChange
            ? (updateData?.editList || []).concat([
                {
                  id: root?.id || '',
                  name: form.getFieldValue('name'),
                  homeShow: +form.getFieldValue('showInHome') as
                    | 0
                    | 1
                    | undefined
                }
              ])
            : updateData?.editList,
          addList: addTreeList
        });
        message.success('编辑分类成功');
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } catch (error) {
        console.error(error);
      }
    } else {
      // 新增分类
      if (!resultTree.children.length) {
        message.error('请至少添加一个二级分类');
        return;
      }
      try {
        await createCategory(resultTree);
        message.success('新建分类成功');
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleAddCategory = () => {
    // 新增二级子分类
    setTreeList((draft) => [
      ...draft,
      {
        title: '',
        key: `2-${
          draft.filter(
            (item) => JSON.stringify(item.parentKeys) === JSON.stringify(['1'])
          ).length + 1
        }`,
        parentKeys: ['1'],
        type: 1
      }
    ]);
    // 编辑二级子分类
    if (search.get('id')) {
      setAddTreeList((draft) => [
        ...draft,
        {
          parentId: root?.id,
          name: '',
          key: `2-${
            treeList.filter(
              (item) =>
                JSON.stringify(item.parentKeys) === JSON.stringify(['1'])
            ).length + 1
          }`,
          type: 1
        }
      ]);
    }
  };

  const handleAddSubCategory = (node: TreeNode) => {
    // 新增二级之下的子分类
    setExpandedKeys(expandedKeys.concat([node.key]));
    const titleStr = node.key.toString().split('-');
    setTreeList((draft) => {
      const nodeParentKeys = draft.find(
        (item) => item.key === node.key
      )?.parentKeys;
      const level = +titleStr[0] + 1;
      const levelIndex =
        draft.filter((item) => +item.key.split('-')[0] === +titleStr[0] + 1)
          .length + 1;
      return [
        ...draft,
        {
          title: '',
          key: `${level}-${levelIndex}-${node.name}`,
          parentKeys: [...(nodeParentKeys || []), node.key],
          type: 1
        }
      ];
    });
    // 编辑二级之下的子分类
    if (search.get('id')) {
      const newCategory: {
        parentId?: string;
        parentKey?: string;
        name?: string;
        key?: string;
        type?: 1 | 2;
      } = { type: 1 };
      if (node.id) {
        newCategory.parentId = node.id;
      } else {
        newCategory.parentKey = node.key;
      }
      const level = +titleStr[0] + 1;
      const levelIndex =
        treeList.filter((item) => +item.key.split('-')[0] === +titleStr[0] + 1)
          .length + 1;
      newCategory.key = `${level}-${levelIndex}-${node.name}`;
      setAddTreeList((draft) => [...draft, newCategory]);
    }
  };

  // 删除分类
  const handleDeleteSubCategory = (node: TreeNode) => {
    setTreeList((draft) => draft.filter((item) => item.key !== node.key));
    if (search.get('id')) {
      setAddTreeList((draft) => draft.filter((item) => item.key !== node.key));
      if (node.parentId) {
        setUpdateData((draft) => ({
          ...draft,
          deleteList: (draft?.deleteList || []).concat([
            { id: node.id || '', parentId: node.parentId || '' }
          ])
        }));
      }
    }
  };

  const handleChangeCategoryName = (value: string, node: TreeNode) => {
    setTreeList((draft) =>
      draft.map((item) =>
        item.key === node.key ? { ...item, title: value } : item
      )
    );
    if (search.get('id')) {
      if (node.id) {
        setUpdateData((draft) => ({
          ...draft,
          editList:
            (draft?.editList || []).findIndex((item) => item.id === node.id) >
            -1
              ? (draft?.editList || [])?.map((item) =>
                  item.id === node.id ? { ...item, name: value } : item
                )
              : (draft?.editList || []).concat([
                  { id: node.id || '', name: value }
                ])
        }));
      } else {
        setAddTreeList((draft) => {
          return draft.map((item) =>
            item.key === node.key ? { ...item, name: value } : item
          );
        });
      }
    }
  };

  const handleExpand = (expandedKeys: Key[]) => {
    setExpandedKeys(expandedKeys);
  };

  const renderTitle = (node: TreeNode) => {
    const titleStr = node.key.toString().split('-');
    const showDelete = search.get('id')
      ? !node.children?.length && node.hasChildren !== 1
      : !node.children?.length;
    return (
      <div className="category-tree-item">
        <Form.Item label={`${titleStr[0]}级分类`} rules={[{ required: true }]}>
          <Input
            defaultValue={node.name || ''}
            className="custom-input category-name-input"
            bordered={false}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeCategoryName(e.target.value, node)
            }
          />
        </Form.Item>
        <div className="tree-item-buttons">
          <Button
            type="link"
            onClick={() => handleAddSubCategory(node)}
            className="tree-item-button"
            icon={<PlusOutlined style={{ color: '#2896fc' }} />}
          >
            新增下级分类
          </Button>
          {showDelete && (
            <Button
              className="tree-item-button"
              onClick={() => handleDeleteSubCategory(node)}
              type="link"
              danger
              icon={<Icon component={DeleteIcon} />}
            >
              删除分类
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="category-wrapper wrapper">
      <ZeissBreadcrumb />
      <div className="page-subtitle">
        {t('page.category.firstClassCategory')}
        {t('common.name')}
      </div>
      <div>
        <Form
          form={form}
          layout="vertical"
          wrapperCol={{ span: 24 }}
          className="create-category-form"
          onFinish={handleCreateCategory}
          initialValues={{ showInHome: '1' }}
        >
          <div className="create-category-input-wrapper">
            <Form.Item
              className="create-category-form-item"
              name={'name'}
              label={t('page.category.firstClassCategory')}
              rules={[{ required: true, message: '一级分类名称不能为空' }]}
            >
              <Input
                className="custom-input"
                bordered={false}
                placeholder="请输入"
              />
            </Form.Item>
            <Form.Item
              className="create-category-form-item"
              name={'showInHome'}
              label={t('page.category.showInHome')}
              rules={[{ required: true, message: '是否展示在导航栏不能为空' }]}
            >
              <Select
                placeholder="请选择"
                bordered={false}
                className="custom-select"
              >
                <Option value="1">{t('btn.yes')}</Option>
                <Option value="0">{t('btn.no')}</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="page-subtitle">子分类设置</div>
          <Form.Item className="sub-category-tree-wrapper">
            {!!treeData.length && (
              <Tree
                className="category-tree"
                treeData={treeData}
                titleRender={renderTitle}
                showLine
                switcherIcon={<DownOutlined />}
                defaultExpandAll={true}
                autoExpandParent={true}
                expandedKeys={expandedKeys}
                onExpand={handleExpand}
              />
            )}
            <ZeissButton
              style={{ marginTop: 8 }}
              icon={<PlusOutlined />}
              onClick={handleAddCategory}
            >
              {t('page.category.addSecondClass')}
            </ZeissButton>
          </Form.Item>
          <Form.Item className="create-category-buttons">
            <ZeissButton className="color-btn" onClick={handleCancelClick}>
              {t('btn.cancel')}
            </ZeissButton>
            <ZeissButton type="primary" htmlType="submit">
              {t('btn.save')}
            </ZeissButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateCategory;
