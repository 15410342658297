import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import ZeissBreadcrumb from '@components/breadcrumb';
import ZeissButton from '@components/button';
import { t } from 'i18next';
import { Space, message } from 'antd';
import './index.scss';
import BannerBox from './banner-box';
import { useImmer } from 'use-immer';
import { uuid } from '@utils/tool';
import { BannerInfo } from '@api/banner/type';
import { useEffect, useState } from 'react';
import { getBannersService, saveBannersService } from '@api/banner';

const BannerConfig = () => {
  const [banners, setBanners] = useImmer<BannerInfo[]>([]);

  useEffect(() => {
    fetchDetail();
  }, []);
  const save = async () => {
    if (banners.find((banner) => !banner.url || !banner.mobileUrl)) {
      message.error('存在轮播图图片未上传，不可保存');
      return;
    }
    try {
      await saveBannersService({ maps: banners });
      message.success('保存成功');
      fetchDetail();
    } catch (error) {
      console.error(error);
    }
  };
  const handleClickCreate = () => {
    if (banners.length > 4) {
      return message.warning('轮播图已达到上限');
    }
    setBanners((draft) => {
      return [...draft, { title: uuid() }];
    });
  };
  const handleDelete = (title: string) => {
    setBanners((draft) => draft.filter((banner) => banner.title !== title));
  };

  const fetchDetail = async () => {
    const res = await getBannersService({});
    setBanners(res || []);
  };
  const handleUpdate = (item: BannerInfo) => {
    setBanners((draft) => {
      const index = draft.findIndex((v) => v.title === item.title);
      draft[index] = item;
      return draft;
    });
  };
  return (
    <div className="banner-config-container wrapper">
      <ZeissBreadcrumb />
      <div className="banner-config-header">
        <div className="page-title">{t('menu.banner-config')}</div>
        <Space>
          <ZeissButton
            className="color-btn"
            onClick={save}
            icon={<SaveOutlined />}
          >
            保存
          </ZeissButton>
          <ZeissButton
            type="primary"
            onClick={handleClickCreate}
            icon={<PlusOutlined />}
          >
            新增
          </ZeissButton>
        </Space>
      </div>
      {banners?.map((banner, index) => (
        <BannerBox
          item={banner}
          index={index}
          key={banner.title}
          onDelete={handleDelete}
          onUpdate={handleUpdate}
        />
      ))}
    </div>
  );
};

export default BannerConfig;
