import { FormLayout } from 'antd-mobile/es/components/form';
import React from 'react';

export interface SizeContextProps {
  width: number;
  height: number;
  formLayout: FormLayout;
}

const BrowserSizeContext = React.createContext<SizeContextProps>({
  width: 0,
  height: 0,
  formLayout: 'horizontal'
});

export default BrowserSizeContext;
