export default {
  'pages.user.Management': '用户管理',
  'pages.role.Management': '角色管理',
  'pages.limit.Management': '资源管理',
  'pages.user.New': '新建',
  'pages.user.No': '序号',
  'pages.user.User': '用户',
  'pages.user.Nickname': '用户昵称',
  'pages.user.name': '姓名',
  'pages.user.Role': '角色名称',
  'pages.user.Mobile': '手机号',
  'pages.user.Status': '状态',
  'pages.user.Area': '所属区域',
  'pages.user.Machine': '设备类型',
  'pages.user.Remark': '备注',
  'pages.user.Email': '邮箱',
  'pages.user.Created': '创建日期',
  'pages.user.Operation': '操作',
  'pages.user.Edit': '编辑',
  'pages.user.Delete': '删除',
  'pages.user.Search': '搜索',
  'pages.user.RoleKey': '角色关键字',
  'pages.user.Configure': '配置角色权限',
  'pages.user.ResetPassword': '重置密码',
  'pages.user.ResetPasswordDesc': '确认重置该用户密码？',
  'pages.user.PermissionsList': '权限列表',
  'pages.user.no': '密码不能含有中文'
};
