const getStorage = (key: string) => {
  const storageValue: any = localStorage.getItem(key);
  if (!storageValue) return null;
  try {
    return JSON.parse(storageValue);
  } catch (error) {
    return storageValue;
  }
};

const setStorage = (key: string, value: any) => {
  const formatKey = `${key}`;
  const formatValue = JSON.stringify(value);
  const invalidKey = ['null', 'undefined'];
  if (invalidKey.includes(formatKey)) return;
  localStorage.setItem(formatKey, formatValue);
};

const clearStorage = (key: string) => {
  localStorage.removeItem(key);
};

export { getStorage, setStorage, clearStorage };
