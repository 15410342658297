import { Button, Card, Empty, Space } from 'antd';
import './index.scss';
import arrowRight from '@assets/image/arrow-right.svg';
import FileIcon from '@assets/image/file.svg';
import EmailIcon from '@assets/image/email.svg';
import { useMemo } from 'react';
import { CategoryListItem } from '@api/category/type';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userInfo } from '@store';

const RowItem = (props: {
  item: CategoryListItem;
  type: string;
  onClick: (item: CategoryListItem) => void;
}) => {
  const [userInfos] = useRecoilState<{
    authority: {
      resourceKey: string;
      children?: {
        resourceKey: string;
        children?: { resourceKey: string }[];
      }[];
    }[];
  }>(userInfo);

  // 这个组件看了只有HOME页面使用了，但是也不知道为啥会加props.item?.item?.type === 2这个逻辑判断，也不好改他的，所以任然保留这个判断
  const Icon = useMemo(() => {
    if (
      props.item?.item?.type === 2 &&
      props.type !== 'NEW' &&
      props.type !== 'HOT'
    ) {
      return EmailIcon;
    }
    return FileIcon;
  }, [props.item]);

  // const hasAuth = (key: string) => {
  //   const auth = userInfos.authority
  //     .find((auth) => auth.resourceKey === 'content')
  //     ?.children?.find((item) => item.resourceKey === 'contentColumn')
  //     ?.children?.findIndex((child) => child.resourceKey === key);
  //   return auth !== undefined ? auth > -1 : false;
  // };

  return (
    <div className="row-content" onClick={() => props.onClick(props.item)}>
      <Space>
        <img className="file-icon" src={Icon} alt="" />
        <div className="file-name">{props.item.name}</div>
      </Space>
      <div className="time">
        <Space>
          {props.type === 'NEW' && (
            <span>
              {dayjs(props.item.item?.createTime).format('YYYY/MM/DD HH:mm')}
            </span>
          )}
        </Space>
      </div>
    </div>
  );
};

interface Props {
  type: string;
  list?: CategoryListItem[];
}
const SpotCard: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, list } = props;

  const handleRowItemClick = (item: CategoryListItem) => {
    navigate(`/contents/detail/${item.id}`, {
      state: { from: location.pathname }
    });
  };

  return (
    <div className="spot-card-container">
      <Card
        title={type}
        extra={
          !!list?.length && (
            <Button
              type="link"
              onClick={() => navigate(`/contents/${type.toLowerCase()}`)}
            >
              查看更多 <img src={arrowRight} alt="" />
            </Button>
          )
        }
      >
        {list?.slice(0, 5)?.map((item, index) => (
          <RowItem
            key={index}
            item={item}
            type={type}
            onClick={handleRowItemClick}
          />
        ))}
        {!list?.length && <Empty />}
      </Card>
    </div>
  );
};

export default SpotCard;
