import { CategoryListItem } from '@api/category/type';
import { getHomeCategory, queryCrumbs } from '@api/home';
import { FC, useEffect, useState } from 'react';
import Content from './components/content';
import { TablePaginationConfig } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { FilterValue, SorterResult } from 'antd/es/table/interface';

const ContentList: FC = () => {
  const { categoryId = '' } = useParams();
  const location = useLocation();
  const [categoryList, setCategoryList] = useState<CategoryListItem[]>([]);
  const [breadcrumb, setBreadcrumb] = useState<
    { paths: string; pathNames: string } | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize: 10,
    current: 1,
    showQuickJumper: true,
    showSizeChanger: true
  });

  const getSorter = (
    sorter: SorterResult<CategoryListItem> | SorterResult<CategoryListItem>[],
    key: string
  ) => {
    return sorter instanceof Array
      ? sorter.find((item) => item.columnKey === key)?.order
      : sorter?.columnKey === key
      ? sorter.order
      : undefined;
  };

  const getHomeCategoryList = async (
    paginationConfig?: {
      current?: number;
      pageSize?: number;
    },
    filters?: Record<string, FilterValue | null>,
    sorter?: SorterResult<CategoryListItem> | SorterResult<CategoryListItem>[]
  ) => {
    try {
      setLoading(true);
      const timeOrder = getSorter(sorter || [], 'createTime');
      const suffixOrder = getSorter(sorter || [], 'suffix');
      const data = await getHomeCategory({
        categoryId,
        pageNumber: paginationConfig?.current || pagination.current,
        pageSize: paginationConfig?.pageSize || pagination.pageSize,
        timeOrder: timeOrder || undefined,
        fileTypeOrder: suffixOrder || undefined
      });
      setCategoryList(data.records);
      setPagination({
        ...pagination,
        pageSize: data.size,
        total: data.total,
        current: data.current
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getBreadcrumbInfo = async () => {
    const data = await queryCrumbs({ categoryId });
    setBreadcrumb(data);
  };

  useEffect(() => {
    getHomeCategoryList();
    getBreadcrumbInfo();
  }, [categoryId, location.pathname]);

  return (
    <div className="wrapper">
      <Content
        data={categoryList}
        fetchData={getHomeCategoryList}
        pagination={pagination}
        setPagination={setPagination}
        breadcrumb={breadcrumb}
        loading={loading}
      />
    </div>
  );
};

export default ContentList;
