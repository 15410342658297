import { Dispatch, FC, SetStateAction } from 'react';
import { Button, Table, Tag } from 'antd';
import { useMemo } from 'react';
import Icon from '@ant-design/icons';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import './index.scss';
import { ReactComponent as SortIcon } from '@/assets/image/sort-icon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { CategoryListItem } from '@api/category/type';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { userInfo } from '@store';

export const tagsColors = ['volcano', 'processing', 'cyan', 'purple'];

interface ListTableProps {
  list: CategoryListItem[];
  multiple: boolean;
  selectedItems: CategoryListItem[];
  setSelectedItems: (value: CategoryListItem[]) => void;
  pagination: {
    sizeCanChange: boolean;
    total: number;
    pageSize: number;
    current: number;
    showQuickJumper: boolean;
    showSizeChanger: boolean;
  };
  setPagination: Dispatch<
    SetStateAction<{
      sizeCanChange: boolean;
      total: number;
      pageSize: number;
      current: number;
      showQuickJumper: boolean;
      showSizeChanger: boolean;
    }>
  >;
  getData: (paginationConfig?: {
    current?: number;
    pageSize?: number;
  }) => Promise<void>;
}

const ListTable: FC<ListTableProps> = ({
  list,
  multiple,
  selectedItems,
  setSelectedItems,
  pagination,
  setPagination,
  getData
}) => {
  const [userInfos, setUserInfo] = useRecoilState<{
    authority: {
      resourceKey: string;
      children?: {
        resourceKey: string;
        children?: { resourceKey: string }[];
      }[];
    }[];
  }>(userInfo);
  const navigate = useNavigate();
  const location = useLocation();

  const handleTableChange = async (
    paginationConfig: TablePaginationConfig
    // sorter:
    //   | SorterResult<listResponseRecordsFace>
    //   | SorterResult<listResponseRecordsFace>[]
  ) => {
    setPagination({
      ...pagination,
      pageSize: paginationConfig.pageSize || 10,
      current: paginationConfig.current || 1
    });
    await getData({
      current: paginationConfig.current,
      pageSize: paginationConfig.pageSize
    });
  };

  const hasAuth = (key: string) => {
    const auth = userInfos.authority
      .find((auth) => auth.resourceKey === 'content')
      ?.children?.find((item) => item.resourceKey === 'contentColumn')
      ?.children?.findIndex((child) => child.resourceKey === key);
    return auth !== undefined ? auth > -1 : false;
  };

  const getAllCategory = (
    category: CategoryListItem,
    result: CategoryListItem[]
  ) => {
    if (category?.children?.length) {
      category.children.forEach((child) => {
        result.push(child);
        if (child.children) {
          getAllCategory(child, result);
        }
      });
    }
  };

  const extraProps = useMemo(
    () =>
      multiple
        ? ({
            rowSelection: {
              onSelect: (record, selected, selectedRows) => {
                setSelectedItems(selectedRows);
              },
              onSelectAll(selected, selectedRows) {
                setSelectedItems(selectedRows);
              },
              checkStrictly: false
            }
          } as TableProps<CategoryListItem>)
        : {},
    [multiple]
  );

  const getColumns = () => {
    const columns: ColumnsType<CategoryListItem> = [];
    if (hasAuth('file_name')) {
      columns.push({
        title: '名称',
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        ellipsis: true
      });
    }
    if (hasAuth('upload_time')) {
      columns.push({
        title: '上传时间',
        dataIndex: 'createTime',
        key: 'createTime',
        sorter: true,
        sortIcon: () => <Icon component={SortIcon} />,
        render: (_: unknown, record: CategoryListItem) => {
          return (
            (record.item.type === 2 || record.item.suffix) &&
            dayjs(record.item.createTime).format('YYYY-MM-DD HH:mm:ss')
          );
        }
      });
    }
    if (hasAuth('type')) {
      columns.push({
        title: '类型',
        dataIndex: 'suffix',
        key: 'suffix',
        sorter: true,
        sortIcon: () => <Icon component={SortIcon} />,
        render: (_: unknown, record: CategoryListItem) => record.item.suffix
      });
    }
    if (hasAuth('upload_user')) {
      columns.push({
        title: '上传者',
        dataIndex: 'item',
        render: (item: CategoryListItem['item']) => <>{item.createUser}</>
      });
    }
    if (hasAuth('tag')) {
      columns.push({
        title: '标签',
        dataIndex: 'tag',
        key: 'tag',
        render: (_: unknown, record: CategoryListItem) =>
          record.item.labels?.split(',').map((label, index) => (
            <Tag
              key={label + index}
              bordered={false}
              color={tagsColors[0]}
              className="table-tag"
            >
              {label}
            </Tag>
          ))
      });
    }
    if (hasAuth('click_volume')) {
      columns.push({
        title: '下载量',
        dataIndex: 'downLoadNum',
        key: 'downLoadNum',
        render: (_: unknown, record: CategoryListItem) =>
          record.item.fileUrl && `${record.item.downLoadNum}`
      });
    }
    columns.push({
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: unknown, record: CategoryListItem) =>
        record.item.type !== 1 &&
        hasAuth('detail') && (
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => {
              navigate(`/detail?id=${record.item.fileId}`, {
                state: { from: location.pathname }
              });
            }}
          >
            {' '}
            详情{' '}
          </Button>
        )
    });

    return columns;
  };

  return (
    <Table
      rowKey={'id'}
      dataSource={list}
      columns={getColumns()}
      pagination={{
        ...pagination,
        showTotal: () => `Total ${pagination.total} items`
      }}
      onChange={handleTableChange}
      size="small"
      {...extraProps}
    ></Table>
  );
};

export default ListTable;
