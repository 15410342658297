import { ZAuthRole } from '@/authority/authType';
import PageA from '@views/pages/pageA';
import { ReactComponent as Menu1 } from '@assets/image/menu/menu1.svg';

import React from 'react';
import { routerListTS } from './types';
const rolesList: routerListTS[] = [
  {
    path: 'overview',
    name: 'ove',
    icon: <Menu1 />,
    role: [ZAuthRole.admin],
    component: <PageA />
  },
  {
    path: 'overvi',
    name: 'ovess',
    icon: <Menu1 />,
    role: [ZAuthRole.admin],
    component: <PageA />
  }
];

export default rolesList;
