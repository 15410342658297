const getFilePreview = (suffix: string, url?: string) => {
  switch (suffix) {
    case 'mp4':
    case 'webm':
      return <video src={url} width={800} muted controls />;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'svg':
      return <img src={url} />;
  }
};

export default getFilePreview;
