import { Card, Tree } from 'antd';
import { CagetoryItem } from '../../../../api/home/type';
import Frame from '@assets/image/frame.svg';
import './index.scss';
import { DownOutlined } from '@ant-design/icons';
import { DataNode } from 'antd/es/tree';
import { Key, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function tranlateTreeData(data?: CagetoryItem[]): DataNode[] {
  if (!data) {
    return [];
  }
  const _data = data.map((v) => {
    return {
      title: v.name,
      key: v.id,
      children: tranlateTreeData(v.children)
    } as DataNode;
  });
  return _data;
}
interface Props {
  info: CagetoryItem;
}
const CagetoryCard: React.FC<Props> = ({ info }) => {
  const navigate = useNavigate();
  const [treeData, setTreeData] = useState<DataNode[]>();
  useEffect(() => {
    const _subData = tranlateTreeData(info.children);
    setTreeData(_subData);
  }, [info]);
  const CardTitle = (title: string) => (
    <div className="cagetory-card-title">
      <img alt="icon" src={Frame} />
      <div className="text">{title}</div>
    </div>
  );
  const handleClickNode = (
    selectedKeys: Key[],
    e: { selected: boolean; selectedNodes: DataNode[]; node: DataNode }
  ) => {
    navigate(`/contents/category/${e.node.key}`);
  };

  return info.children?.length ? (
    <div className="cagetory-card-container">
      <Card
        title={CardTitle(info.name!)}
        style={{ minWidth: 248, borderRadius: '4px' }}
      >
        <Tree
          selectable={true}
          showLine={false}
          switcherIcon={<DownOutlined />}
          treeData={treeData}
          onSelect={handleClickNode}
        />
      </Card>
    </div>
  ) : null;
};

export default CagetoryCard;
