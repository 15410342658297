import { CategoryListItem } from '@api/category/type';
import { getFileList } from '@api/home';
import { FC, useEffect, useState } from 'react';
import Content from './components/content';
import { TablePaginationConfig } from 'antd';
import { useLocation } from 'react-router-dom';
import { FilterValue, SorterResult } from 'antd/es/table/interface';

const HotContent: FC = () => {
  const [categoryList, setCategoryList] = useState<CategoryListItem[]>([]);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize: 10,
    current: 1,
    showQuickJumper: true,
    showSizeChanger: true
  });

  const getHotContentList = async (
    paginationConfig?: {
      current?: number;
      pageSize?: number;
    },
    filters?: Record<string, FilterValue | null>,
    sorter?: SorterResult<CategoryListItem> | SorterResult<CategoryListItem>[]
  ) => {
    try {
      const timeOrder =
        sorter instanceof Array
          ? sorter.find((item) => item.columnKey === 'createTime')?.order
          : sorter?.columnKey === 'createTime'
          ? sorter.order
          : undefined;
      const data = await getFileList({
        timeOrder: timeOrder || undefined,
        downloadOrder: !timeOrder ? 'descend' : undefined,
        pageNumber: paginationConfig?.current || pagination.current,
        pageSize: paginationConfig?.pageSize || pagination.pageSize
      });
      setCategoryList(data.records);
      setPagination({
        ...pagination,
        pageSize: data.size,
        total: data.total,
        current: data.current
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getHotContentList();
  }, []);

  return (
    <div className="wrapper">
      <Content
        data={categoryList}
        fetchData={getHotContentList}
        pagination={pagination}
        setPagination={setPagination}
      />
    </div>
  );
};

export default HotContent;
