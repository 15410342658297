import pcEnUS from 'antd/locale/en_US';
import pcZhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en_US from './locale/en_US';
import zh_CN from './locale/zh_CN';

import { LabeledValue } from 'antd/es/select';

export const valueZHCN = 'zh-CN';
export const valueEN = 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'zh-CN', // 设置默认语言为中文
    resources: {
      en: { translation: en_US },
      'zh-CN': { translation: zh_CN }
    },
    fallbackLng: 'en',
    debug: false,
    react: {
      useSuspense: false
    },
    detection: {
      order: ['localStorage', 'sessionStorage', 'cookie']
    }
  });

export const pcLocale: { [key: string]: any } = {
  [valueZHCN]: pcZhCN,
  [valueEN]: pcEnUS
};

export const langOptions: LabeledValue[] = [
  { value: valueZHCN, label: '中文' },
  { value: valueEN, label: 'English' }
];

export const dayjsMap: { [key: string]: string } = {
  [valueZHCN]: 'zh-cn',
  [valueEN]: 'en'
};

export const notificationMap: { [key: string]: number } = {
  [valueZHCN]: 1,
  [valueEN]: 2
};

export default i18n;
