import React, {
  useState,
  useImperativeHandle,
  useMemo,
  useTransition
} from 'react';
import { Form, Input, Space } from 'antd';
import useLatest from '@utils/useLatest';
import { Timeout } from 'ahooks/lib/useRequest/src/types';
import ZeissButton from '@components/button';
import { useTranslation } from 'react-i18next';
import './index.scss';

interface VertifyCodeProps {
  cRef: React.Ref<unknown>;
  onTap: () => void;
}

export type ImperativeHandleProps = {
  setVertifyCodeLoading: () => void;
  stopReduceTimer: () => void;
  startReduceTimer: () => void;
};
let reduceRestTimer: string | Timeout | undefined = undefined; // 邮件倒计时timer
const REST_TIME = 180; // 单位： s

function VertifyCode(props: VertifyCodeProps) {
  const { cRef, onTap } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [restTime, setRestTime] = useState(REST_TIME);
  const curRestTime = useLatest(restTime);
  const { t } = useTranslation();

  // 停止倒计时
  function stopReduceTimer() {
    clearTimeout(reduceRestTimer);
    setLoading(false);
    setRestTime(REST_TIME);
  }
  // 启动倒计时
  function startReduceTimer() {
    setLoading(true);
    reduceRestTime();
  }

  function reduceRestTime() {
    reduceRestTimer = setTimeout(() => {
      if (curRestTime.current === 1) {
        // setRestTime(5);
        stopReduceTimer();
        return;
      }
      if (curRestTime.current > 1) {
        setRestTime((pre) => pre - 1);
        reduceRestTime();
      }
    }, 1000);
  }

  useImperativeHandle(cRef, () => ({
    setVertifyCodeLoading: setLoading,
    stopReduceTimer,
    startReduceTimer
  }));

  const searchButtonText = useMemo(() => {
    if (loading) {
      return `重新获取（${restTime}）`;
    } else {
      return '获取验证码';
    }
  }, [loading, restTime]);

  function onSearch() {
    if (typeof onTap === 'function') {
      onTap?.();
    }
  }

  return (
    <Form.Item>
      <Space.Compact block className="vertify-wrapper">
        <Form.Item
          name="validateCode"
          rules={[{ required: true, message: '验证码不能为空' }]}
          wrapperCol={{ span: 24 }}
          className="vertify-item"
        >
          <Input
            className="zeiss-reset-input vertify-input"
            placeholder={t('page.login.vertifyCode') || ''}
          />
        </Form.Item>
        <ZeissButton
          type="link"
          onClick={onSearch}
          className="vertify-button"
          disabled={loading}
        >
          {searchButtonText}
        </ZeissButton>
      </Space.Compact>
    </Form.Item>
  );
}

export default VertifyCode;
