import './index.scss';

interface OverLayOptions {
  center: boolean;
}

const useOverlay = (
  id: string,
  options?: Partial<OverLayOptions>
): HTMLElement => {
  const currentContainer = document.getElementById(id);
  let container = currentContainer;
  if (!container) {
    container = document.createElement('div');
    container.id = id;
    container.className = 'overlay-container';
    if (options?.center) {
      container.classList.add('center');
    }
    document.body.appendChild(container);
  }
  return container;
};

export { useOverlay };
