import login from './en_US/login';
import menu from './en_US/menu';
import rc from './en_US/rc';
import user from './en_US/user';
import globalHeader from './zh_CN/globalHeader';
import components from './en_US/components';
import accunt from './en_US/account';
import category from './en_US/category';

export default {
  'navBar.lang': 'lang',
  'operation.add': 'Add',
  'btn.delete': 'Delete',
  'btn.edit': 'Edit',
  'operation.success': 'Operation successful!',
  'operation.delSuccess': 'The delete operation was successful',
  'operation.delFailed': 'The delete operation was failed',
  'operation.createSuccess': 'The create operation was successful',
  'operation.createFailed': 'The create operation was failed',
  'operation.editSuccess': 'The edit operation was successful',
  'operation.editFailed': 'The edit operation was failed',
  'btn.reset': 'Reset',
  'btn.search': 'Search',
  'btn.create': 'Create',
  'operation.notifySuccess': 'Notification of success',
  'operation.notifyFailed': 'Notification of failure',
  'btn.confirm': 'Confirm',
  'btn.login': 'Login',
  'btn.cancel': 'Cancel',
  'btn.close': 'Close',
  'btn.back': 'Back',
  'btn.export': 'Download',
  'btn.submit': 'Submit',
  'btn.save': 'Save',
  'btn.view': 'View',
  'btn.addNewOne': 'Add',
  'btn.upload': 'Upload',
  'btn.notify': 'Notice',
  'btn.tip': 'Notification',
  'btn.noNotify': 'No notice',
  'btn.download': 'Download',
  'btn.downloadFail': 'Download Fail',
  'btn.yes': 'Yes',
  'btn.no': 'No',
  'common.selectDate': 'Select Date',
  'common.last1year': 'Nearly 1 year',
  'common.last3year': 'Nearly 3 years',
  'common.index': 'No.',
  'common.name': 'Name',
  'common.yes': 'YES',
  'common.startDate': 'Start Date',
  'common.endDate': 'End Date',
  'common.no': 'NO.',
  'common.all': 'Total',
  'common.more': 'More',
  'common.status': 'Status',
  'common.formInputRequired': 'Please input {{field}}',
  'common.formSelectRequired': 'Please select {{field}}',
  'common.createDate': 'Created Date',
  'common.pleaseSelectDataToDel':
    'Please select the data you want to delete first',
  'common.pleaseSelectDataToExport':
    'Please select the data you want to export first',
  'common.pleaseSelectDataToDownload':
    'Please select the data you want to download first',
  'common.pleaseSelectData': 'Please select the data first',
  'input.placeholder': 'Please input',
  'confirm.back':
    'Fill in the information has not been saved, confirm to leave?',
  'confirm.back.nosave':
    'The information has not been saved yet. Are you sure to leave?',
  'confirm.delete': 'Confirm deletion',
  'date.month1': 'Jan',
  'date.month2': 'Feb',
  'date.month3': 'Mar',
  'date.month4': 'Apr',
  'date.month5': 'May',
  'date.month6': 'Jun',
  'date.month7': 'Jul',
  'date.month8': 'Aug',
  'date.month9': 'Sep',
  'date.month10': 'Oct',
  'date.month11': 'Nov',
  'date.month12': 'Dec',
  'common.year': 'year',
  'common.month': 'month',
  'common.day': 'day',
  'common.goCheck': 'View',
  'common.samePassword': 'Please input same password again',
  'common.passwordUpdated': 'Password was updated successfully',
  'common.version': 'Version',
  'pages.order.confirmDel': 'Are you sure you want to delete it',
  'pages.templates.operate': 'Operation',
  'pages.templates.newBuilt': 'New Built',
  'pages.templates.edit': 'Edit Built',
  'pages.templates.childBuilt': 'Child Built',
  'pages.limit.resourceName': 'resourceName',
  'pages.limit.resourceKey': 'resourceKey',
  'pages.limit.type': 'Type',
  'pages.limit.sortNum': 'SortNum',
  'pages.limit.visible': 'Visible',
  'pages.limit.remark': 'Remark',
  operation: 'Operation',
  ...globalHeader,
  ...components,
  ...menu,
  ...login,
  ...user,
  ...rc,
  ...accunt,
  ...category
};
