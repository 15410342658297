import request from '@/utils/request';
import {
  AddRoleParams,
  ModifyRoleParams,
  RoleDetailItem,
  RoleItem,
  RoleResource,
  RoleResult,
  UserParams
} from './type';

export const queryRoleListPage = (data: {
  pageNumber?: number;
  pageSize?: number;
  searchName?: string;
  createTimeValue?: 1 | 2;
}): Promise<RoleResult> => {
  return request({
    url: '/system/role/roleListPage',
    method: 'post',
    data
  });
};
export const queryRoleDetail = (data: {
  id: string;
  adminRoleId: string;
}): Promise<RoleDetailItem> => {
  return request({
    url: '/system/role/queryRoleDetail',
    method: 'post',
    data
  });
};
export const saveRole = (data: AddRoleParams): Promise<boolean> => {
  return request({
    url: '/system/role/saveRole',
    method: 'post',
    data
  });
};
export const modifyRole = (data: ModifyRoleParams): Promise<boolean> => {
  return request({
    url: '/system/role/modifyRole',
    method: 'post',
    data
  });
};
export const deleteRole = (data: {
  id: number;
  adminRoleId: number;
}): Promise<boolean> => {
  return request({
    url: '/system/role/deleteRole',
    method: 'post',
    data
  });
};
export const queryUserList = (data: UserParams): Promise<boolean> => {
  return request({
    url: '/system/user/queryUserList',
    method: 'post',
    data
  });
};
export const queryResourceAllList = (): Promise<{
  resources: RoleResource[];
}> => {
  return request({
    url: '/system/resource/resourceAllList',
    method: 'post'
  });
};
export const queryAllRoleShow = (): Promise<{ roles: RoleItem[] }> => {
  return request({
    url: '/system/role/queryAllRoleShow',
    method: 'post'
  });
};
export const addUserRoleBatch = (data: {
  userId: string[];
  roleIds: number[];
}): Promise<null> => {
  return request({
    url: '/system/user/addUserRoleBatch',
    method: 'post',
    data
  });
};
export const queryUserRoleResourceList = (data: {
  roleIdList: number[];
}): Promise<{ resources: RoleResource[] }> => {
  return request({
    url: '/system/user/queryUserRoleResourceList',
    method: 'post',
    data
  });
};
